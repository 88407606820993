import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const AddAttribute = () => {

  const { t, i18n } = useTranslation();



  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state)
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const validateFtn = (values) => {
    console.log("values:-", values)
    const errors = {};
    if (!values.payment_method) {
      errors.payment_method = "Payment Method Required.";
    }
    if (!values.is_moderated) {
      errors.is_moderated = "Is Moderated Required.";
    }
    if (!values.is_online) {
      errors.is_online = "Is Online Required.";
    }
    if (!values.status) {
      errors.status = "Status Required.";
    }


    return errors;
  };
  function confirmBack() {
    let msg = t("Once Back, you will not be able to revert!")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        navigate(-1)
      }
    })
  };

  const onSubmit = (values) => {
    console.log("values", values)
    var payloadData = {
      _id:values._id,
      functionName: "Update_PaymentMethod",
      payment_method: values.payment_method,
      is_moderated: values.is_moderated,
      is_online: values.is_online,
      status: values.status,

    }
    setLoading(true);
    console.log("Attribute payload:-", payloadData)
    AttributesService.Update_PaymentMethod(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        navigate("/Finance-Settings")
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Class Updated successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  };

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update Payment Method')}
                  <div className="page-title-subheading">
                    {t('update the form below to update a Payment Method.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateFtn}
                      initialValues={state}
                      render={({ handleSubmit, values, submitting, validating, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Payment Method")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="payment_method">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            type="text"
                                            placeholder={'Payment Method'}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Is Online")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="is_online">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Payment Method will be Online/Offline'}
                                            type="select"
                                            invalid={meta.error && meta.touched}
                                          >
                                            <option disabled={false} value="">--{t("Select Online Status")}--</option>
                                            <option key='Yes' value='Yes'>{t("Yes")}</option>
                                            <option key='No' value='No'>{t("No")}</option>
                                          </Input>
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Is Moderated")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="is_moderated">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Payment Method is Moderated or Not'}
                                            type="select"
                                            invalid={meta.error && meta.touched}
                                          >
                                            <option disabled={false} value="">--{t("Select Moderated")}--</option>
                                            <option key='Yes' value='Yes'>{t("Yes")}</option>
                                            <option key='No' value='No'>{t("No")}</option>
                                          </Input>
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}

                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Status")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="status">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Payment Method Status'}
                                            type="select"
                                            invalid={meta.error && meta.touched}

                                          >
                                            <option disabled={false} value="">--{t("Select status")}--</option>
                                            <option key='Active' value='Active'>{t("Active")}</option>
                                            <option key='Inactive' value='Inactive'>{t("Inactive")}</option>
                                          </Input>
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "20px" }}>
                            <FormGroup>
                              <div className="text-center">
                                <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                  Update
                                </Button>
                                <button type="button" onClick={() => {
                                  if (state?.check) {
                                    localStorage.setItem('tab5', state.check);
                                  } else {
                                    localStorage.setItem('tab5', "1");
                                  }

                                  confirmBack();
                                }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                  Cancel
                                </button>
                              </div>
                            </FormGroup>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}
export default AddAttribute;