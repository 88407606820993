import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import FormsService from "./UserForms.service";
import AuthService from "../Authentication/auth.service";


const AddEntity = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { state } = location;
  const [cruds, setcruds] = useState([]);
  const [rows, setrows] = useState([]);
  const [formstepsData, setformstepsData] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchEntities();
    addRow1();
  },[]);

  function fetchEntities() {
    FormsService.getAll({ _id: { $ne: state._id } }).then((response) => {
      console.log("Get Entities response:-", response)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        var updatedata = response.data.data;
        var formsteps = updatedata[0].formsteps;
        setformstepsData(formsteps);
        setrows(formsteps.length);
        setcruds(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  const onSubmit = values => {
    
    var stepsobject = Array.from(document.querySelectorAll('.stepslable'))
    .map(input => input.value);
      
    var payloadData = {
      functionName: "UpdateUserForm",
      postData: {
        name: values.name,
        page_title: values.page_title,
        collection_name:values.collection_name,
        menu_label: values.menu_label,
        menu_positon: values.menu_positon,
        SignUp_Form: values.SignUp_Form,
        Admin_Add_Form: values.Admin_Add_Form,
        formsteps: stepsobject,
        _id: state._id
      },
    }
  
    setLoading(true);
    FormsService.UpdateEntity(payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        navigate("/userform-list")
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Entity updated successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update entity at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });

  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = t("Entity Name Required.");
    }
    if (!values.page_title) {
      errors.page_title = t("Entity Title Required.");
    }
    if (!values.menu_label) {
      errors.menu_label = t("Menu Label Required.");
    }
    if (!values.menu_positon) {
      errors.menu_positon = t("Menu Position Required.");
    }
    if (!values.collection_name) {
      errors.collection_name = t("Collection name Required.");
    }
    return errors;
  };

  function addRow1(){
    setrows(1);
  }

  function addRow(){
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);
  }


  function remove_option(index){
    document.getElementById('parent'+index).remove();
    // //console.log("document.getElementById('parent'+"+index+").remove()");
    // var saddRow = rows;
    // saddRow = saddRow - 1;
    // setrows(saddRow);
  }

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-pen icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update User Types')}
                  <div className="page-title-subheading">
                  {t('Edit the form below to update a user type.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-12">
            <Card className="main-card mb-3">
              <CardBody>
                <Form onSubmit={onSubmit}
                  initialValues={state}
                  validate={validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="name">{t('Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="page_title">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="page_title">{t('Page Title')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Page Title')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>

                            <FormGroup>
                              <Field name="menu_label">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_label">{t('Menu Label')}</Label>
                                    <Input {...input} type="text"
                                      invalid={meta.error && meta.touched}
                                      placeholder={t('Entity Menu Label')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Field name="menu_positon">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="menu_positon">{t('Menu Postion')}</Label>
                                    <Input {...input} type="number" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Entity Menu Position')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="SignUp_Form">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="SignUp_Form">{t('SignUp Form')}</Label>
                                    <Input {...input} type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose an option--')}</option>
                                      <option key="0" value ="1">{t('Yes')}</option>
                                      <option key="1" value ="0">{t('No')}</option>
                                      
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>

                            <FormGroup>
                              <Field name="Admin_Add_Form">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="Admin_Add_Form">{t('Admin Add Form')}</Label>
                                    <Input {...input} type="select" 
                                      invalid={meta.error && meta.touched} 
                                    >
                                      <option disabled={true} value="">{t('--Choose an option--')}</option>
                                      <option key="0" value ="1">{t('Yes')}</option>
                                      <option key="1" value ="0">{t('No')}</option>
                                      
                                    </Input>
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            
                           
                          </Col>


                          {Array.from({ length: rows }, (_, index) => (
                              <div className="row " id={"parent"+index}>
                                <div className="col-md-6 col-xl-6" >
                                  
                                  <div className="form-group">
                                    <label htmlFor="steps[]">{t('Step')} {index+1 } {t('Label')}</label>
                                      <Input type="text" className="form-control stepslable" placeholder={t('Step Label')}  id={"step_lable"+index}  defaultValue={formstepsData[index]}     />
                                    </div>

                                  </div> 
                                  
                                  <div className="col-md-1 col-xl-1" >
                                    <div className="form-group">
                                      <br/>
                                      {index > 0 &&<a onClick={() =>remove_option(index)} style={{ cursor: "pointer" }} className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger">
                                      
                                          <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                        
                                      </a>}
                                    </div>
                                  </div> 
                                  <div className="col-md-4 col-xl-4" >
                                  <br/>
                                    <a  onClick={() => addRow()} style={{ cursor: "pointer" }} className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-success">
                                      
                                        <i className="pe-7s-plus btn-icon-wrapper"> </i>
                                      
                                    </a>
                                  </div>
                              </div>
                            ))}
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid}>{t('Update Type')}</button>
                              </div>
                            </FormGroup>

                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};
export default AddEntity;






























