import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";

const IMAGEURL = process.env.REACT_APP_IMAGEURL

const CustomerBasicInfo = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);



  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th >Name of Representative</th>
                    <td>{state.name_of_representative}</td>
                  </tr>
                  <tr>
                    <th>NRIC / Passport No.</th>
                    <td>{state.nric_passport_no}</td>
                  </tr>
                  <tr>
                    <th >Contact No.</th>
                    <td>{state.contact_no}</td>
                  </tr>
                  <tr>
                    <th >Designation</th>
                    <td>{state.designation}</td>
                  </tr>
                  <tr>
                    <th >Email</th>
                    <td>{state.email}</td>
                  </tr>
                  <tr>
                    <th >Nationality</th>
                    <td>{state.nationality}</td>
                  </tr>
                  {/* <tr>
                    <th >Payment Slip</th>
                    <td>
                      <a href={`${IMAGEURL}${state.upload_payment_slip}`}
                        target="_blank"
                        rel="noopener noreferrer">
                        Open File
                      </a>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default CustomerBasicInfo;

