import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CommonService from "../../../pages/Entities/entities.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../../components/Alerts/alerts";


const Input = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 16%;
  border-radius: 6px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
  background: white;
  margin-right: 20%;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20%;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const FunctionFilter = ({ filterText3, handleKeyDown, onFilter3, onClear }) => {

  const { t, i18n } = useTranslation();
  const [functions, setFunctions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchFunctions();
  }, []);

  function fetchFunctions() {
    CommonService.fetchFunctionNames().then((response) => {
      console.log("Get Functions response:-", response.data.data)
      if(response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setFunctions(response.data.data);
      }
    },
    (error) => {
      const errContent =
      (error.response && error.response.data) || error.message || error.toString();
      if(errContent) {
        Alerts.swalErrorAlert(errContent)
      }
      else {
        Alerts.swalErrorAlert(t("Server Error, Please try again later."))
      }
    })
  };

  return(
    <>
      <Input
        type="select"
        placeholder={t("Filter by function")}
        value={filterText3}
        onChange={onFilter3}
        onKeyDown={handleKeyDown}
        id="funcfilter"
      >
      <option disabled={false} value="">{`--${t("Select Function")}--`}</option>
        {functions &&
          functions.map((func, inde) => (
            <option key={inde} value={func.function_name}>{func.function_name}</option>
          ))
        }
      </Input>
      {/* <ClearButton onClick = {onClear}>X</ClearButton> */}
    </>
  );
  
};

export default FunctionFilter;


