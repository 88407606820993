import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import $ from 'jquery';

const DateRangePickerComponent = ({ onDateChange }) => {
    const dateRangePickerRef = useRef(null);

    useEffect(() => {
        const start = moment().subtract(30, 'days');
        const end = moment().add(1, 'days');

        const updateText = (start, end) => {
            dateRangePickerRef.current.innerText = start.format('DD-MM-YYYY') + ' to ' + end.format('DD-MM-YYYY');
        };

        const cb = (start, end) => {
            updateText(start, end);

            // Format start and end dates in "yyyy-mm-dd" format
            const startDateFormatted = start.format('YYYY-MM-DD');
            const endDateFormatted = end.format('YYYY-MM-DD');
            console.log(startDateFormatted, endDateFormatted);
            if (startDateFormatted === 'Invalid date' && endDateFormatted === 'Invalid date') {
                onDateChange('', '');

            } else {
                onDateChange(startDateFormatted, endDateFormatted);
            }
        };

        $(dateRangePickerRef.current).daterangepicker({
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],

            }
        }, cb);

        cb(start, end);
    }, []);

    return (
        <div id="reportrange" ref={dateRangePickerRef} style={{ background: '#F9F9FB', cursor: 'pointer', padding: '5px 10px', width: '100%' }}>
            <i className="fa fa-calendar"></i>&nbsp;
            <span>No date range selected</span> <i className="fa fa-caret-down"></i>
        </div>
    );
};

export default DateRangePickerComponent;
