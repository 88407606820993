import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}



const fetchMembershipTrends = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/' + payload.functionName, payload, { headers });
};
const fetchExpiredMembers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/' + payload.functionName, payload, { headers });
};


const GetOrdersDetails = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/GetOrdersDetails', payload, { headers });
};
const GetDashboardData = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/GetDashboardData', payload, { headers });
};
const Get_Member_Distribution_Data = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/Get_Member_Distribution_Data', payload, { headers });
};
const Get_Geographical_distribution_Data = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DashboardRoutes/Get_Geographical_distribution_Data', payload, { headers });
};
const GetOrders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const GetCustomers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const getAllFleets = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'FleetsRoutes/' + payload.functionName, payload, { headers });
};

const getAll = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(API_URL, payload, { headers });
};
const getAllStatements = (page, limit, filter) => {
  var payload = {
    functionName: "User_Record_Dashboard",
    Customer_ID: filter,
    limit: limit,
    offset: page
  }
  return axios.post(B_URL + "statements", payload, { headers });
};

const DashboardService = {
  Get_Member_Distribution_Data,
  Get_Geographical_distribution_Data,
  fetchExpiredMembers,
  fetchMembershipTrends,
  GetDashboardData,
  GetOrdersDetails,
  GetCustomers,
  GetOrders,
  getAllFleets,
  getAll,
  getAllStatements,
};

export default DashboardService;