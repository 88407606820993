import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const Input = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 16%;
  border-radius: 6px;
  border: 1px solid var(--buttons-color, #30b1ff);
  padding: 0 8px 0 8px;
  margin-bottom: 12px;
  justify-content: flex-start;
  background: white;
  margin-right: 12px;
`;

const ClearButton = styled.button`
  border:none;
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 0 5px 5px 0;
  color: white;
  background: var(--buttons-color, #30b1ff);
`;

const ActionFilter = ({ filterText2, handleKeyDown, onFilter2, onClear }) => {

  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  return (
    <>
      <Input
        type="select"
        placeholder={t("Filter by action")}
        value={filterText2}
        onChange={onFilter2}
        onKeyDown={handleKeyDown}
        id="actfilter"
      >
        <option disabled={false} value="">{`--${t("Select Action")}-`}-</option>
        <option value="INSERT">{t("INSERT")}</option>
        <option value="DELETE">{t("DELETE")}</option>
        <option value="UPDATE">{t("UPDATE")}</option>
      </Input>
      {/* <ClearButton onClick={onClear}>X</ClearButton> */}
    </>

  );

};

export default ActionFilter;


