import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import moment from "moment";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
// import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

import PaymentService from "./payments.service.js";
import AuthService from "../../../Authentication/auth.service";
import RoleService from "../../../Roles/roles.service";


const AddUser = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log(state);
  const [loading, setLoading] = useState(false);
  const [Receipt, setReceipt] = useState(state?.Receipt);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));

  }, []);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    const paymentDateInput = document.getElementById('payment_date');

    if (paymentDateInput) {
      paymentDateInput.setAttribute('max', today);
    }
  }, []);




  const onFileChange = (e) => {
    console.log(e.target.files)
    var filename = e.target.files[0].name
    console.log("File Name", filename)
    var fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      const convertedPDF = fileReader.result
      console.log("Converted PDF File:-", convertedPDF)
      setReceipt(convertedPDF);
    }
  }


  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      _id: state.state._id,
      functionName: "UpdatePayments",
      invoice_number: values.invoice_number,
      initial_amount: state.state.amount,
      amount: parseFloat(values.amount).toFixed(2),
      payment_method: values.payment_method,
      customer_id: state.state?.user_details?._id,
      transaction_id: values.transaction_id,
      is_moderated: state.state.is_moderated,
      status: values.status,
      Receipt: Receipt,
      payment_date: values.payment_date,
      invoice_id: state.state.invoice_id,
      currency: localStorage.getItem("currency")

    }
    console.log(payloadData);
    setLoading(true);
    PaymentService.updatePayment(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (state.check) {
          localStorage.setItem('tab5', "5");
        }

        navigate(-1)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Payment Updated Successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add Payment at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });

  }
  const validateFtn = (values) => {
    const errors = {};
    if (!values.invoice_number) {
      errors.invoice_number = t("Invoice Number Required.");
    }
    if (!values.amount) {
      errors.amount = t("Amount Required.");
    }
    if (!values.customer_id) {
      errors.customer_id = t("Customer Required.");
    }
    if (parseFloat(values.amount).toFixed(2) < parseFloat(state.state.amount).toFixed(2)) {

    } else if (parseFloat(values.amount).toFixed(2) === parseFloat(state.state.amount).toFixed(2)) {
    } else {
      errors.amount = t("Amount Should be less or equal to Total amount.");

    }
    // if (!values.channel) {
    //   errors.channel = t("Channel Required  ");
    // }
    // if (!values.payment_method) {
    //   errors.payment_method = t("Method Required.");
    // }
    if (!values.payment_date) {
      errors.payment_date = t("Payment Date Required");
    }

    return errors;
  };

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-add-user icon-gradient bg-happy-green" />
                </div>
                <div>{t('Update Payment for Invoice')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to Update payment details for invoice.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Card className="main-card mb-3">
                <CardBody>
                  <Form onSubmit={onSubmit}
                    validate={validateFtn}
                    initialValues={state.state}
                    render={({ handleSubmit, values, submitting, validating, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="invoice_number">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="invoice_number">{t('Invoice Number')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Invoice Number')}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="amount">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="amount">{t(`Amount (${localStorage.getItem("currency")})`)}</Label>
                                      <Input {...input} type="number"
                                        value={parseFloat(input.value).toFixed(2)}
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Amount')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="customer_id" initialValue={state.state.user_details ? state.state?.user_details[localStorage.getItem('invoice_to_field')] : 'unknown'}>
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="customer_id">{t('Customer Name')}</Label>
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        disabled
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="receipt">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="method">{t('Payment Receipt')}</Label>
                                      <Input name="file" type='file' onChange={onFileChange} />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Field name="payment_method">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="payment_method">{t('Payment Method')}</Label>
                                      <Input {...input} type="text"
                                        disabled
                                        invalid={meta.error && meta.touched}
                                        placeholder='Payment Method'
                                      >
                                      </Input>
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                              <FormGroup>
                                <Field name="payment_date">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="payment_date">{t('Payment Date')}</Label>
                                      <Input
                                        {...input}
                                        id="payment_date"
                                        type="date"
                                        value={moment(input.value).format("YYYY-MM-DD")}
                                        // Display the date in "YYYY-MM-DD" format in the input
                                        invalid={meta.error && meta.touched}
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="text-danger">{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                              <FormGroup>
                                <Field name="status">
                                  {({ input, meta }) => (
                                    <div>
                                      <Label for="status">{t('Status')}</Label>
                                      <Input
                                        {...input}
                                        placeholder={'Select Verification Status'}
                                        type="select"
                                        invalid={meta.error && meta.touched}
                                      >
                                        <option disabled={false} value="">--{t("Select Status")}--</option>
                                        <option key='paid' value='paid'>{t("Paid")}</option>
                                        <option key='In Process' value='In Process'>{t("In Process")}</option>
                                      </Input>
                                      {meta.error && meta.touched && (
                                        <span className="text-danger">{meta.error}</span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>

                            </Col>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => {
                                  if (state.check) {
                                    localStorage.setItem('tab5', "5");
                                  }
                                  navigate(-1)
                                }} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" disabled={!valid} >{t('Update Payment!')}</button>
                              </div>
                            </FormGroup>

                          </Row>
                        </LoadingOverlay>
                      </form>
                    )}
                  />
                </CardBody>
              </Card>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );

};
export default AddUser;