import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  CardBody,
  Card
} from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import ItemService from "./Item.service";
import AuthService from "../Authentication/auth.service";

import DataTable from 'react-data-table-component';



const DriversList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [fieldlist, setfieldlist] = useState([])

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchData(0, pageLimit);
  }, [pageLimit]);


  useEffect(() => {
    fetchDynamicData();
  }, []);

  const fetchDynamicData = async () => {
    try {
      const response = await ItemService.get_cruds_dynamic_data();
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data;
        console.log(data);
        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {

            const optresponse = await ItemService.getoptions(custom_fields[i].options_collection, "", {}, 5000);
            const optdata = await optresponse.data.data;
            var options = [];

            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, })
            }
            custom_fields[i].options = options;
          }
          custom_fields[i].default_value = ""
        }

        data[0].custom_fields = custom_fields;

        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i = 0; i < cust.length; i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  function fetchData(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["name"] = searchName
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    ItemService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchData(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    var offset = 0;
    var getData = {
      functionName: "getItems",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    ItemService.getAllDrivers(getData).then((response) => {
      setLoading(false);
      console.log("Get Drivers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDrivers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchItems() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  function confirmDeleteItems(_id) {
    let msg = t("Once deleted, you will not be able to recover this Item!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    ItemService.deleteDriver(id).then((response) => {
      setLoading(false);
      console.log("Delete Item response:-", response)
      if (response.data.status) {
        fetchData(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Item Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the driver at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  let mycolumns = [];

  mycolumns.push(
    {
      name: t("Name"),
      selector: row => row.item_name,
    },
    {
      name: t("Fee"),
      selector: row => row.price,
    },
  );

  const filteredFields = fieldlist.filter(field => field.data_table === '1');

  mycolumns = mycolumns.concat(filteredFields.map((field, index) => ({
    name: t(`${field.field_lable}`),
    selector: (row) => row[field.referal_name]
  })));

  mycolumns.push(
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteItems(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              <Tooltip placement="top" title="Edit" arrow>
                <i>
                  <Link to="/update-Items" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltip >
            </button>
          </div>
        );
      }
    }
  );
  // Now mycolumns contains the dynamically generated columns correctly

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t("Manage Membership Types")}
                        <div className="page-title-subheading">
                          {t('View all Memberships, Create, Edit and Delete a Membership Type.')}
                        </div>

                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t("Back")}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>

                            <LoadingOverlay tag="div" active={loading}
                              styles={{ overlay: (base) => ({ ...base }) }}
                              spinner={<DNALoader />}>
                              <Row>
                                <Col md="3">
                                  <Input
                                    type="text"
                                    name='name'
                                    value={searchName}
                                    placeholder='Search Text'
                                    onChange={(event) => nameFilter(event)}
                                  />
                                </Col>
                                <Col md="9" className="d-flex justify-content-end align-items-center">
                                  <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine ms-1" onClick={() => searchItems()}>{t("Search")}</button>
                                  <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => resetFilters()}>{t("Reset")}</button>
                                  <a style={{ marginLeft: '5px' }} href="/add-Items" className="btn btn-custom-color btn-hover-shine ms-1 float-end">
                                    {t("Add New")}</a>
                                </Col>

                              </Row>

                              <Col md="12" className="pt-3">
                                <DataTable
                                  data={drivers}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </Col>

                            </LoadingOverlay>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>
                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
};

export default DriversList;