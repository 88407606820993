import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";

import AuthService from "./auth.service";
import AlertService from "../../components/Alerts/alerts";

import { ToastContainer } from 'react-toastify';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import CopyRight from "../../components/CopyRight Bar/CopyRight";

import ConfigurationService from "../Configuration/configuration.service";
import FinanceService from "../Finance/Settings/configuration.service";

const Login = () => {

  useEffect(() => {
    getSetLanguage();
    setTimeout(() => {
      getFinanceConfig();
      getSystemConfig();
    }, 2000);
  }, []);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(localStorage.getItem("system_logo"));
  const [system_name, setSystem_Name] = useState(localStorage.getItem("name"));


  function getFinanceConfig() {
    FinanceService.getConfiguration().then((response) => {
      console.log("Finance API Response:-", response.data.data);
      if (response.data.status) {
        localStorage.setItem("payment_method", response.data.data[0]?.payment_method);
        localStorage.setItem("due_date", response.data.data[0]?.due_date);
        localStorage.setItem("default_notes", response.data.data[0]?.default_notes);
        localStorage.setItem("tax_percentage", response.data.data[0]?.tax_percentage);
        localStorage.setItem("currency", response.data.data[0]?.currency);
        localStorage.setItem("invoice_to", response.data.data[0]?.invoice_to);
        localStorage.setItem("invoice_to_field", response.data.data[0]?.invoice_to_field);
        localStorage.setItem("payment_types", response.data.data[0]?.payment_types);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }
  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }

  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        setColors(response.data.data[0].buttons_color, '--buttons-color');
        setColors(response.data.data[0].topbar_color, '--topbar-color');
        setColors(response.data.data[0].leftbar_color, '--leftbar-color');
        setColors(response.data.data[0].buttons_text_color, '--buttons-text-color');
        setSystemLogo(response.data.data[0].system_logo);
        setSystemName(response.data.data[0].name);
      }
      else {
        setColors('#3ac47d', 'onerror');
      }
    },
      (error) => {
        setColors('#3ac47d', 'onerror');
      });
  }

  function setColors(color, param) {
    if (param === '--buttons-color') {
      localStorage.setItem("buttons_color", color);
      document.documentElement.style.setProperty('--buttons-color', color)
    }
    if (param === '--topbar-color') {
      localStorage.setItem("topbar_color", color);
      document.documentElement.style.setProperty('--topbar-color', color)
    }
    if (param === '--leftbar-color') {
      localStorage.setItem("leftbar_color", color);
      document.documentElement.style.setProperty('--leftbar-color', color)
    }
    if (param === '--buttons-text-color') {
      localStorage.setItem("buttons_text_color", color);
      document.documentElement.style.setProperty('--buttons-text-color', color)
    }
    if (param === 'onerror') {
      localStorage.setItem("buttons_color", color);
      localStorage.setItem("topbar_color", '#fff');
      localStorage.setItem("leftbar_color", '#fff');
      localStorage.setItem("buttons_text_color", '#fff');
      document.documentElement.style.setProperty('--buttons-color', color)
      document.documentElement.style.setProperty('--topbar-color', '#fff')
      document.documentElement.style.setProperty('--leftbar-color', '#fff')
      document.documentElement.style.setProperty('--buttons-text-color', '#fff')
    }
  }

  function setSystemLogo(sys_logo) {
    setLogo(sys_logo);
    localStorage.setItem("system_logo", sys_logo);
  }
  function setSystemName(name) {
    setSystem_Name(name);
    localStorage.setItem("system_name", name);
  }
  function handlereset() {
    localStorage.setItem("reset", true)
  };

  function handleregprod() {
    localStorage.setItem("regprod", true)
  };

  const onSubmit = values => {
    console.log(values);
    setLoading(true);
    AuthService.login(values.email, values.password).then((response) => {
      console.log("Login:-", response.role_details);
      setLoading(false);
      var landingpage = response?.role_details?.landingpage
      // AlertService.successToast("Login Successful.")
      navigate("/" + landingpage);
      window.location.reload();
    },
      (error) => {
        setLoading(false);
        const resMessage =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        if (resMessage === 'Unauthorized') {
          AlertService.swalErrorAlert(t("Invalid Credentials."))
        }
        else {
          AlertService.swalErrorAlert(resMessage)
        }
      }
    );
  };

  const validateFtn = (values) => {
    const errors = {};
    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    if (!values.email) {
      errors.email = t('Email Required.');
    }
    else if (!validateEmail(values.email)) {
      errors.email = t('Please enter a valid email address.');
    }
    if (!values.password) {
      errors.password = t('Password Required.');
    }
    return errors;
  };

  return (
    <Fragment>
      <div className="h-100 bg-light-gray bg-animation">
        <div className="d-flex h-100 justify-content-center align-items-center">
          <Col md="8" className="mx-auto app-login-box">
            <Form onSubmit={onSubmit}
              validate={validateFtn}
              render={({ handleSubmit, values, submitting, validating, valid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="modal-dialog w-100 mx-auto shadow-sm">
                    <LoadingOverlay tag="div" active={loading}
                      styles={{ overlay: (base) => ({ ...base }) }}
                      spinner={<DNALoader />}>
                      <div className="modal-content border-light">
                        <div className="modal-body p-5">
                          <div className="h5 modal-title text-center">
                            <img alt="MPBC" src={logo} style={{ width: '40%', height: 'auto' }} className="app-logo mx-auto mb-3" />
                            <h6 className="mb-5">
                              <span>{t('Sign in to your account below.')}</span>
                            </h6>
                          </div>
                          <Row form>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="mb-3">
                                      <Label for="email">{t('Email')}</Label>
                                      <Input {...input} type="email"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Enter Email')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup>
                                <Field name="password">
                                  {({ input, meta }) => (
                                    <div className="mb-3">
                                      <Label for="password">{t('Password')}</Label>
                                      <Input {...input} type="password"
                                        invalid={meta.error && meta.touched}
                                        placeholder="**********"
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  )}
                                </Field>
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup check className="mb-3">
                            {/* <Input type="checkbox" name="check" id="exampleCheck" />
                                <Label for="exampleCheck" check>
                                  Keep me logged in
                                </Label> */}
                          </FormGroup>
                          <div>

                            <div className="d-grid mb-3">
                              <Button size="lg" className="btn-custom-color" type="submit" disabled={!valid}>
                                {t('Login')}
                              </Button>
                            </div>
                            <div className="text-center" onClick={handlereset}>
                              <a href="/forgot-password" className="btn-lg btn link-btn-custom-text-color btn-link">
                                {t('Forgot password?')}
                              </a>
                            </div>
                            {/* <div className="text-center" onClick={handleregprod}>
                                  <a href="/customer/register-product" className="btn-lg btn link-btn-custom-text-color btn-link">
                                    Register Warranty as Customer
                                  </a>
                                </div> */}
                          </div>
                        </div>
                      </div>
                    </LoadingOverlay>
                  </div>
                </form>
              )}
            />
            {/* {<CopyRight/>} */}
          </Col>
        </div>
      </div>
    </Fragment>
  );
};
export default Login;