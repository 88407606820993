import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate, Link } from 'react-router-dom';
import {
  Row,
  Col, CardBody, Card,

  Label
} from "reactstrap";
import $ from 'jquery';
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";
import StatementsService from "./Statements.service";
import AuthService from "../../Authentication/auth.service";


import DataTable from 'react-data-table-component';

import StatementFilter from "../../../components/Table Filters/InvoiceFilter/StatementFilter";
import AlertService from "../../../components/Alerts/alerts";
import { Divider } from "@mui/material";



const CustomersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoice_EndDate, setFilterInvoice_EndDate] = useState('');
  const [filterDue_StartDate, setFilterDue_StartDate] = useState('');
  const [filterDue_EndDate, setFilterDue_EndDate] = useState('');

  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);
  function setFilterTextFtn(itemrec, param) {
    console.log(itemrec)
    console.log('Value of key1:', itemrec);
    if (param === 'users') {
      setFilterText1(itemrec);
    }
    if (param === 'orders') {
      setFilterText2(itemrec);
    }
  };
  const [value, onChange] = useState(new Date());



  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };




  function fetchCustomers(page_index, page_limit, filterText1,
    filterInvoice_StartDate, filterInvoice_EndDate) {
    var filter = {};
    console.log(filterText1);
    if (filterText1) {
      console.log("filterText1");
      filter["customer_id"] = filterText1
    }


    if (filterInvoice_StartDate) {
      console.log("filterInvoice_StartDate");
      filter["filterInvoice_StartDate"] = filterInvoice_StartDate
    } if (filterInvoice_EndDate) {
      filter["filterInvoice_EndDate"] = filterInvoice_EndDate
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);

    StatementsService.getAllStatements(offset, page_limit, filterText1, filterInvoice_StartDate, filterInvoice_EndDate).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data);
        setCustomers(response.data);
        if (response.data.data) {
          setTotalRows(response.data.length);
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function handleClear(item) {
    if (item === 'users') {
      $("#colfilter").val('');
      setFilterText1('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText1('');
    setFilterInvoice_StartDate('');
    setFilterInvoice_EndDate('');
    setFilterDue_StartDate('');
    setFilterDue_EndDate('');
    setFilterText2('');
    fetchCustomers(0, pageLimit);

  }

  function searchCustomers() {
    if (filterText1) {
      setResetPaginationToggle(!resetPaginationToggle);
      const page = 0;

      // Filter values
      const filteredText1 = filterText1 || "";
      const filteredText2 = filterText2 || "";
      const FilterInvoice_StartDate1 = filterInvoice_StartDate || "";
      const FilterInvoice_EndDate2 = filterInvoice_EndDate || "";
      fetchCustomers(0, pageLimit, filteredText1, FilterInvoice_StartDate1,
        FilterInvoice_EndDate2);
    } else {
      AlertService.swalErrorAlert("Please Select Customer");
    }
  }

  function confirmDeleteCustomer(_id, order_no, status) {
    if (status === 'generated') {
      let msg = t("Once deleted, you will not be able to recover this Invoice!")
      Alerts.swalConfirmDeleteAlert(msg).then(res => {
        console.log("Resp:-", res)
        if (res === true) {
          sendDelete(_id, order_no)
        }
      })
    } else {
      AlertService.swalErrorAlert("Invoice status should be Generated")
    }
  };


  function sendDelete(id, order_no) {
    setLoading(true);
    StatementsService.deleteInvoice(id).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          var payload = {
            functionName: "UpdateOrder",
            postData: {
              _id: order_no,
              invoice_status: "false"
            },
          }
          console.log(payload);
          StatementsService.UpdateOrderStatus(payload).then((response) => {
            console.log(response);
            setLoading(false);
            Alerts.swalSuccessAlert(t("Statement Deleted Successfully."))
          });

        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };
  console.log(customers)
  const mycolumns = [
    {
      name: "ID",
      sortable: true,
      selector: (row) => {
        if (row.Payment_ID) {
          return <div>{row.Payment_ID}</div>;
        } else {
          return <div >{row.invoice_number}</div>
        }
      }
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => {
        if (row.payment_date) {
          return <div>{format(parseISO(row.payment_date), 'dd-MM-yyyy')}</div>;
        } else {
          return <div >{format(parseISO(row.invoice_date), 'dd-MM-yyyy')}</div>
        }
      }
    },
    {
      name: "Due Date",
      sortable: true,
      selector: (row) => {
        if (row.due_date) {
          return <div>{format(parseISO(row.due_date), 'dd-MM-yyyy')}</div>;
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => {
        if (row.Payment_ID) {
          return <div>Payment</div>;
        } else {
          return <div >Invoice</div>
        }
      }
    },
    {
      name: "Invoice Amount",
      sortable: true,
      selector: (row) => {
        if (row.total_amount) {
          return <div >{`${row.currency}${parseFloat(row.total_amount).toFixed(2)}`} </div>
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Payment Amount",
      sortable: true,
      selector: (row) => {
        if (row.amount) {
          return <div >{`${row.currency}${parseFloat(row.amount).toFixed(2)}`} </div>
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Invoice",
      sortable: true,
      selector: (row) => {
        if (row.invoices) {
          return <div>{row.invoices.invoiceNumbers}</div>;
        } else {
          return <div ></div>
        }
      }
    },
    // {
    //   name: 'Actions',
    //   selector: (row) => {
    //     return (
    //       <div>
    //         <button className="mt-2 mb-2 me-2 btn-icon btn">
    //           <Tooltip placement="top" title="Send Email" arrow>
    //             <i onClick={() => handleMail(row)} className="pe-7s-mail text-primary btn-icon-wrapper"></i>
    //           </Tooltip>

    //           <Tooltip placement="top" title="Make Payment" arrow>
    //             <i onClick={() => handlePayments(row)} className="pe-7s-print text-primary btn-icon-wrapper"></i>
    //           </Tooltip>
    //           <Tooltip placement="top" title="View PDF" arrow>
    //             <i onClick={() => handleViewPDF(row._id)} className="pe-7s-look text-primary btn-icon-wrapper"> </i>
    //           </Tooltip>
    //           <Tooltip placement="top" title="Download PDF" arrow>
    //             <i onClick={() => handlePDF(row._id)} className="pe-7s-download text-success btn-icon-wrapper text-info"> </i>
    //           </Tooltip>
    //           <Tooltip placement="top" title="Update Invoice" arrow>
    //             <i>
    //               <Link to="/update-invoice" state={{ state: row, check: false }}>
    //                 <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
    //               </Link>
    //             </i>
    //           </Tooltip>
    //           <Tooltip placement="top" title="Delete Invoice" arrow>
    //             <i onClick={() => confirmDeleteCustomer(row._id, row.order_no, row.status)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
    //           </Tooltip>
    //         </button>
    //       </div>
    //     );
    //   }
    // },
  ];
  const downloadPDF = (response) => {
    console.log("PDF Data Length: ", response.data.length);
    console.log("Partial PDF Data: ", response.data.slice(0, 100));
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'invoice.pdf'; // Set the desired file name here.
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };

  const handlePDF = () => {
    var getData = {
      functionName: "User_Record_PDF",
      Customer_ID: filterText1,
      startDate: filterInvoice_StartDate,
      endDate: filterInvoice_EndDate,
    }
    setLoading(true);
    StatementsService.User_Record_PDF(getData).then((response) => {
      console.log(response);
      const pre = document.createElement('pre');
      pre.textContent = response.data;
      document.body.appendChild(pre);
      setLoading(false);
      if (response.status === 200) {

        downloadPDF(response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        // Handle other error cases, if necessary
        console.log("Response status: " + response.status);
        // Display an error message or perform other actions as needed.
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }
  const handleViewPDF = (_id) => {
    var getData = {
      functionName: "User_Record_PDF",
      Customer_ID: filterText1,
      startDate: filterInvoice_StartDate,
      endDate: filterInvoice_EndDate,
    }
    setLoading(true);
    StatementsService.User_Record_PDF(getData)
      .then((response) => {
        setLoading(false);
        console.log(response.status);
        console.log(response);
        if (response.status === 200) {
          setPdfUrl(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));
          setPDFModalIsOpen(true);
        } else if (response.status === 401) {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          // Handle other error cases, if necessary
          console.log("Response status: " + response.status);
          // Display an error message or perform other actions as needed.
        }
      })
      .catch((error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  };

  const PDFViewerModal = () => {
    console.log(pdfUrl);
    return (
      <div
        className={`modal fade ${pdfModalIsOpen ? "show" : ""}`}
        style={{ display: pdfModalIsOpen ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header ">
              <h5 className="modal-title">Customer's Statement of Invoices & Payments</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setPDFModalIsOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <iframe title="pdfUrl" src={pdfUrl} style={{ width: "100%", height: "500px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const handlePayments = (row) => {
    if (row.status !== 'paid') {
      navigate('/add-payment', { state: row, check: false });
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }


  function handleMail(row) {
    let msg = t("Are you sure you want to send Invoice in Email?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendMail(row)
      }
    })
  };

  function sendMail() {
    setLoading(true);
    var getData = {
      functionName: "User_Record_PDF_Mail",
      Customer_ID: filterText1,
      startDate: filterInvoice_StartDate,
      endDate: filterInvoice_EndDate,
    }
    StatementsService.sendPDF_Mail(getData).then((response) => {
      console.log("Send mail to:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Invoice emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };




  return (
    <>
      <PDFViewerModal />
      <Fragment>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className=" bg-light-gray bg-animation ">
              <div className="d-flex  justify-content-center align-items-center">
                <Col md="12" className="mx-auto app-login-box">
                  <div className="app-page-title">
                    <div className="page-title-wrapper">
                      <div className="page-title-heading fw-bold">
                        <div className="page-title-icon">
                          <i className="pe-7s-notebook icon-gradient bg-tempting-azure" />
                        </div>
                        <div>
                          Statements Management
                          <div className="page-title-subheading">
                            {t(`View & Download Details of Invoices & Payments Of Customers.`)}
                          </div>
                        </div>
                      </div>
                      <div className="page-title-actions">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <Row>


                    <Fragment>
                      <Row>
                        <Col md="12">
                          <Card className="main-card mb-3">
                            <CardBody>
                              <LoadingOverlay tag="div" active={loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>



                                <StatementFilter

                                  onFilterInvoice_StartDate={setFilterInvoice_StartDate}
                                  onFilterInvoice_EndDate={setFilterInvoice_EndDate}
                                  searchCustomers={searchCustomers}
                                  handlePDF={handlePDF}
                                  sendMail={sendMail}
                                  viewPDF={handleViewPDF}
                                  resetFilters={resetFilters}
                                  onFilter1={(e) => {
                                    console.log(e.target.value);
                                    setFilterTextFtn(e.target.value, 'users');
                                  }}

                                  handleKeyDown={(event) => checkenterkey(event)}
                                  filterText1={filterText1}
                                  filterText2={filterText2}
                                  filterInvoice_StartDate={filterInvoice_StartDate}
                                  filterInvoice_EndDate={filterInvoice_EndDate}

                                  onClear={() => handleClear('users')}
                                />
                                {customers && customers.totalAmount && (
                                  <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                                    <table style={{ width: '100%' }}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div style={{ backgroundColor: '#E8E8E8', width: "330px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              Amount Summary
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div style={{ width: "220px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              Total Dues:
                                            </div>
                                            <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              {`${customers.result[0] ? customers.result[0].currency : ''}${parseFloat(customers.totalAmount).toFixed(2)}`}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div style={{ width: "220px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              Paid Dues:
                                            </div>
                                            <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              {`${customers.result[0] ? customers.result[0].currency : ''}${parseFloat(customers.paidAmount).toFixed(2)}`}
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div style={{ width: "330px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              <Divider />
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div style={{ width: "220px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              Remaining Dues:
                                            </div>
                                            <div style={{ width: "110px", textAlign: 'left', padding: '5px', display: 'inline-block' }}>
                                              {`${customers.result[0] ? customers.result[0].currency : ''}${parseFloat(customers.RemainingAmount).toFixed(2)}`}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}





                                <DataTable
                                  data={customers.result}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  noDataComponent={
                                    <div className="dataTables_wrapper">
                                      <div style={{ marginTop: '20px' }} className="dataTables_empty">
                                        <p>Please Select a customer or this customer may have no Data</p>
                                      </div>
                                    </div>
                                  }
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </LoadingOverlay>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    </>

  );
};

export default CustomersList;