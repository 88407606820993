import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import moment from 'moment';

import { useNavigate, useLocation } from "react-router-dom";

import CustomerService from "../Customer.service";
import AuthService from "../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";

import DocsAddCustomer from "../Customer Modals/DocsAddCustomer";
import DocsUpdateCustomer from "../Customer Modals/DocsUpdateCustomer";


const CustomerDocs = () => {

  const [documents, setdocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [modal, setModal] = useState(false);
  const [pdfData, setPdfData] = useState('');

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchSingleCustomerDocs();
  }, []);

  function fetchSingleCustomerDocs() {
    var getData = {
      functionName: "GetUserDocuments",
      postData: {
        user_id: state._id
      },
    }
    setLoading(true);
    CustomerService.getCustomerDocs(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Docs response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setdocuments(response.data.data)

      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handleSuccess() {
    fetchSingleCustomerDocs();
  };

  function confirmDeleteDocument(_id) {
    let msg = t("Once deleted, you will not be able to recover this Document!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    CustomerService.deleteDocument(id).then((response) => {
      setLoading(false);
      console.log("Delete document details response:-", response)
      if (response.data.status) {
        fetchSingleCustomerDocs()
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Document Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Document at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };
  const toggle = () => {
    setModal(!modal);
  };


  const handleDocView = async (row) => {
    console.log(row.file)
    if (row.file.startsWith("data:application/pdf;base64")) {
      const pdfData = row.file.split(',')[1];
      // Creating a Blob from the base64 string
      const byteCharacters = atob(pdfData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
      // Create a URL for the Blob
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfData(pdfUrl);
      setModal(!modal);
    } else {
      setPdfData(row.file);
      setModal(!modal);
    }
  }

  const mycolumns = [
    {
      name: "Document Type",
      selector: row => row.documentData && row.documentData[0] && row.documentData[0].name ? row.documentData[0].name : row.documentData[0].pic_name
    },
    {
      name: "File",
      selector: row => {
        return (
          <div>
            <a onClick={() => { handleDocView(row); }} className="color-link" style={{ cursor: "pointer" }}>
              {t("view")}
            </a >
          </div>
          // <div>
          //   <DocsCustomerImage data={row._id} />
          // </div>
        )
      }
    },
    {
      name: "Uploaded At",
      // selector: row => new Date(row.created_at).toDateString().split(' ').slice(1).join(' ')
      // selector: row => new moment(row.created_at, "DD-MM-YYYY").format()
      selector: row => moment(row.created_at).format("DD-MM-YYYY HH:mm:ss A")
    },
    {
      name: "Uploaded By",
      selector: row => row.usersInfo?.name ? row.usersInfo?.name : row.usersInfo?.pic_name
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 btn-icon btn">
              <Tooltip placement="top" title="Update" arrow>
                <i>
                  <DocsUpdateCustomer data={row} onSuccess={handleSuccess} />
                </i>
              </Tooltip>
              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteDocument(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
            </button>
          </div>
        );
      }
    },
  ];



  return (
    <Fragment>
      <Modal isOpen={modal} toggle={toggle}
      // className={className}
      >
        <ModalHeader>{t("Document Viewer")}</ModalHeader>
        <ModalBody>
          <iframe title="pdf-viewer" width={470} height={470} src={pdfData} />

          <div className="modal-footer justify-content-center">
            <button onClick={toggle} className="btn btn-outline-success btn-hover-shine">
              {t("close")}
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <LoadingOverlay tag="div" active={loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <Row className="mt-2">
                  <Col md="6">
                    <h4 className="fw-bold mb-1">Member Documents</h4>
                  </Col>
                  <Col md="6">
                    <DocsAddCustomer data={state._id} onSuccess={handleSuccess} />
                  </Col>
                  <Col md="12" className="pt-3">

                    <DataTable
                      data={documents}
                      columns={mycolumns}
                      pagination
                      fixedHeader
                      fixedHeaderScrollHeight="400px"
                    />

                  </Col>
                </Row>
              </LoadingOverlay>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default CustomerDocs;