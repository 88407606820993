import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";

import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import AuthService from "../Authentication/auth.service";

import CollectionFilter from "../../components/Table Filters/LogsFilters/CollectionFilter";
import FunctionFilter from "../../components/Table Filters/LogsFilters/FunctionFilter";
import ActionFilter from "../../components/Table Filters/LogsFilters/ActionFilter";

import DataTable from 'react-data-table-component';
import Alerts from "../../components/Alerts/alerts";
import $ from 'jquery';
import Tooltip from '@mui/material/Tooltip';

const API_URL = process.env.REACT_APP_BASEURL;

const ActivityLogs = () => {

  const { t, i18n } = useTranslation();

  const [filteredItems, setFilteredItems] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);

  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');
  const [filterText4, setFilterText4] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    getlogs(0, pageLimit);
  }, [pageLimit]);


  async function getlogs(page_index, page_limit) {
    const headers = {
      'Content-Type': 'application/json',
      'token': localStorage.getItem("session-id")
    }
    var filter = {}
    if ($("#colfilter").val() !== "")
      filter["collection_name"] = $("#colfilter").val();
    if ($("#actfilter").val() !== "")
      filter["action"] = $("#actfilter").val();
    // if ($("#modfilter").val() !== "")
    //   filter["module_name"] = $("#modfilter").val();
    if ($("#funcfilter").val() !== "")
      filter["function_name"] = $("#funcfilter").val();

    if (filterText4 !== "")
      filter["datetime_added"] = $("#datefilter").val();
    // "datetime_added": filterText4

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) offset = 0;
    var getData = {
      functionName: "GetAuditLogs",
      postData: {
        "collection_name": "audit_logs",
        "sort": { _id: -1 },
        "limit": page_limit,
        "offset": offset,
        "filter": filter,
      },
    }
    setLoading(true);
    axios.post(API_URL, getData, { headers })
      .then((response) => {
        setLoading(false);
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          const data = response.data.data;
          console.log("Get Loglist Resp:-", data);
          setFilteredItems(data);
          setTotalRows(response.data.count.count);
        }
      }).catch((error) => {
        setLoading(false);
        console.log("API Error:-", error)
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        Alerts.swalErrorAlert("Network Error. Unable to retrieve data from Server")
        // alert(t("Unable to retrieve data from Server"))
      });

  };

  function handlePageChange(page_index) {
    getlogs(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const TBcolumns = [
    {
      name: t('Collection'),
      width: '20%',
      selector: row => row.collection_name,
    },
    {
      name: t('Action'),
      width: '20%',
      selector: row => row.action,
    },
    // {
    //   name: t('Module'),
    //   width: '15%',
    //   selector: row => row.module_name,
    // },
    {
      name: t('Function'),
      width: '20%',
      selector: row => row.function_name,
    }, {
      name: t('Date'),
      width: '20%',
      // selector: row => format(parseISO(row.datetime_added, new Date()), "dd-MMM-yyyy H:i:s"),
      selector: (row) => {
        var date = new Date(row.datetime_added).toISOString()
        date = date.replace("T", " ");
        date = date.slice(0, -5);
        return date;
      }

    },
    {
      name: t('Actions'),
      width: '20%',
      selector: (row) => {
        return (
          <div>
            <Link to='/view-activity-log' state={row} >
              <Tooltip placement="top" title={t("View Log Details")} arrow>
                <button className="mt-2 mb-2 me-2 btn-icon btn-link btn text-info">
                  <i className="pe-7s-news-paper btn-icon-wrapper"> </i>
                </button>
              </Tooltip >
            </Link>
          </div>
        );
      }
    },
  ];

  const subHeaderComponentMemo = React.useMemo(() => {

    return (
      <>
        <CollectionFilter
          onFilter1={e => setFilterTextFtn(e.target.value, 'collection')}
          handleKeyDown={event => checkenterkey(event)}
          filterText1={filterText1}
          onClear={() => handleClear('collection')}
        />
        <ActionFilter
          handleKeyDown={event => checkenterkey(event)}
          onFilter2={e => setFilterTextFtn(e.target.value, 'action')}
          filterText2={filterText2}
          onClear={e => handleClear('action')}
        />
        <FunctionFilter
          handleKeyDown={event => checkenterkey(event)}
          onFilter3={e => setFilterTextFtn(e.target.value, 'function')}
          filterText3={filterText3}
          onClear={e => handleClear('function')}
        />
        <button style={{ marginLeft: '-15%' }} type="button" onClick={search_logs} className="btn btn-custom-color btn-hover-shine me-3 ">
          {t("Search")}
        </button>
        <button type="button" onClick={clear_filters} className="btn btn-custom-color btn-hover-shine me-3 ">
          {t("Clear All")}
        </button>

        {/* <DateFilter 
          handlechange = {event => handledatechange(event)}
          onFilter4={e => setFilterTextFtn(e.target.value, 'date')}
          filterText4={filterText4} 
          onClear={handleClear} 
        /> */}
      </>
    );
  }, [filterText1, filterText2, filterText3, filterText4, resetPaginationToggle]);


  function setFilterTextFtn(itemrec, param) {
    console.log('item:-', itemrec)
    if (param === 'collection') {
      setFilterText1(itemrec);
    }
    if (param === 'action') {
      setFilterText2(itemrec);
    }
    if (param === 'function') {
      setFilterText3(itemrec);
    }
    if (param === 'date') {
      setFilterText4(itemrec);
      // const filterItems = logslist.filter(
      //   item => item.datetime_added.includes(itemrec),
      // );
      // setFilteredItems(filterItems);
    }
  };

  function clear_filters() {
    $("#colfilter").val('');
    setFilterText1('');
    // $("#modfilter").val('');
    // setFilterText2('');
    $("#actfilter").val('');
    setFilterText2('');
    $("#funcfilter").val('');
    setFilterText3('');
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }

  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function search_logs() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  };

  function handledatechange(event) {
    var page = 0
    setFilterText4($("#datefilter").val());
    handlePageChange(page++)
  };

  function handleClear(item) {
    console.log(item);
    if (item === 'collection') {
      $("#colfilter").val('');
      setFilterText1('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }

    // if (item === 'collection') {
    //   console.log("value:-", item)
    //   console.log("filter text:-", filterText1)
    //   if (filterText1) {

    //     var local = '';
    //     setFilterText1(local);
    //     console.log("After filter text:-", filterText1)
    //   }

    // }
    // if (item === 'module') {
    //   console.log("value:-", item)
    //   setFilterText2('');
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   var page = 0
    //   handlePageChange(page++)
    // }
    // if (item === 'function') {
    //   console.log("value:-", item)
    //   setFilterText3('');
    //   setResetPaginationToggle(!resetPaginationToggle);
    //   var page = 0
    //   handlePageChange(page++)
    // }
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-display2 icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {t('Activity Logs')}
                        <div className="page-title-subheading">
                          {t('View all Activity Logs. Click on a log to view its details.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t('Back')}
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Col md="12" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>

                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment >
  );
}

export default ActivityLogs;