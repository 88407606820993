import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { Card, CardBody } from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import ItemService from "./Item.service";
import AuthService from "../Authentication/auth.service";
import { useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';


const DriverUpdationForm = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { state } = location;

  const [loading, setLoading] = useState(false);
  const form = useRef();
  const [pre_params, setpre_params] = useState('')
  const [collectionlist, setcollectionlist] = useState([])
  const [fieldlist, setfieldlist] = useState([])
  const [fieldlist_validate, setfieldlist_validate] = useState([])
  const [fieldvalues, setfieldvalues] = useState({})
  const [item_name, setItem_name] = useState(state?.item_name)
  const [itemType, setItemType] = useState(state?.item_type);
  const [price, setPrice] = useState(state?.price);
  const [description, setDescription] = useState(state?.description);

  useEffect(() => {

    i18n.changeLanguage(localStorage.getItem('language'));
    fetchDynamicData();
    console.log(state)
  }, []);

  const fetchDynamicData = async () => {
    try {
      const response = await ItemService.get_cruds_dynamic_data();
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        const data = await response.data;
        console.log(data);
        var custom_fields = data;
        for (var i = 0; i < custom_fields.length; i++) {
          if (custom_fields[i].options_collection !== '') {

            const optresponse = await ItemService.getoptions(custom_fields[i].options_collection, "", {}, 5000);
            const optdata = await optresponse.data.data;
            var options = [];

            for (var j = 0; j < optdata.length; j++) {
              options.push({ label: optdata[j][custom_fields[i].Options_Field_Name], value: optdata[j]._id, })
            }
            custom_fields[i].options = options;
          }
          if (custom_fields[i].parent_col === pre_params.Parent_referal_name) {
            custom_fields[i].default_value = pre_params.Parentid
          } else {
            custom_fields[i].default_value = ""
          }
        }

        data[0].custom_fields = custom_fields;

        setcollectionlist(data[0]);
        setfieldlist(data[0].custom_fields);
        var cust = data[0].custom_fields;
        var validations = {};
        for (var i = 0; i < cust.length; i++) {
          validations[cust[i].referal_name] = cust[i].is_required;
        }
        setfieldlist_validate(validations);
      }
    } catch (error) {
      console.log("error", error);
    }
  };



  async function handleSubmit(e) {
    e.preventDefault();
    var field_errors = [];

    var formData = new FormData();
    for (var i in fieldlist) {
      var field = fieldlist[i];
      if (field.is_editable) {
        if (field.show_in === 'Items List' && field.status === 'Active') {
        if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Items') {

        if (field.field_type === 'radio') {

          const elements1 = Array.from(
            document.getElementsByClassName('prop' + field.referal_name)
          );
          var elementvalue = 0;
          elements1.forEach(element => {

            if (element.checked) {
              elementvalue = element.value
            }
          });
          formData.append(field.referal_name, elementvalue)
          formData[field.referal_name] = elementvalue
        } else if (field.field_type === 'checkbox') {
          const elements1 = Array.from(
            document.getElementsByClassName('prop' + field.referal_name)
          );
          var elementvalue = [];
          elements1.forEach(element => {

            if (element.checked) {
              elementvalue.push(element.value)
            }
          });
          var fieldelevalue = 0;
          if (elementvalue.length == 0) {
            formData.append(field.referal_name, 0)
            formData[field.referal_name] = 0;
          } else if (elementvalue.length == 1) {
            formData.append(field.referal_name, elementvalue[0])
            formData[field.referal_name] = elementvalue[0];
          } else {
            formData.append(field.referal_name, elementvalue)
            formData[field.referal_name] = elementvalue;
          }

        }
        else {

          var fieldvalue = document.getElementById(field.referal_name).value;
          if (field.field_type === 'file') {
            formData.append(field.referal_name, $('#' + field.referal_name).prop('files')[0]);
            formData[field.referal_name] = $('#' + field.referal_name).prop('files')[0];
          } else {
            formData.append(field.referal_name, fieldvalue)
            formData[field.referal_name] = fieldvalue;
          }

          var fieldvalue = document.getElementById(field.referal_name).value;
          if (field.is_required && field.field_type !== 'file') {

            if (fieldvalue === '') {
              field_errors.push({ field: field.referal_name, status: false, message: field.name + " is required" });
              document.getElementById(field.referal_name).style.border = "2px solid red"
              document.getElementById(field.referal_name + "error-box").innerHTML = " is required."
            } else {

              document.getElementById(field.referal_name).removeAttribute("style");
              document.getElementById(field.referal_name + "error-box").innerHTML = "";
            }
          }
          // if(field.is_unique && fieldvalue !==''  && field.field_type !== 'file'){

          //   var filter = {}
          //   filter[field.referal_name] = fieldvalue;

          //   const response = await ItemService.getcollection(collectionlist.collection_name,filter,1,0);
          //   const data = await response.data;
          //   if(data.count){
          //     if(data.count.count > 0 ){
          //       field_errors.push({field:field.referal_name,status:false,message:field.name+" already exists"});
          //       document.getElementById(field.referal_name).style.border ="2px solid red"
          //       document.getElementById(field.referal_name+"error-box").innerHTML =" already exists."
          //     } else{
          //       document.getElementById(field.referal_name).removeAttribute("style");
          //       document.getElementById(field.referal_name+"error-box").innerHTML ="";
          //     }
          //   } else{
          //     document.getElementById(field.referal_name).removeAttribute("style");
          //     document.getElementById(field.referal_name+"error-box").innerHTML ="";
          //   } 
          // }


        }
        }
        }
      }

    }


    formData.append("_id", state._id)
    formData.append("functionName", "UpdateTypeItems")
    formData.append("formId", collectionlist._id)
    formData.append("user_type_id", collectionlist._id)
    formData.append("user_type", collectionlist.name)
    formData.append("collection_name", collectionlist.collection_name);

    formData['_id'] = state._id;
    formData["functionName"] = "UpdateTypeItems";
    formData["formId"] = collectionlist._id;
    formData["user_type_id"] = collectionlist._id;
    formData["user_type"] = collectionlist.name;
    formData["collection_name"] = collectionlist.collection_name;
    formData.append("item_name", item_name);
    formData.append("description", description)
    formData.append("price", price)
    formData.append("item_type", itemType)

    if (field_errors.length === 0) {

      setLoading(true);

      ItemService.save_item(formData).then((response) => {

        setLoading(false);
        if (response.data.status) {
          navigate("/items-list")
          if (response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message)
          }
          else {
            Alerts.swalSuccessAlert(t("Data Updated successfully."));
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message);
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't add data at the moment. Please try again later."))
          }
        }
      },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });



    }



  };

  const handle_change = (e) => {
    var value = e.target.value;
    var referal_name = e.target.id;

    var custom_fields = fieldlist;
    var datafield_values = {};
    for (var i = 0; i < custom_fields.length; i++) {

      if (custom_fields[i].referal_name === referal_name) {
        custom_fields[i].fieldvalue = value
        datafield_values[custom_fields[i].referal_name] = value
      } else {
        datafield_values[custom_fields[i].referal_name] = custom_fields[i].fieldvalue;
      }

    }
    setfieldvalues(datafield_values);
  }

  const handle_optionchange = (e) => {

    var element = e.target
    var value = element.value;
    var referal_name = element.name;
    var type = element.type;
    var custom_fields = fieldlist;
    var datafield_values = {};
    for (var i = 0; i < custom_fields.length; i++) {

      if (custom_fields[i].referal_name === referal_name) {

        if (type === "radio") {
          custom_fields[i].fieldvalue = value
          datafield_values[custom_fields[i].referal_name] = value
        }

        if (type === "checkbox") {
          var fieldvals = custom_fields[i].fieldvalue;
          if (typeof fieldvals === "string") {
            var cval = fieldvals;
            fieldvals = [];
            fieldvals = cval.split(",");
          }


          if (typeof fieldvals === "object") {

            if (!element.checked) {
              var index = fieldvals.indexOf(value);
              if (index !== -1) {

                fieldvals.splice(index, 1);
              }
            } else {

              fieldvals.push(value);


            }
          }
          custom_fields[i].fieldvalue = fieldvals
          datafield_values[custom_fields[i].referal_name] = fieldvals
        }



      } else {
        datafield_values[custom_fields[i].referal_name] = custom_fields[i].fieldvalue;
      }

    }
    setfieldvalues(datafield_values);



  }
  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>

                <div>{t("Update Membership Type")}
                  <div className="page-title-subheading">
                    {t('Fill the form below to update Membership Type')}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Form onSubmit={handleSubmit} initial={state} ref={form}>
                      <div className="row" key={'name'}>
                        <div className="col-md-12 col-xl-12" >
                          <div className="form-group" >
                            <label htmlFor="name">{t("Item Name")} <span id={"name error-box"} style={{ color: "red" }}></span></label>
                            <Input type='text'
                              value={item_name}
                              onChange={(e) => { setItem_name(e.target.value) }}
                              className="form-control" placeholder={t("Item Name")} name="item_name" id="item_name" />
                          </div>
                        </div>
                      </div>
                      <br />
                      {/* <div className="row" key={'description'}>
                        <div className="col-md-12 col-xl-12" >
                          <div className="form-group" >
                            <label htmlFor="description">{t("Description")} <span id={"description error-box"} style={{ color: "red" }}></span></label>
                            <Input
                              value={description}
                              onChange={(e) => { setDescription(e.target.value) }}
                              type='text' className="form-control" placeholder={t("Description")} name="description" id="description" />
                          </div>
                        </div>
                      </div>
                      <br /> */}
                      <div className="row" key={'price'}>
                        <div className="col-md-12 col-xl-12" >
                          <div className="form-group" >
                            <label htmlFor="price">{t("Price")} <span id={"price error-box"} style={{ color: "red" }}></span></label>
                            <Input value={price}
                              onChange={(e) => { setPrice(e.target.value) }}
                              type='number' className="form-control" placeholder={t("Price")} name="price" id="price" />
                          </div>
                        </div>
                      </div>
                      {/* <br />
                      <div className="row" key={'item_type'}>
                        <div className="col-md-12 col-xl-12" >
                          <div className="form-group" >
                            <label htmlFor="name">Item Type <span id={"item_type error-box"} style={{ color: "red" }}></span></label>
                            <select
                              value={itemType}
                              onChange={(e) => { setItemType(e.target.value) }}
                              className="form-select" name='item_type' id='item_type'    >
                              <option disabled={false} value="">{t('--Choose an option--')}</option>
                              <option key='Service' value='Service'>{t("Service")}</option>
                              <option key='Goods' value='Goods'>{t("Goods")}</option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                      <br />
                      {fieldlist && fieldlist.map((field, index) => {
                        if (field.is_editable && field.status === 'Active' && field.show_in === 'Items List') {
                          if (field.item_in_invoice === 'Both' || field.item_in_invoice === 'Items') {

                            if (field.field_type === 'text' || field.field_type === 'email' || field.field_type === 'number' || field.field_type === 'password' || field.field_type === 'file') {
                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" >
                                      <div className="form-group " >
                                        <label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label>
                                        <Input type={field.field_type} className="form-control"
                                          placeholder={field.field_lable}
                                          name={field.referal_name} id={field.referal_name}
                                          value={field.field_type !== 'file' ? state[field.referal_name] : ''} />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                            else if (field.field_type === 'dropdown') {

                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" >
                                      <div className="form-group" >
                                        <label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label>
                                        <select className="form-select" name={field.referal_name} key={field.referal_name} index={index} id={field.referal_name} value={fieldvalues[field.referal_name]} onChange={handle_change}    >
                                          <option disabled={false} value="">{t('--Choose an option--')}</option>
                                          {field.options.map((option, optionsindex) => (
                                            <option key={field.referal_name + optionsindex} value={option.value} >{option.label}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                            else if (field.field_type === 'radio' || field.field_type === 'checkbox') {
                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" ><label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label></div>
                                    <br />
                                    <div className="col-md-12 col-xl-12" >
                                      <ul className="child mb-0 p-0">
                                        {field.options.map((option, optionsindex) => {
                                          var checked = "";
                                          var btnvalue = fieldvalues[field.referal_name];

                                          if (typeof btnvalue === "string") {
                                            if (option.value === btnvalue) {
                                              checked = "true"
                                            } else if (btnvalue.indexOf(option.value) > -1) {
                                              checked = "true"
                                            }
                                          }

                                          if (typeof btnvalue === "object") {
                                            if (btnvalue.indexOf(option.value) > -1) {
                                              checked = "true"
                                            }
                                          }
                                          return (
                                            <li className="form-check form-check-inline">
                                              <input className={"form-check-input prop" + field.referal_name}
                                                type={field.field_type}
                                                value={option.value} checked={checked} onClick={handle_optionchange} name={field.referal_name} id={field.referal_name + optionsindex}
                                              />
                                              <label className="form-check-label" htmlFor="users">
                                                {option.label}
                                              </label>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                            else {
                              return (
                                <>
                                  <div className="row" key={index}>
                                    <div className="col-md-12 col-xl-12" >
                                      <div className="form-group" >
                                        <label htmlFor="name">{field.field_lable} <span id={field.referal_name + "error-box"} style={{ color: "red" }}></span></label>
                                        <Input type="text" className="form-control" placeholder={field.field_lable} name={field.referal_name} id={field.referal_name} />
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )
                            }
                          }
                        }
                      })}
                      <br />
                      <div className="row">
                        <div className="col-md-12 col-xl-12" >
                          <center>
                            <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                            <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Update data')}</button>
                          </center>
                        </div>
                      </div>
                    </Form>
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}

export default DriverUpdationForm;