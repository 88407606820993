import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import cx from "classnames";
import ResizeDetector from "react-resize-detector";
import { useTranslation } from 'react-i18next';

import ConfigurationService from "./pages/Configuration/configuration.service";
import AuthService from "./pages/Authentication/auth.service";

import Leftbar from "./components/Left Bar/Leftbar";
import Topbar from "./components/Top Bar/Topbar";

import Login from "./pages/Authentication/Login";
import ResetPassword from "./pages/Authentication/ResetPassword";
import ForgotPassword from "./pages/Authentication/ForgotPassword";

import UserLogin from "./pages/Authentication_usertypes/Login";
import UserResetPassword from "./pages/Authentication_usertypes/ResetPassword";
import UserForgotPassword from "./pages/Authentication_usertypes/ForgotPassword";

import Dashboard from "./pages/Dashboard/Dashboard";
import CustomerDashboard from "./pages/Dashboard/CustomerDashboard";
import MyProfile from "./pages/Profile/MyProfile";



import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import AddEmailTemplate from "./pages/EmailTemplates/AddEmailTemplate";
import UpdateEmailTemplate from "./pages/EmailTemplates/UpdateEmailTemplate";
import ViewEmailTemplate from "./pages/EmailTemplates/ViewEmailTemplate";

import Configuration from "./pages/Configuration/Configuration";

import ActivityLogs from "./pages/Activity Logs/ActivityLogs";
import ViewActivityLog from "./pages/Activity Logs/ViewActivityLog";

import EntitiesList from "./pages/Entities/EntitiesList";
import AddEntity from "./pages/Entities/AddEntity";
import UpdateEntity from "./pages/Entities/UpdateEntity";

import AttributesList from "./pages/Attributes/AttributesList";
import AddAttribute from "./pages/Attributes/AddAttribute";
import UpdateAttribute from "./pages/Attributes/UpdateAttribute";

import AddField from "./pages/Finance/Settings/AddField.js";
import UpdateField from "./pages/Finance/Settings/UpdateField";


import RolesList from "./pages/Roles/RolesList";
import AddRole from "./pages/Roles/AddRole";
import UpdateRole from "./pages/Roles/UpdateRole";
import AssignRole from "./pages/Roles/AssignRole";

import UsersList from "./pages/Users Management/UsersList";
import AddUser from "./pages/Users Management/AddUser";
import UpdateUser from "./pages/Users Management/UpdateUser";


import ItemList from "./pages/Items/ItemsList";
import AddItem from "./pages/Items/ItemInsertionForm";
import UpdateItem from "./pages/Items/ItemUpdationForm";
import ItemSettings from "./pages/Items/Settings/Settings";



import CustomersList from "./pages/Members/Customers/CustomersList";
import CustomerInsertionForm from "./pages/Members/Customers/CustomerInsertionForm";
import CustomerUpdationForm from "./pages/Members/Customers/CustomerUpdationForm";
import CustomerDetails from "./pages/Members/Customers/CustomerDetails";

import AddMembershipUser from "./pages/Members/Customers/AddMembershipUser.js";
import UpdateMembershipUser from "./pages/Members/Customers/UpdateMembershipUser";


import MembersList from "./pages/Members/ManageMembers/CustomersList";
import MembersInsertionForm from "./pages/Members/ManageMembers/CustomerInsertionForm";
import MembersUpdationForm from "./pages/Members/ManageMembers/CustomerUpdationForm";
import MembersDetails from "./pages/Members/ManageMembers/CustomerDetails";


import CorporateCustomersList from "./pages/Members/CorporateCustomers/CustomersList";
import CorporateCustomerInsertionForm from "./pages/Members/CorporateCustomers/CustomerInsertionForm";
import CorporateCustomerUpdationForm from "./pages/Members/CorporateCustomers/CustomerUpdationForm";
import CorporateCustomerDetails from "./pages/Members/CorporateCustomers/CustomerDetails";



import UserTypesView from "./pages/UserTypes/DataTableView";
import UserTypesInsertionForm from "./pages/UserTypes/InsertionForm";
import UserTypesUpdationFrom from "./pages/UserTypes/UpdationFrom";

import DataTableView from "./pages/Dynamic Modules/DataTableView";
import InsertionForm from "./pages/Dynamic Modules/InsertionForm";
import UpdationFrom from "./pages/Dynamic Modules/UpdationFrom";

import PageNotFound from "./pages/Page Not Found/PageNotFound";
import RestrictedAccess from "./pages/Restricted Access/RestrictedAccess";

import UserFormList from "./pages/DynamicUserForms/UserFormList";
import AddUserForm from "./pages/DynamicUserForms/AddUserForm";
import UpdateUserForm from "./pages/DynamicUserForms/UpdateUserForm";

import DynamicSignup from "./pages/DynamicUserForms/DynamicSignup";

import UserFormAttributesList from "./pages/UserForm_Attributes/AttributesList";
import UserFormAddAttribute from "./pages/UserForm_Attributes/AddAttribute";
import UserFormUpdateAttribute from "./pages/UserForm_Attributes/UpdateAttribute";

import PaymentsDetails from "./pages/Finance/Payments/PaymentsDetails";
import CustomerInvoices from "./pages/Finance/Invoices/CustomerInvoices";
import CustomerPayments from "./pages/Finance/Payments/CustomerPayments";
import PaymentRedirectPage from "./pages/Finance/Payments/paymentRedirectPage";


import RecurringInvoices from "./pages/Finance/RecurringInvoices/RecurringInvoices";
import AddRecurringInvoice from "./pages/Finance/RecurringInvoices/AddRecurringInvoice";
import UpdateRecurringInvoice from "./pages/Finance/RecurringInvoices/UpdateRecurringInvoice";

import InvoicesList from "./pages/Finance/Invoices/InvoicesList";
import StatementsList from "./pages/Finance/Statements/StatementsList";
import PaymentsList from "./pages/Finance/Payments/PaymentsList";
import AddInvoice from "./pages/Finance/Invoices/AddInvoice";
import UpdateInvoice from "./pages/Finance/Invoices/UpdateInvoice";
import FinanceSettings from "./pages/Finance/Settings/Settings";

import AddPayments from "./pages/Finance/Invoices/payments/AddPayments";
import UpdatePayments from "./pages/Finance/Invoices/payments/UpdatePayments";


import AddTaxClasses from "./pages/Finance/Settings/AddTaxClasses";
import UpdateTaxClasses from "./pages/Finance/Settings/UpdateTaxClasses";
import AddReminders from "./pages/Finance/Settings/AddReminders";
import UpdateReminders from "./pages/Finance/Settings/UpdateReminders";
import AddPaymentMethod from "./pages/Finance/Settings/AddPaymentMethod";
import UpdatePaymentMethod from "./pages/Finance/Settings/UpdatePaymentMethod";


const App = () => {

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = window.location.pathname
  const accessPages = localStorage.getItem('access_pages')?.split(',') ?? [];
  console.log(accessPages)
  console.log(accessPages.length);
  const [storeduser, setStoredUser] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    getColors();
    // getSetLanguage();
  }, [storeduser]);

  function getColors() {
    const buttonsColor = localStorage.getItem("buttons_color");
    const topbarColor = localStorage.getItem("topbar_color");
    const leftbarColor = localStorage.getItem("leftbar_color");
    const buttonsTextColor = localStorage.getItem("buttons_text_color");
    setColors(buttonsColor, topbarColor, leftbarColor, buttonsTextColor);
  }

  function setColors(buttons_color, topbar_color, leftbar_color, buttons_text_color) {
    document.documentElement.style.setProperty('--buttons-color', buttons_color);
    document.documentElement.style.setProperty('--topbar-color', topbar_color);
    document.documentElement.style.setProperty('--leftbar-color', leftbar_color);
    document.documentElement.style.setProperty('--buttons-text-color', buttons_text_color);
  }

  function getSetLanguage() {
    if (localStorage.getItem("language")) {
      const language = localStorage.getItem("language");
      i18n.changeLanguage(language);
    }
    else {
      localStorage.setItem('language', 'en');
      i18n.changeLanguage('en');
    }
  }

  localStorage.setItem("user_type", 'admin');
  const userType = localStorage.getItem("user_type");
  const register = localStorage.getItem("register");
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const reset = localStorage.getItem("reset");
  const resetpass = localStorage.getItem("resetpass");
  const regprod = localStorage.getItem("regprod");
  // if (!currentUser && !reset && !resetpass && !regprod && userType === 'admin') {
  //   console.log("I am at checkpoint 1")
  //   return <Login />
  // }

  // if(reset && userType === 'admin') {
  //   return <ForgotPassword />
  // }

  // if(resetpass && userType === 'admin') {
  //   return <ResetPassword />
  // }

  // if(!storeduser){
  //   console.log("Login Page inbound")
  //   return <Login />
  // }

  if (location.pathname === '/login') {
    console.log("Login Page inbound")
    return <Login />
  }

  if (location.pathname === '/RedirectPage') {
    console.log("Login Page inbound")
    return <PaymentRedirectPage />
  }

  if (location.pathname === '/forgot-password') {
    console.log("Forgot Page inbound")
    return <ForgotPassword />
  }

  if (location.pathname === '/auth-password') {
    console.log("Reset Pass Page inbound")
    return <ResetPassword />
  }

  if (pathname.includes("userLogin")) {
    //if (location.pathname === '/UserLogin/:id') {
    console.log("Login Page inbound")
    return <UserLogin />
  }


  if (location.pathname === '/Userforgot-password') {
    console.log("Forgot Page inbound")
    return <UserForgotPassword />
  }

  if (location.pathname === '/Userauth-password') {
    console.log("Reset Pass Page inbound")
    return <UserResetPassword />
  }


  if (location.pathname === '/404') {
    console.log("PageNotFound inbound")
    return <PageNotFound />
  }

  if (location.pathname === '/restricted-access') {
    console.log("RestrictedAccess page inbound")
    return <RestrictedAccess />
  }

  else {
    return (
      <ResizeDetector handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-white",
                { "fixed-header": true },
                { "fixed-sidebar": true || width < 1500 },
                { "fixed-footer": true },
                { "closed-sidebar": true || width < 1500 },
                {
                  "closed-sidebar-mobile": true || width < 1500,
                },
                { "sidebar-mobile-open": false },
                { "body-tabs-shadow-btn": true }
              )}>
              {pathname.search('/register') < 0 && <Topbar />}
              <div className="app-main">

                {pathname.search('/register') < 0 && <Leftbar />}
                <Routes>
                  <Route exact path={"/"} element={<Dashboard />} />
                  {/* <Route exact path="/login" element={<Login />} />
                      <Route exact path="/auth-password" element={<ResetPassword />} />
                      <Route exact path="/forgot-password" element={<ForgotPassword />} /> */}

                  <Route exact path={"/home"} element={<Dashboard />} />
                  <Route exact path="/my-profile" element={<MyProfile />} />



                  <Route exact path="/email-templates" element={accessPages.length === 1 ? <EmailTemplates /> :
                    accessPages.includes('email-templates') ? <EmailTemplates />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-email-template" element={accessPages.length === 1 ? <AddEmailTemplate /> :
                    accessPages.includes('add-email-template') ? <AddEmailTemplate />
                      : <Navigate to="/404" />
                  } />
                  <Route path="/update-email-template" element={<UpdateEmailTemplate />} />
                  <Route path="/view-email-template" element={<ViewEmailTemplate />} />

                  <Route exact path="/configuration" element={accessPages.length === 1 ? <Configuration /> :
                    accessPages.includes('configuration') ? <Configuration />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/activity-logs" element={accessPages.length === 1 ? <ActivityLogs /> :
                    accessPages.includes('activity-logs') ? <ActivityLogs />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/view-activity-log" element={<ViewActivityLog />} />

                  <Route exact path="/entities-list" element={accessPages.length === 1 ? <EntitiesList /> :
                    accessPages.includes('entities-list') ? <EntitiesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-entity" element={accessPages.length === 1 ? <AddEntity /> :
                    accessPages.includes('add-entity') ? <AddEntity />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-entity" element={accessPages.length === 1 ? <UpdateEntity /> :
                    accessPages.includes('update-entity') ? <UpdateEntity />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/attributes-list" element={accessPages.length === 1 ? <AttributesList /> :
                    accessPages.includes('attributes-list') ? <AttributesList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-attribute" element={accessPages.length === 1 ? <AddAttribute /> :
                    accessPages.includes('add-attribute') ? <AddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-attribute" element={accessPages.length === 1 ? <UpdateAttribute /> :
                    accessPages.includes('update-attribute') ? <UpdateAttribute />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add_field" element={accessPages.length === 1 ? <AddField /> :
                    accessPages.includes('add_field') ? <AddField />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update_field" element={accessPages.length === 1 ? <UpdateField /> :
                    accessPages.includes('update_field') ? <UpdateField />
                      : <Navigate to="/404" />
                  } />


                  <Route exact path="/roles-list" element={accessPages.length === 1 ? <RolesList /> :
                    accessPages.includes('roles-list') ? <RolesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-role" element={accessPages.length === 1 ? <AddRole /> :
                    accessPages.includes('add-role') ? <AddRole />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-role" element={<UpdateRole />} />
                  <Route exact path="/assign-role" element={<AssignRole />} />

                  <Route exact path="/users-list" element={accessPages.length === 1 ? <UsersList /> :
                    accessPages.includes('users-list') ? <UsersList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-user" element={<AddUser />} />
                  <Route exact path="/update-user" element={<UpdateUser />} />

                  <Route exact path="/add_membership_user" element={<AddMembershipUser />} />
                  <Route exact path="/update_membership_user" element={<UpdateMembershipUser />} />


                  <Route exact path="/add_Tax_Classes" element={ <AddTaxClasses />} />
                  <Route exact path="/update_Tax_Classes" element={<UpdateTaxClasses />} />
                  <Route exact path="/add_payment_method" element={<AddPaymentMethod />} />
                  <Route exact path="/update_payment_method" element={<UpdatePaymentMethod />} />
                  <Route exact path="/Add_Reminders" element={<AddReminders />} />
                  <Route exact path="/Update_Reminders" element={<UpdateReminders />} />



                  <Route exact path="/customers-list" element={accessPages.length === 1 ? <CustomersList /> :
                    accessPages.includes('customers-list') ? <CustomersList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-customer" element={<CustomerInsertionForm />} />
                  <Route exact path="/update-customer" element={<CustomerUpdationForm />} />
                  <Route exact path="/customer-details" element={<CustomerDetails />} />


                  <Route exact path="/Members-list" element={accessPages.length === 1 ? <MembersList /> :
                    accessPages.includes('Members-list') ? <MembersList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-Members" element={<MembersInsertionForm />} />
                  <Route exact path="/update-Members" element={<MembersUpdationForm />} />
                  <Route exact path="/Members-details" element={<MembersDetails />} />




                  <Route exact path="/corporate-customers-list" element={accessPages.length === 1 ? <CorporateCustomersList /> :
                    accessPages.includes('corporate-customers-list') ? <CorporateCustomersList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/corporate-add-customer" element={<CorporateCustomerInsertionForm />} />
                  <Route exact path="/corporate-update-customer" element={<CorporateCustomerUpdationForm />} />
                  <Route exact path="/corporate-customer-details" element={<CorporateCustomerDetails />} />





                  <Route path="/viewdata/:id" element={storeduser ? <DataTableView /> : <Login />} />
                  <Route path="/adddata/:id" element={storeduser ? <InsertionForm /> : <Login />} />
                  <Route path="/editdata/:id" element={storeduser ? <UpdationFrom /> : <Login />} />

                  <Route exact path="/userform-attributes-list" element={accessPages.length === 1 ? <UserFormAttributesList /> :
                    accessPages.includes('userform-attributes-list') ? <UserFormAttributesList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform-attribute" element={accessPages.length === 1 ? <UserFormAddAttribute /> :
                    accessPages.includes('add-userform-attribute') ? <UserFormAddAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform-attribute" element={accessPages.length === 1 ? <UserFormUpdateAttribute /> :
                    accessPages.includes('update-userform-attribute') ? <UserFormUpdateAttribute />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/userform-list" element={accessPages.length === 1 ? <UserFormList /> :
                    accessPages.includes('userform-list') ? <UserFormList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-userform" element={accessPages.length === 1 ? <AddUserForm /> :
                    accessPages.includes('add-userform') ? <AddUserForm />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/update-userform" element={accessPages.length === 1 ? <UpdateUserForm /> :
                    accessPages.includes('update-userform') ? <UpdateUserForm />
                      : <Navigate to="/404" />
                  } />


                  <Route path="/usertypes_view/:id" element={<UserTypesView />} />
                  <Route path="/usertypes_add/:id" element={<UserTypesInsertionForm />} />
                  <Route path="/usertypes_edit/:id" element={<UserTypesUpdationFrom />} />


                  <Route exact path="/invoices-list" element={accessPages.length === 1 ? <InvoicesList /> :
                    accessPages.includes('invoices-list') ? <InvoicesList />
                      : <Navigate to="/404" />
                  } />


                  <Route exact path="/recurring-invoices" element={accessPages.length === 1 ? <RecurringInvoices /> :
                    accessPages.includes('invoices-list') ? <RecurringInvoices />
                      : <Navigate to="/404" />
                  } />

                  <Route path="/CustomerInvoices" element={<CustomerInvoices />} />
                  <Route path="/CustomerPayments" element={<CustomerPayments />} />
                  <Route exact path={"/CustomerHome"} element={<CustomerDashboard />} />
                  <Route exact path="/payments-details" element={<PaymentsDetails />} />


                  <Route exact path="/payments-list" element={accessPages.length === 1 ? <PaymentsList /> :
                    accessPages.includes('payments-list') ? <PaymentsList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/Finance-Settings" element={accessPages.length === 1 ? <FinanceSettings /> :
                    accessPages.includes('Finance-Settings') ? <FinanceSettings />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/item-Settings" element={accessPages.length === 1 ? <ItemSettings /> :
                    accessPages.includes('item-Settings') ? <ItemSettings />
                      : <Navigate to="/404" />
                  } />



                  <Route exact path="/statements-list" element={accessPages.length === 1 ? <StatementsList /> :
                    accessPages.includes('statements-list') ? <StatementsList />
                      : <Navigate to="/404" />
                  } />

                  <Route exact path="/add-invoice" element={<AddInvoice />} />
                  <Route exact path="/update-invoice" element={<UpdateInvoice />} />
                  <Route exact path="/add-recurring-invoice" element={<AddRecurringInvoice />} />
                  <Route exact path="/update-recurring-invoice" element={<UpdateRecurringInvoice />} />

                  <Route exact path="/add-payment" element={<AddPayments />} />
                  <Route exact path="/update-payment" element={<UpdatePayments />} />

                  <Route path="/register/:id" element={<DynamicSignup />} />

                  <Route exact path="/items-list" element={accessPages.length === 1 ? <ItemList /> :
                    accessPages.includes('items-list') ? <ItemList />
                      : <Navigate to="/404" />
                  } />
                  <Route exact path="/add-Items" element={<AddItem />} />
                  <Route exact path="/update-Items" element={<UpdateItem />} />


                  {/* <Route path="*" element={<Navigate to="/404" />} /> */}

                </Routes>
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }







};

export default App;

// <Route exact path="/users" element={<UsersList />} />
// <Route path="/users/:id" element={<Users />} />
// <Route path="/datatable" element={<DataTableTest />} />
