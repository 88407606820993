import axios from "axios";

const API_URL  = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const headers = { 
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData":{
        "collection_name": "dynamic_modules",
        "filter":filter
    }
  }
  return axios.post(API_URL, payload, {headers});
};

const fetchcollections=()=>{
  
  var payload = {
    functionName: "FetchCollections",
    "postData":{}
  }
  return axios.post(API_URL, payload, {headers});
}

const deleteEntity = (id,referal_name) => {
  var payload = {
    functionName: "DeleteCrud",
    postData: {
      "_id": id,
      "referal_name":referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, {headers});
};


const addEntity = payload => {
  console.log("Received addEntity Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}


const UpdateEntity = payload => {
  console.log("Received addEntity Payload:-", payload)
  return axios.post(API_URL, payload, {headers});
}

const fetchFunctionNames = () => {
  var payload = {
    functionName: "FetchFunctionNames",
    "postData":{}
  }
  return axios.post(API_URL, payload, {headers});
}

const EntitiesService =  {
  getAll,
  deleteEntity,
  addEntity,
  UpdateEntity,
  fetchcollections,
  fetchFunctionNames
}

export default EntitiesService;
