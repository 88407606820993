import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};


const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};
const bulkdeleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "BulkDeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const getcollection = (referal_name, filter, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, { headers });
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyusertypes';
  return axios.post(API_URL2, payload, { headers });
};


const AddInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};
const addPayments = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};
const updatePayment = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};
const UpdateInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const getAllPaymentMethods = () => {
  var payload = {
    functionName: "getAllPaymentMethods",
  }
  return axios.post(B_URL + "payments", payload, { headers });
};

const OnlinePayment = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};



const CustomerService = {
  addPayments,
  getAllPaymentMethods,
  updatePayment,
  OnlinePayment,
  AddInvoice,
  UpdateInvoice,
  import_dyn_records,
  getuserform,
  deleteCollection,
  bulkdeleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
  getoptions,
  save_user
};

export default CustomerService;
