import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment'
// Examples
import DateRangePickerComponent from "./DatePicker";
import CountUp from "react-countup";
import Chart from "react-apexcharts";

import { Pie } from "react-chartjs-2";
import {
  Row, Col, Card, CardHeader, CardBody, CardTitle, Progress, ListGroup,
  ListGroupItem
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  LineChart,
  Line,
} from "recharts";

import Alerts from "../../components/Alerts/alerts";
import DataTable from 'react-data-table-component';
import DashboardService from "./dashboard.service";
import AuthService from "../Authentication/auth.service";
import Tooltips from '@mui/material/Tooltip';





const Home = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [StartDate, setStartDate] = useState(Date.now());
  const [EndDate, setEndDate] = useState(Date.now());

  const [orders, setOrders] = useState([]);
  const [Geographical_Distribution, setGeographical_Distribution] = useState([]);
  const [Membership_Distribution, setMembership_Distribution] = useState([]);
  const [DashboardData, setDashboardData] = useState([]);
  const [Membership_Trends, setMembership_Trends] = useState([]);
  const [Expired_Members, setExpired_Members] = useState([]);
  const [visible, setVisible] = useState(true);
  const [popoverOpen1, setPopoverOpen1] = useState(false);
  const [total_members, setTotal_members] = useState([0]);
  const [active_members, setActive_members] = useState([0]);
  const [in_active_members, setIn_Active_members] = useState([0]);
  const [total_percentage, setTotal_percentage] = useState(0);
  const [active_percentage, setActive_percentage] = useState(0);
  const [inactive_percentage, setInactive_percentage] = useState(0);

  const [Calender, setCalender] = useState([]);
  const optionsRadial = {
    popoverOpen1: false,
    chart: {
      height: 350,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          showOn: 'always',
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter: function (val) {
              return val;
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Percentage'],
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchStates(0, pageLimit);
    // setViewFtn('allorders', "reload")
    // getPackaging();
    fetchCustomerOrders();
    fetchExpiredMembers();
    fetchMembershipTrends();
    // setOrderDetails([]);
  }, [pageLimit]);

  useEffect(() => {
    GetOrdersDetails();
    Get_Geographical_distribution_Data();
    Get_Member_Distribution_Data()
  }, []);



  let labels1 = '';
  let values1 = '';

  if (Membership_Distribution.length !== 0) {
    labels1 = Object.keys(Membership_Distribution && Membership_Distribution.data);
    values1 = Object.values(Membership_Distribution && Membership_Distribution.data);
  }

  const membership_distribution = {
    labels: labels1 ? labels1 : '',
    datasets: [
      {
        data: values1 ? values1 : '',
        backgroundColor: ["#8dace7", "#4BC0C0", "#ef869e", "#E7E9ED", "#71deb9"],
      },
    ],
  };


  let labels = '';
  let values = '';

  if (Geographical_Distribution.length !== 0) {
    labels = Object.keys(Geographical_Distribution && Geographical_Distribution.data);
    values = Object.values(Geographical_Distribution && Geographical_Distribution.data);
  }


  const geographical_distribution = {
    labels: labels ? labels : '',
    datasets: [
      {
        data: values ? values : '',
        backgroundColor: ["#285E2B", "#CC0001", "#4BC0C0"],
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: "#fff",
        backgroundColor: (context) => context.dataset.backgroundColor,
        borderRadius: 5,
        font: {
          weight: "bold",
        },
        formatter: (value, context) => `${value}%`,
      },
    },
  };




  function GetOrdersDetails(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetOrdersDetails",
      postData: {
        offset: offset,
        limit: page_limit,
        filter: {
          order_status: 'In Progress'
        }
      },
    }
    setLoading(true);
    DashboardService.GetOrdersDetails(getData).then((response) => {
      setLoading(false);
      console.log("Get Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response.data)
        setCalender(response.data.data);
        // setservice(response.data.data[0].services);
        // setpackaging(response.data.data[0].packaging);
        // setpackagingsize(response.data.data[0].packaging_size_details)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  function fetchCustomerOrders(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetDashboardCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "user_type": "all",
        "filter": {}
      },
    }
    setLoading(true);
    DashboardService.GetCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customer Orders response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setOrders(response.data.data);
        // setservice(response.data.data[0].services);
        // setpackaging(response.data.data[0].packaging);
        // setpackagingsize(response.data.data[0].packaging_size_details)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };



  function fetchStates(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetDashboardData",
      postData: {
        offset: offset,
        limit: page_limit
      },
    }
    setLoading(true);
    DashboardService.GetDashboardData(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setDashboardData(response.data)
        setTotal_members([response.data.data.all_members])
        setActive_members([response.data.data.active_members])
        setIn_Active_members([response.data.data.in_active_members])
        let total_percentage = 100;
        setTotal_percentage(total_percentage);
        setActive_percentage(Math.round((response?.data.data.active_members / response.data.data.all_members) * total_percentage));
        setInactive_percentage(Math.round((response?.data.data.in_active_members / response.data.data.all_members) * total_percentage));

      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function Get_Geographical_distribution_Data(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "Get_Geographical_distribution_Data",
      postData: {
        offset: offset,
        limit: page_limit,
        startDate: StartDate,
        endDate: EndDate,
      },
    }
    setLoading(true);
    DashboardService.Get_Geographical_distribution_Data(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setGeographical_Distribution(response.data)
        console.log(response.data)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function Get_Member_Distribution_Data(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "Get_Member_Distribution_Data",
      postData: {
        offset: offset,
        limit: page_limit,
        startDate: StartDate,
        endDate: EndDate,
      },
    }
    setLoading(true);
    DashboardService.Get_Member_Distribution_Data(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setMembership_Distribution(response.data)
        console.log(response.data)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };



  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
  };


  function fetchMembershipTrends(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "fetchMembershipTrends",
      postData: {
        offset: offset,
        limit: page_limit
      },
    }
    setLoading(true);
    DashboardService.fetchMembershipTrends(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setMembership_Trends(response.data)
        console.log(response.data)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchExpiredMembers(page_index, page_limit) {
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "fetchExpiredMembers",
      postData: {
        offset: offset,
        limit: page_limit
      },
    }
    setLoading(true);
    DashboardService.fetchExpiredMembers(getData).then((response) => {
      setLoading(false);
      console.log("Get Dash States response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setExpired_Members(response.data)
        console.log(response.data)
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    fetchStates(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  const mycolumns = [
    {
      name: "Membership Number",
      selector: row => row?.membership_number,
    },
    {
      name: "Member Type",
      width: '18%',
      selector: (row) => {
        const lowerCaseUserType = row.user_type.toLowerCase();

        let label = '';
        let color = '';

        if (lowerCaseUserType.includes('corporate')) {
          label = 'Corporate Member';
          color = 'blue';
        } else if (lowerCaseUserType.includes('honorary')) {
          label = 'Honorary Member';
          color = 'red';
        } else if (lowerCaseUserType.includes('government')) {
          label = 'Government Nominee';
          color = 'green';
        } else if (lowerCaseUserType.includes('elected')) {
          label = 'Exco Member (Elected)';
          color = 'orange';
        } else if (lowerCaseUserType.includes('ordinary_individual_professional')) {
          label = 'Ordinary - Individual| Professional';
          color = '#a103fc';
        } else if (lowerCaseUserType.includes('ordinary')) {
          label = 'Ordinary  Member';
          color = 'gray';

        } else if (lowerCaseUserType.includes('council')) {
          label = 'Chambers & Business Council Nominee';
          color = 'black';
        }

        return (
          <div style={{ color }}>
            {label}
          </div>
        );
      }
    },
    {
      name: "Name",
      selector: row => row?.company_name ? row.company_name : row?.name,
    },
    {
      name: "Phone Number",
      selector: row => row.phone_number,
    },
    {
      name: "Email",
      selector: row => row.email
    },
    {
      name: t('Status'),
      width: '11%',
      selector: (row) => {
        return (
          <div>
            {row.status === "Active" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">Active
              </button>
              :
              row.status === "InActive" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">InActive
                </button>
                :
                ""
            }
          </div>
        );
      }
    },
    // {
    //   name: t('Expiry Date'),
    //   selector: (row) => {
    //     return (
    //       moment(row.expiry_date).format('DD-MMM-YYYY')
    //     )
    //   }
    //   ,
    //   sortable: true,
    // },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltips placement="top" title="Member Details" arrow>
                <Link to="/customer-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltips >

              <Tooltips placement="top" title="Update" arrow>
                <i>
                  <Link to="/update-members" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltips >

              {/* <Tooltip placement="top" title="Delete" arrow>
                  <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip > */}
              {/* {row.customer_status === "Active" && */}
              {/* <Tooltip placement="top" title="Send Credentials" arrow>
                  <i onClick={() => regeneratePassword(row._id)} className="pe-7s-mail-open-file text-danger btn-icon-wrapper"> </i>
                </Tooltip > */}
              {/* } */}
              {/* <Tooltip placement="top" title="Regenrate Password" arrow>
                  <i onClick={() => regeneratePassword(row._id)} className="text-warning btn-sm px-0 btn-icon-wrapper">
                    <FontAwesomeIcon icon={["fas", "key"]} />
                  </i>
                </Tooltip> */}
            </button>
          </div>
        );
      }
    },
  ];
  const ExpiredColumns = [
    {
      name: "Membership Number",
      selector: row => row?.membership_number,
    },
    {
      name: "Member Type",
      selector: (row) => {
        return (
          <div style={{ color: row.user_type === 'ordinary' ? 'blue' : 'green' }}>
            {row.user_type.toLowerCase().toUpperCase()}
          </div>
        )
      }
    },
    {
      name: "Name",
      selector: row => row?.company_name ? row.company_name : row?.name,
    },
    {
      name: "Phone Number",
      selector: row => row.phone_number,
    },
    {
      name: "Email",
      selector: row => row.email
    },
    {
      name: t('Status'),
      width: '11%',
      selector: (row) => {
        return (
          <div>
            {row.status === "Active" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">Active
              </button>
              :
              row.status === "InActive" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">InActive
                </button>
                :
                ""
            }
          </div>
        );
      }
    },
    // {
    //   name: t('Expiry Date'),
    //   selector: (row) => {
    //     return (
    //       moment(row.expiry_date).format('DD-MMM-YYYY')
    //     )
    //   }
    //   ,
    //   sortable: true,
    // },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltips placement="top" title="Member Details" arrow>
                <Link to="/customer-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltips >

              <Tooltips placement="top" title="Update" arrow>
                <i>
                  <Link to="/update-members" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltips >

              {/* <Tooltip placement="top" title="Delete" arrow>
                  <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
                </Tooltip > */}
              {/* {row.customer_status === "Active" && */}
              {/* <Tooltip placement="top" title="Send Credentials" arrow>
                  <i onClick={() => regeneratePassword(row._id)} className="pe-7s-mail-open-file text-danger btn-icon-wrapper"> </i>
                </Tooltip > */}
              {/* } */}
              {/* <Tooltip placement="top" title="Regenrate Password" arrow>
                  <i onClick={() => regeneratePassword(row._id)} className="text-warning btn-sm px-0 btn-icon-wrapper">
                    <FontAwesomeIcon icon={["fas", "key"]} />
                  </i>
                </Tooltip> */}
            </button>
          </div>
        );
      }
    },
  ];
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t('Dashboard')}
                      </div>
                    </div>
                    <div className="page-title-actions">
                      {/* <a href="/add-email-template" className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a> */}
                    </div>
                  </div>
                </div>

                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab z-index-6">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          <i className="header-icon lnr-chart-bars icon-gradient bg-happy-green"> {" "} </i>
                          {t('Analytics')}
                        </div>
                      </CardHeader>
                      <Row style={{ paddingLeft: '14px', paddingRight: '14px', paddingTop: '14px' }}>
                        <Col md="6">
                          <CardTitle style={{ fontSize: "20px", fontWeight: '700' }} >Membership Distribution</CardTitle>
                          <div style={{ paddingLeft: '20%', paddingRight: '20%' }} className="main-card mb-3">
                            <CardBody >
                              <Pie data={membership_distribution} width={667} height={270} options={options} />
                            </CardBody>
                          </div>
                        </Col>
                        <Col md="6"  >
                          <CardTitle style={{ fontSize: "20px", fontWeight: '700' }} >Geographical Distribution</CardTitle>
                          <div style={{ paddingLeft: '20%', paddingRight: '20%' }} className="main-card mb-3">
                            <CardBody>
                              <Pie data={geographical_distribution} width={667} height={270} options={options} />
                            </CardBody>
                          </div>
                        </Col>
                      </Row>
                      {/* <Row style={{ paddingLeft: '14px', paddingRight: '14px', paddingTop: '14px' }}>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-premium-dark">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t('Total Members')}</div>
                                <div className="widget-subheading">{t("Total Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-warning">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_ordinary : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-arielle-smile">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Ordinary Members")}</div>
                                <div className="widget-subheading">
                                  {t("Total Ordinary Members")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_Complete_Orders : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-happy-green">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Corporate Members")}</div>
                                <div className="widget-subheading">{t("Total Corporate Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_Pending_Orders : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ paddingLeft: '14px', paddingRight: '14px', paddingTop: '14px' }}>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-premium-dark">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t('Government Nominee')}</div>
                                <div className="widget-subheading">{t("Government Nominee Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-warning">
                                  <CountUp end={DashboardData.data ? DashboardData.data.government : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-arielle-smile">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Chambers & Business Council Nominee")}</div>
                                <div className="widget-subheading">
                                  {t("Total Chambers & Business Council Nominee")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.council : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-happy-green">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Honorary Members")}</div>
                                <div className="widget-subheading">{t("Total Honorary Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.honorary : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-premium-dark">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Exco Member (Elected)")}</div>
                                <div className="widget-subheading">
                                  {t("Total Exco Member (Elected)")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-warning">
                                  <CountUp end={DashboardData.data ? DashboardData.data.elected : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-arielle-smile">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Ordinary - Individual| Professional")}</div>
                                <div className="widget-subheading">{t("Total Ordinary - Individual| Professional")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.ordinary_individual_professional : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row style={{ paddingLeft: '14px', paddingRight: '14px' }}>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-premium-dark">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t('Pakistani Members')}</div>
                                <div className="widget-subheading">{t("Total Pakistani Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-warning">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_Pakistani : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-arielle-smile">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Malaysian Members")}</div>
                                <div className="widget-subheading">
                                  {t("Total Malaysian Members")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_malaysian : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-happy-green">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Other Members")}</div>
                                <div className="widget-subheading">{t("Total Other Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.count_Others : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ paddingLeft: '14px', paddingRight: '14px' }}>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-premium-dark">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t('Total Invoices')}</div>
                                <div className="widget-subheading">{t("All Invoices For Members")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-warning">
                                  <CountUp end={DashboardData.data ? DashboardData.data.total_invoices : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-arielle-smile">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Paid Invoices")}</div>
                                <div className="widget-subheading">
                                  {t("Total Paid Invoices")}
                                </div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.paid_invoices : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col md="6" xl="4">
                          <div className="card mb-3 widget-content bg-happy-green">
                            <div className="widget-content-wrapper text-white">
                              <div className="widget-content-left">
                                <div className="widget-heading">{t("Unpaid Invoices")}</div>
                                <div className="widget-subheading">{t("Total unpaid Invoices")}</div>
                              </div>
                              <div className="widget-content-right">
                                <div className="widget-numbers text-white">
                                  <CountUp end={DashboardData.data ? DashboardData.data.unpaid_invoices : ''} duration="10" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row> */}
                    </Card>
                  </Col>
                </Row>


                {/* <Row>
                  <Col sm="12" md="5" lg="4">
                    <Card className="mb-3" style={{ cursor: 'pointer' }}>
                      <CardBody className="p-0">
                        <Chart options={optionsRadial} labels='Total Members'
                          id="optionsRadial"
                          style={{ transform: 'scale(1)', transition: 'transform 0.5s ease, background-color 0.3s ease' }}
                          onMouseEnter={() => {
                            document.querySelector('#optionsRadial').style.transform = 'scale(1.2)';
                          }}
                          onMouseLeave={() => {
                            document.querySelector('#optionsRadial').style.transform = 'scale(1)';
                          }}
                          series={[total_percentage]} type="radialBar" height={270} />
                        <div className="widget-content pt-0 w-100">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left pe-2 fsize-1">
                                <div className="widget-numbers mt-0 fsize-3 text-warning">
                                  {total_members}
                                </div>
                              </div>
                              <div className="widget-content-right w-100">
                                <Progress className="progress-bar-xs" color="warning" value={total_percentage} />
                              </div>
                            </div>
                            <div className="widget-content-left fsize-1">
                              <div className="text-muted opacity-6">
                                All Members
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="12" md="5" lg="4">
                    <Card className="mb-3" style={{ cursor: 'pointer' }}>
                      <CardBody className="p-0">
                        <Chart options={optionsRadial}
                          id="optionsRadial1"
                          style={{ transform: 'scale(1)', transition: 'transform 0.5s ease, background-color 0.3s ease' }}
                          onMouseEnter={() => {
                            document.querySelector('#optionsRadial1').style.transform = 'scale(1.2)';
                          }}
                          onMouseLeave={() => {
                            document.querySelector('#optionsRadial1').style.transform = 'scale(1)';
                          }}
                          series={[active_percentage]} type="radialBar" height={270} />
                        <div className="widget-content pt-0 w-100">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left pe-2 fsize-1">
                                <div className="widget-numbers mt-0 fsize-3 text-success">
                                  {active_members}
                                </div>
                              </div>
                              <div className="widget-content-right w-100">
                                <Progress className="progress-bar-xs" color="success" value={active_percentage} />
                              </div>
                            </div>
                            <div className="widget-content-left fsize-1">
                              <div className="text-muted opacity-6">
                                Active Members
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="12" md="5" lg="4">
                    <Card className="mb-3" style={{ cursor: 'pointer' }}>
                      <CardBody className="p-0">
                        <Chart
                          options={optionsRadial}
                          series={[inactive_percentage]}
                          type="radialBar"
                          height={270}
                          style={{ transform: 'scale(1)', transition: 'transform 0.5s ease, background-color 0.3s ease' }}
                          id="optionsRadial2"
                          onMouseEnter={() => {
                            document.querySelector('#optionsRadial2').style.transform = 'scale(1.2)';
                          }}
                          onMouseLeave={() => {
                            document.querySelector('#optionsRadial2').style.transform = 'scale(1)';
                          }}
                        />
                        <div className="widget-content pt-0 w-100">
                          <div className="widget-content-outer">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left pe-2 fsize-1">
                                <div className="widget-numbers mt-0 fsize-3 text-danger">
                                  {in_active_members}
                                </div>
                              </div>
                              <div className="widget-content-right w-100">
                                <Progress className="progress-bar-xs" color="danger" value={inactive_percentage} />
                              </div>
                            </div>
                            <div className="widget-content-left fsize-1">
                              <div className="text-muted opacity-6">
                                InActive Members
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                </Row> */}

                {/* <div className="card no-shadow bg-transparent no-border rm-borders mb-3">
                  <Card >
                    <Row className="g-0">
                      <Col md="12" lg="4">
                        <ListGroup flush>
                          <ListGroupItem className="bg-transparent">
                            <div className="widget-content p-0">
                              <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                  <div className="widget-content-left">
                                    <div className="widget-heading">{t("Total Members")}</div>
                                    <div className="widget-subheading">
                                      {t("All Active InActive Members")}
                                    </div>
                                  </div>
                                  <div className="widget-content-right">
                                    <div className="widget-numbers text-warning">
                                      <CountUp end={DashboardData.data ? DashboardData.data.all_members : ''} duration="10" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>

                      </Col>

                      <Col md="12" lg="4">
                        <ListGroup flush>

                          <ListGroupItem className="bg-transparent">
                            <div className="widget-content p-0">
                              <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                  <div className="widget-content-left">
                                    <div className="widget-heading">
                                      {t("Active Members")}
                                    </div>
                                    <div className="widget-subheading">
                                      {t("Membership Active Members")}
                                    </div>
                                  </div>
                                  <div className="widget-content-right">
                                    <div className="widget-numbers text-success">
                                      <CountUp end={DashboardData.data ? DashboardData.data.active_members : ''} duration="10" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      </Col>
                      <Col md="12" lg="4">
                        <ListGroup flush>
                          <ListGroupItem className="bg-transparent">
                            <div className="widget-content p-0">
                              <div className="widget-content-outer">
                                <div className="widget-content-wrapper">
                                  <div className="widget-content-left">
                                    <div className="widget-heading">{t("InActive Members")}</div>
                                    <div className="widget-subheading">
                                      {t("Membership not active yet!")}
                                    </div>
                                  </div>
                                  <div className="widget-content-right">
                                    <div className="widget-numbers text-danger">
                                      <CountUp end={DashboardData.data ? DashboardData.data.in_active_members : ''} duration="10" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>

                        </ListGroup>
                      </Col>
                    </Row>
                  </Card>
                </div> */}

                <Row>

                  <Col md="6">
                    <Card className="main-card mb-2">
                      <CardBody>
                        <CardTitle style={{ marginTop: '10px', marginBottom: '20px', fontSize: "20px", fontWeight: '700' }} >Membership Trends</CardTitle>
                        <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                          <LineChart data={Membership_Trends?.data?.monthlyCounts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" strokeWidth={2} dataKey="Corporate" stroke="#3BC37D" name="Corporate" />
                            <Line type="monotone" strokeWidth={2} dataKey="Government" stroke="#ed1515" name="Government" />
                            <Line type="monotone" strokeWidth={2} dataKey="Council" stroke="#000000" name="Council" />
                            <Line type="monotone" strokeWidth={2} dataKey="Honorary" stroke="#fcad03" name="Honorary" />
                            <Line type="monotone" strokeWidth={2} dataKey="Elected" stroke="#7532a8" name="Elected" />
                            <Line type="monotone" strokeWidth={2} dataKey="Ordinary_Individual_Professional" stroke="#3289a8" name="Ordinary Individual Professional" />
                            <Line type="monotone" strokeWidth={2} dataKey="Ordinary" stroke="#545CD7" name="Ordinary" />

                          </LineChart>
                        </ResponsiveContainer>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="main-card mb-2">
                      <CardBody>
                        <CardTitle style={{ marginTop: '10px', marginBottom: '20px', fontSize: "20px", fontWeight: '700' }} >Upcoming Renewals</CardTitle>
                        <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
                          <BarChart data={Expired_Members?.data?.monthlyCounts}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Corporate" fill="blue" />
                            <Bar dataKey="Government" fill="red" />
                            <Bar dataKey="Council" fill="black" />
                            <Bar dataKey="Honorary" fill="#fcad03" />
                            <Bar dataKey="Elected" fill="#7532a8" />
                            <Bar dataKey="Ordinary Individual Professional" fill="#3289a8" />
                            <Bar dataKey="Ordinary" fill="green" />
                          </BarChart>
                        </ResponsiveContainer>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          {t('Latest Members')}
                        </div>
                      </CardHeader>
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <DataTable
                            columns={mycolumns}
                            data={orders}
                            selectableRowDisabled={true}
                            persistTableHead
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                          // paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          {t('Expired Members')}
                        </div>
                      </CardHeader>
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <DataTable
                            columns={ExpiredColumns}
                            data={ExpiredMembers}
                            selectableRowDisabled={true}
                            persistTableHead
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment >
  );
};


export default Home;