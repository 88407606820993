import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const AddAttribute = () => {

  const { t, i18n } = useTranslation();



  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);
  

  const validateFtn = (values) => {
    console.log("values:-", values)
    const errors = {};
    if (!values.name) {
      errors.name = "Name Required.";
    }
    if (!values.percentage) {
      errors.percentage = "Percentage Required.";
    }


    return errors;
  };
  const onSubmit = (values) => {
    console.log("values", values)
    var payloadData = {
      functionName: "ADD_TaxClass",
      name: values.name,
      description: values.description,
      percentage: values.percentage,

    }
    setLoading(true);
    console.log("Attribute payload:-", payloadData)
    AttributesService.ADD_TaxClass(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        navigate("/Finance-Settings")
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Class added successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  };

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add New Tax Class')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add a Tax Class.')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateFtn}
                      render={({ handleSubmit, values, submitting, validating, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Name")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="name">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            type="text"
                                            placeholder={'Name for Tax Class'}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Description")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="description">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Description for Tax Class'}
                                            type="text"
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Percentage")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="percentage">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Percentage for Tax Class'}
                                            type="text"
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "20px" }}>
                            <FormGroup>
                              <div className="text-center">
                                <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                  Save
                                </Button>
                                <button type="button" onClick={() => {
                                  if (state.check) {
                                    localStorage.setItem('tab5', "5");
                                  }
                                  navigate(-1)
                                }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                  Cancel
                                </button>
                              </div>
                            </FormGroup>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}
export default AddAttribute;









