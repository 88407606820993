import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};


const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};
const bulkdeleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "BulkDeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const getcollection = (referal_name, filter, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, { headers });
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyusertypes';
  return axios.post(API_URL2, payload, { headers });
};

const getAllCustomers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/GetCustomers', payload, { headers });
};
const GetInvoiceTo = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/GetInvoiceTo', payload, { headers });
};
const getLastInvoiceNumber = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const getServices = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'ServicesRoutes/GetServices', payload, { headers });
};
const GetItems = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'ItemsRoutes/GetItems', payload, { headers });
};
const getCustomerServices = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'ServicesRoutes/getCustomerServices', payload, { headers });
};
const getPackaging = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'PackagingRoutes/GetPackaging', payload, { headers });
};
const GetCustomerPackaging = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'PackagingRoutes/GetCustomerPackaging', payload, { headers });
};
const getPackaging_size = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'PackagingSizeRoutes/GetSizes', payload, { headers });
};
const deleteCustomer = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomer",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const getDocTypes = () => {
  var payload = {
    functionName: "GetTypes",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};



const getCustomerDocs = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orders', payload, { headers });
};


const AddInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};
const UpdateInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const UpdateOrderStatus = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/UpdateOrder', payload, { headers });
};

const deleteDocument = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteDocument",
    postData: {
      "_id": id,
    },
  }
  return axios.post(B_URL + 'documents', payload, { headers });
};

const getCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const addCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const updateCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const deleteCustomerPricing = (id, service_id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomerPricing",
    postData: {
      "_id": id,
      "service_id": service_id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'customers', payload, { headers });
};

const getOrders = (id) => {
  var payload = {
    functionName: "GetCustomerOrders",
    postData: {
      user_id: id
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orders', payload, { headers });
};

const getAllOrders = (id) => {

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/GetOrders', id, { headers });
};
const getAllClasses = (payload) => {

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};
const getCustomerOrders = (payload) => {

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/GetCustomerOrders', (payload), { headers });
};
const getOrderDetails = (data) => {
  var payload = {
    functionName: "GetOrders",
    postData: {
    }
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/GetOrder', data, { headers });
};

const AddRecurringInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'recurring_invoices', payload, { headers });
};
const UpdateRecurringInvoice = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'recurring_invoices', payload, { headers });
};

const getLastRecurringInvoiceNumber = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'recurring_invoices', payload, { headers });
};

const CustomerService = {

  getLastRecurringInvoiceNumber,
  AddRecurringInvoice,
  UpdateRecurringInvoice,
  GetInvoiceTo,
  getAllCustomers,
  getServices,
  GetItems,
  getCustomerServices,
  GetCustomerPackaging,
  getOrderDetails,
  getAllOrders,
  getAllClasses,
  getLastInvoiceNumber,
  getPackaging,
  getCustomerPricing,
  getPackaging_size,
  deleteCustomer,
  getDocTypes,
  getOrders,
  getCustomerDocs,
  getCustomerOrders,
  AddInvoice,
  UpdateInvoice,
  UpdateOrderStatus,
  deleteDocument,
  addCustomerPricing,
  deleteCustomerPricing,
  updateCustomerPricing,
  import_dyn_records,
  getuserform,
  deleteCollection,
  bulkdeleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
  getoptions,
  save_user
};

export default CustomerService;
