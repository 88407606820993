import axios from "axios";


const API_URL = process.env.REACT_APP_BASEURL;
const Login_url = process.env.REACT_APP_LOGINURL;
// console.log("Login URL:-", Login_url)
// const API_URL = process.env.API_URL;

const register = (username, email, password, role_id) => {

  var newdata = {
    functionName: "AddUser",
    postData: {
      "name": username,
      "email": email,
      "password": password,
      "role_id": role_id
    },
  }
  return axios.post(API_URL + "settings", newdata);
  // return axios.post(API_URL + "signup", {
  //   username,
  //   email,
  //   password,
  // });
};

const login = (username, password, usertype) => {
  var logindata = {
    functionName: "LoginMainUser",
    postData: {
      "email": username,
      "password": password,
      "user_type": usertype
    },
  }

  return axios
    .post(Login_url + "login/", logindata)
    .then((response) => {

      if (response.data.status) {
        // if (response.data.accessToken) {
        var user_data = response.data.userData;
        user_data['username'] = user_data.email;
        user_data['accessToken'] = user_data._id;
        user_data['session-id'] = response.data.sessionID;
        user_data['roles'] = [
          "ROLE_ADMIN",
          "ROLE_USER"
        ];
        console.log("Login Response:-", response)
        localStorage.setItem("user", JSON.stringify(user_data));
        localStorage.setItem("session-id", response.data.sessionID);
      }
      // return response.data;
      console.log("User Data:-", user_data);
      return user_data;
    });
};

const logout = () => {
  // localStorage.removeItem("user");
  const userType = localStorage.getItem("user_type");
  const logo = localStorage.getItem("system_logo");
  const buttons_color = localStorage.getItem("buttons_color");
  const topbar_color = localStorage.getItem("topbar_color");
  const leftbar_color = localStorage.getItem("leftbar_color");
  const buttons_text_color = localStorage.getItem("buttons_text_color");
  const language = localStorage.getItem("language");

  localStorage.clear();
  localStorage.setItem("user_type", userType);
  localStorage.setItem("system_logo", logo);
  localStorage.setItem("buttons_color", buttons_color);
  localStorage.setItem("topbar_color", topbar_color);
  localStorage.setItem("leftbar_color", leftbar_color);
  localStorage.setItem("buttons_text_color", buttons_text_color);
  localStorage.setItem("language", language);

  var logindata = {
    functionName: "LogoutUser",
    postData: {
      // "email": username,
      // "password": password,
      // "resetcode": resetcode
    },
  }

  return axios
    // return axios.post(API_URL+"logout/")  
    .post(API_URL + "logout/", logindata)
    .then((response) => {
      return response.data;
    });
};

const pwresetreq = (username) => {

  var logindata = {
    functionName: "GetResetCodeMainUser",
    postData: {
      "email": username,
    },
  }

  return axios
    .post(Login_url + "forgotpassword/", logindata)
    .then((response) => {

      // if (response.data.status) {
      // if (response.data.accessToken) {
      // var user_data = response.data.userData;
      // }

      // console.log(response.headers);
      //   console.log(response.request);
      //   console.log(response.config);
      //   console.log(response.data);

      // return response.data;
      // console.log(user_data);
      return response;
    });
};

const resetpw = (username, password, resetcode) => {

  var logindata = {
    functionName: "ResetPassowrdMainUser",
    postData: {
      "email": username,
      "password": password,
      "resetcode": resetcode
    },
  }

  return axios.post(Login_url + "forgotpassword/", logindata)
  // .then((response) => {

  //   if (response.data.status) {
  //     var user_data = response.data.userData;
  //   }
  //   console.log(user_data);
  //   return user_data;
  // });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  pwresetreq,
  resetpw,
  getCurrentUser,
}

export default AuthService;
