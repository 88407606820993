import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";


const CustomerBasicInfo = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);



  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th >Name of Ordinary / Corporate / Honorary</th>
                    <td>{state.name_ordinary_corporate_honorary}</td>
                  </tr>
                  <tr>
                    <th>NRIC / Passport No. / Company Registration No.</th>
                    <td>{state.nric_passport_company_registration}</td>
                  </tr>
                  <tr>
                    <th >Correspondence / Business / Secreteriat Address</th>
                    <td>{state.correspondence_business_secreteriat_address}</td>
                  </tr>
                  <tr>
                    <th >Phone</th>
                    <td>{state.tel}</td>
                  </tr>
                  <tr>
                    <th >Fax</th>
                    <td>{state.fax}</td>
                  </tr>
                  <tr>
                    <th >H/P</th>
                    <td>{state.hp}</td>
                  </tr><tr>
                    <th >Email</th>
                    <td>{state.email}</td>
                  </tr>
                  <tr>
                    <th >Webpage</th>
                    <td>{state.webpage}</td>
                  </tr>
                  <tr>
                    <th >Company Type</th>
                    <td>{state.company_type}</td>
                  </tr>
                  <tr>
                    <th >Line of Business</th>
                    <td>{state.line_of_business}</td>
                  </tr>
                  <tr>
                    <th >Product(s) manufactured:</th>
                    <td>{state.product_manufactured}</td>
                  </tr>
                  <tr>
                    <th >Product(s) trade</th>
                    <td>{state.product_trade}</td>
                  </tr>
                  <tr>
                    <th >Service(s) provided</th>
                    <td>{state.service_provided}</td>
                  </tr>
                  <tr>
                    <th >Company</th>
                    <td>{state.Is_the_company}</td>
                  </tr>
                  <tr>
                    <th >Date of Incorporation</th>
                    <td>{state.date_of_incorporation}</td>
                  </tr>
                  <tr>
                    <th >Country of Incorporation</th>
                    <td>{state.Country_of_incorporation}</td>
                  </tr>
                  <tr>
                    <th >Paid-up capital (RM)</th>
                    <td>{state.paid_up_capital}</td>
                  </tr>
                  <tr>
                    <th >Total Investment (RM)</th>
                    <td>{state.total_investment}</td>
                  </tr>
                  <tr>
                    <th >Annual turnover (RM)</th>
                    <td>{state.annual_turnover}</td>
                  </tr>
                  <tr>
                    <th >No. of Employees</th>
                    <td>{state.no_of_employees}</td>
                  </tr>
                  <tr>
                    <th >Name of director</th>
                    <td>{state.name_of_director}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default CustomerBasicInfo;

