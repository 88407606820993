import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';


import EmailService from "./emailTemplate.service";
import AuthService from "../Authentication/auth.service";


const EmailTemplates = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_BASEURL;
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTemplates();
  }, []);

  function fetchTemplates() {
    setLoading(true);
    EmailService.getAll().then((response) => {
      setLoading(false);
      console.log("Get Templates response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setTemplates(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const TBcolumns = [
    {
      name: t('Template Name'),
      selector: (row) => {
        return (
          <div>
            <Link to="/view-email-template" state={row} >
              {row.template_name}
            </Link>
          </div>
        );
      }
    },
    {
      name: t('Subject'),
      selector: (row) => {
        return (
          <div>
            {/* <Link to="/view-email-template" state={row} > */}
            {row.temp_subject}
            {/* </Link> */}
          </div>
        );
      }
    },
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <Tooltip placement="top" title={t("Edit")} arrow>
            <Link to="/update-email-template" state={row} className="EditData">
              <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1"> </i>
            </Link>
            </Tooltip >

            {/* <a onClick={() => confirmDelete(row._id)} style={{cursor: "pointer"}} className="DeleteData">
              <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
            </a> */}
          </div>
        );
      }
    },
  ];

  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = templates.filter(
    item => item.template_name && item.template_name.toLowerCase().includes(filterText.toLowerCase()),
  );

  const handleSearch = (searchText) => {
    setFilterText(searchText);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <GenFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle])

  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this template!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    EmailService.deleteTemplate(id).then((response) => {
      console.log("Delete Template response:-", response)
      setLoading(false);
      fetchTemplates();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Template Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Template."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-mail icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t('Email Templates')}
                        <div className="page-title-subheading">
                          {t('List of all Email Templates created.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        {t('Back')}
                      </button>
                      {/* <a href="/add-email-template" className="AddData">
                        <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                      </a> */}
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                            {subHeaderComponentMemo}
                          </Col>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>

            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
}


export default EmailTemplates;

{/* <table className="table table-hover mb-0 square border">
  <thead className="thead-light">
    <tr>
      <th>Name</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {templates &&
      templates.map((template, index) => (
        <tr>
          <td>
            <Link to="/view-email-template" state={template}>
              {template.referal_name}
            </Link>
          </td>
          <td>
            <Link to="/add-email-template" state={template}>
              <i className="las la-pen text-secondary font-16" />
            </Link>
            <a onClick={() => deletetemplate(template._id)} style={{cursor: "pointer"}}>
              <i className="las la-trash-alt text-secondary font-16" />
            </a>
          </td>
        </tr>
      ))
    }
  </tbody>
</table> */}
