import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const B_URL = process.env.REACT_APP_LOGINURL
const storeduser = JSON.parse(localStorage.getItem("user"));


const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}


const getuserform = (name) => {
  var payload = {
    functionName: "GetUserForm",
    "postData": {
      name: name
    }
  }
  return axios.post(API_URL, payload, { headers });
};

//testing

const deleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const bulkdeleteCollection = (id, referal_name) => {
  var payload = {
    functionName: "BulkDeleteDynCollectionData",
    postData: {
      "_id": id,
      "collection_name": referal_name,
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getcollection = (referal_name, filter, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const getoptions = (referal_name, filter, fieldlist, limit, offset) => {
  var payload = {
    functionName: "GetCollectionData",
    postData: {
      "collection_name": referal_name,
      "login_user_id": storeduser._id,
      "fieldlist": fieldlist,
      "filter": filter,
      "limit": limit,
      "offset": offset
    },
  }
  return axios.post(API_URL, payload, { headers });
};

const insertCollectionData = payload => {
  console.log("Received addRole Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
};

const import_dyn_records = (formData) => {
  formData.login_user_id = storeduser._id
  var payload = {
    functionName: "ImportDynCollectionData",
    postData: formData
  }
  console.log(formData);
  return axios.post(API_URL, payload, { headers });
};

const getAllCruds = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "dynamic_modules",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const save_user = (payload) => {
  const API_URL2 = process.env.REACT_APP_LOGINURL + 'modifyusertypes';
  return axios.post(API_URL2, payload, { headers });
};

const getAllCustomers = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};
const fetchAllMemberships = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'itemsRoutes/' + payload.functionName, payload, { headers });
};

const deleteCustomer = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomer",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const sendCustomerPassword = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "sendCustomerPassword",
    postData: {
      _id: id,
    },
  }
  return axios.post(B_URL + 'customerRoutes/sendCustomerPassword', payload, { headers });
};

const getDocTypes = () => {
  var payload = {
    functionName: "GetTypes",
    postData: {},
  }
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const getCustomerDocs = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};


const AddCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const UpdateCustomerDocument = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const deleteDocument = (id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteDocument",
    postData: {
      "_id": id,
    },
  }
  return axios.post(B_URL + 'DocumentsRoutes/' + payload.functionName, payload, { headers });
};

const getCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const addCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const updateCustomerPricing = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const deleteCustomerPricing = (id, service_id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteCustomerPricing",
    postData: {
      "_id": id,
      "service_id": service_id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'customerRoutes/' + payload.functionName, payload, { headers });
};

const getCustomerOrders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};
const GetOrders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};
const getSingleOrder = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const deleteCustomerOrder = (id, user_id) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteOrder",
    postData: {
      "_id": id,
      "user_id": user_id
    },
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const addCustomerOrder = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const manualDriverAssign = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};



const updateCustomerOrderDetails = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const addCustomerOrderItems = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};


const updateSingleOrderItem = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};


const deleteSingleOrderItem = (id, orderID, userID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteOrderDetails",
    postData: {
      _id: id,
      order_id: orderID,
      user_id: userID
    },
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const getSingleOrderMedia = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
}


const AddSingleorderMedia = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const deleteSingleOrderMedia = (id, orderID, userID) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "DeleteOrderMedia",
    postData: {
      _id: id,
      order_id: orderID,
      user_id: userID
    },
  }
  return axios.post(B_URL + 'orderRoutes/' + payload.functionName, payload, { headers });
};

const sendOrder_PDF_Mail = (row) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "sendOrder_PDF_Mail",
    postData: {
      "row": row,
    },
  }
  return axios.post(B_URL + "invoices", payload, { headers });
};
const getOrder_PDF = (payload) => {
  const headers = {

    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, {
    headers,
    responseType: 'arraybuffer'
  });
};


const fetchLast_MembershipNumber = () => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  var payload = {
    functionName: "fetchLast_MembershipNumber",
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};



const CustomerService = {
  fetchLast_MembershipNumber,
  sendOrder_PDF_Mail,
  getAllCustomers,
  fetchAllMemberships,
  getOrder_PDF,
  deleteCustomer,
  GetOrders,
  sendCustomerPassword,
  getDocTypes,
  getCustomerDocs,
  AddCustomerDocument,
  UpdateCustomerDocument,
  deleteDocument,
  getCustomerPricing,
  addCustomerPricing,
  deleteCustomerPricing,
  updateCustomerPricing,
  getCustomerOrders,
  addCustomerOrder,
  manualDriverAssign,
  deleteCustomerOrder,
  updateCustomerOrderDetails,
  addCustomerOrderItems,
  updateSingleOrderItem,
  deleteSingleOrderItem,
  getSingleOrderMedia,
  AddSingleorderMedia,
  deleteSingleOrderMedia,
  import_dyn_records,
  getuserform,
  deleteCollection,
  bulkdeleteCollection,
  getcollection,
  insertCollectionData,
  getAllCruds,
  getoptions,
  save_user
};

export default CustomerService;