import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody } from "reactstrap";

import { useNavigate, useLocation } from "react-router-dom";

const IMAGEURL = process.env.REACT_APP_IMAGEURL

const CustomerBasicInfo = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);



  return (
    <Fragment>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <table className="table table-striped table-bordered nowrap">
                <tbody>
                  <tr>
                    <th >Membership Number</th>
                    <td>{state.membership_number}</td>
                  </tr>
                  <tr>
                    <th>Member Type</th>
                    <td style={{
                      color: state.user_type === 'ordinary' ? 'blue' :
                        state.user_type === 'corporate' ? 'gray' :
                          state.user_type === 'honorary' ? 'red' :
                            state.user_type === 'elected' ? '#a103fc' :
                              state.user_type === 'ordinary_individual_professional' ? 'orange' :
                                state.user_type === 'government' ? 'green' : 'black'
                    }}>
                      {state.user_type === 'ordinary' ? 'Ordinary Member' :
                        state.user_type === 'corporate' ? 'Corporate Member' :
                          state.user_type === 'elected' ? 'Exco Member (Elected)' :
                            state.user_type === 'ordinary_individual_professional' ? 'Ordinary - Individual| Professional' :
                              state.user_type === 'government' ? 'Government Nominee' :
                                'Chambers & Business Council Nominee'}
                    </td>
                  </tr>
                  <tr>
                    <th >Company Name</th>
                    <td>{state?.name}</td>
                  </tr>
                  <tr>
                    <th >Member Name</th>
                    <td>{state?.member_name}</td>
                  </tr>
                  <tr>
                    <th >Email</th>
                    <td>{state.email}</td>
                  </tr>
                  <tr>
                    <th >Alternative Email</th>
                    <td>{state.alternate_email_address}</td>
                  </tr>
                  <tr>
                    <th >Phone Number</th>
                    <td>{state.phone_number}</td>
                  </tr>
                  <tr>
                    <th >Nationality</th>
                    <td>{state.nationality}</td>
                  </tr>
                  <tr>
                    <th >Position In Company</th>
                    <td>{state.position_in_company}</td>
                  </tr>
                  <tr>
                    <th >Status</th>
                    <td>{state.status}</td>
                  </tr>
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );

};
export default CustomerBasicInfo;

