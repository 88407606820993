import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../components/Alerts/alerts";

import AttributesService from "./attributes.service";
import AuthService from "../Authentication/auth.service";
import Tooltip from '@mui/material/Tooltip';



const AttributesList = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();


  const { state } = location;
  const [loading, setLoading] = useState(false);
  const [attributeslist, setattributes] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchAttributes();
  }, []);



  function fetchAttributes() {
    setLoading(true);
    var filter = {};
    if (state._id) {
      filter['crud_id'] = state._id
    }
    AttributesService.getAll(filter).then((response) => {
      setLoading(false);
      console.log("Get Attributes response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setattributes(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  const TBcolumns = [
    {
      name: t('Field Label'),
      selector: row => row.field_lable,
    },
    {
      name: t('Field Type'),
      selector: row => row.field_type,
    },
    {
      name: t('Field Name'),
      selector: row => row.referal_name,
    },
    {
      name: t('Editable'),
      selector: (row) => {
        if (parseInt(row.is_editable)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    }, {
      name: t('DataTable'),
      selector: (row) => {
        if (parseInt(row.datatable)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },
    {
      name: t('Unique'),
      selector: (row) => {
        if (parseInt(row.is_unique)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },
    {
      name: t('Required'),
      selector: (row) => {
        if (parseInt(row.is_required)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },
    {
      name: t('Filterable'),
      selector: (row) => {
        if (parseInt(row.is_filterable)) {
          return (<div> <button className="btn btn-success btn">{t('Yes')}</button> </div>);
        } else {
          return (<div> <button className="btn btn-danger btn">{t('No')}</button> </div>);
        }

      }
    },
    {
      name: t('Actions'),
      selector: (row) => {
        return (
          <div>
            <Tooltip placement="top" title="Update" arrow>
            <Link className="EditData" to="/update-userform-attribute" state={row} >
              <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
            </Link>
            </Tooltip >
            <Tooltip placement="top" title="Delete" arrow>
            <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
              <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
            </a>
            </Tooltip >
          </div>

        );
      }
    },
  ];


  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const filteredItems = (attributeslist && attributeslist.filter(
    item => item.field_lable && item.field_lable.toLowerCase().includes(filterText.toLowerCase()),
  ));

  const handleSearch = (searchText) => {
    setFilterText(searchText);
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <GenFilter
        onSearch={handleSearch}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle])


  function confirmDelete(_id) {
    let msg = t("Once deleted, you will not be able to recover this attribute!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    AttributesService.deleteAttribute(id).then((response) => {
      setLoading(false);
      console.log("Delete Attribute response:-", response)
      fetchAttributes();
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Attribute Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          Alerts.swalErrorAlert(response.data.message)
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the Attribute at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" className="TabsAnimation" appear={true}
              timeout={1500} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-bookmarks icon-gradient bg-happy-green" />
                      </div>
                      <div>
                        {t('Attributes')}
                        <div className="page-title-subheading">
                          {t('View all Attributes, Create, Edit and Delete an Attribute.')}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                              {t('Back')}
                            </button>
                      
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="main-card mb-3">
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                           {subHeaderComponentMemo}
                            
                          </Col>
                          <DataTable
                            columns={TBcolumns}
                            data={filteredItems}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={<Link to="/add-userform-attribute" state={{ "crud_id": state._id }} className="AddData">
                            <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                          </Link>}
                            selectableRowDisabled={true}
                            persistTableHead
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );


}

export default AttributesList;