import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import { useNavigate, useLocation } from "react-router-dom";

import TabGeneralConfig from "./TabGeneralConfig";
import TabPaymentGateway from "./TabPaymentGateway";
import TabTaxClassesConfig from "./TabTaxClassesConfig";
import PaymentMethods from "./PaymentMethods";
import TabReminders from "./TabReminders";


const Configuration = () => {

  const { t, i18n } = useTranslation();
  const [checkTab, setCheckTab] = useState(1);
  const location = useLocation();
  const { state } = location;
  console.log("Received Object:-", state);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);
  useEffect(() => {
    if (state?.checkTab) {
      setCheckTab(state?.checkTab)
    }
  }, []);

  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title mb-0">
            <div className="page-title-wrapper">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-settings icon-gradient bg-happy-green" />
                </div>
                <div>{t('Finance Settings')}
                  <div className="page-title-subheading">
                    {t('View and update some basic configurations of Finance.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-12">
              <Tabs defaultActiveKey={checkTab} renderTabBar={() => <ScrollableInkTabBar />} renderTabContent={() => <TabContent />}>
                <TabPane tab={t('General Settings')} key="1" >
                  <TabGeneralConfig className="btn-custom-color" />
                </TabPane>
                <TabPane tab={t('Payment Gateway')} key="2" >
                  <TabPaymentGateway className="btn-custom-color" />
                </TabPane>
                <TabPane tab={t('Payment Methods')} key="3" >
                  <PaymentMethods className="btn-custom-color" />
                </TabPane>
                <TabPane tab={t('Reminders')} key="4" >
                  <TabReminders className="btn-custom-color" />
                </TabPane>
                <TabPane tab={t('Tax Classes')} key="5" >
                  <TabTaxClassesConfig className="btn-custom-color" />
                </TabPane>
              </Tabs>
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );

};
export default Configuration; 