// Import necessary modules from React and other libraries
import React, { Fragment, useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { format, parseISO } from 'date-fns';

// Import custom components and styles
import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";
import moment from 'moment';

import Alerts from "../../components/Alerts/alerts";

import DataTable from 'react-data-table-component';
import DashboardService from "./dashboard.service";
import AuthService from "../Authentication/auth.service";

// Define the functional component Home
const Home = () => {

  // Initializing hooks and state variables
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [Data, setData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentPage, setCurrentPage] = useState("");

  // Perform actions on component mount or when 'pageLimit' changes
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchTableData(0, pageLimit)
  }, [pageLimit]);

  // Function to fetch table data based on page index and limit
  function fetchTableData(page_index, page_limit) {
    // Prepare filter and retrieve user data from local storage
    var filter = {};
    const userString = localStorage.getItem('user');
    const user = JSON.parse(userString);
    const userId = user['_id'];
    filter["customer_id"] = userId

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    // Set loading state to true before fetching data
    setLoading(true);

    // Fetch data from DashboardService
    DashboardService.getAllStatements(offset, page_limit, userId).then((response) => {
      setLoading(false); // Set loading state to false after data retrieval
      // Handle response data
      if (response.data.message === "Unauthorized") {
        // Perform logout and redirection for unauthorized access
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setData(response.data); // Set retrieved data to state variable 'Data'
        if (response.data.data) {
          setTotalRows(response.data.length); // Set total rows based on response length
        }
      }
    },
      // Handle error scenarios
      (error) => {
        setLoading(false); // Set loading state to false in case of error
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  // Function to handle page change in the DataTable
  function handlePageChange(page_index) {
    setCurrentPage(page_index)
    fetchTableData(page_index, pageLimit); // Fetch data for the new page index
  }

  // Function to handle change in rows per page in the DataTable
  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit); // Set the new page limit
  }

  // Define columns for the DataTable
  const TBcolumns = [
    // Column definitions...
    {
      name: "ID",
      sortable: true,
      selector: (row) => {
        if (row.Payment_ID) {
          return <div>{row.Payment_ID}</div>;
        } else {
          return <div >{row.invoice_number}</div>
        }
      }
    },
    {
      name: "Date",
      selector: "payment_date",
      sortable: true,
      cell: (row) => (
        <div>
          {row.payment_date ? moment(row.payment_date).format('DD-MM-yyyy') : ''}
        </div>
      ),
    },
    {
      name: "Due Date",
      sortable: true,
      selector: (row) => {
        if (row.due_date) {
          return <div>{format(parseISO(row.due_date), 'dd-MM-yyyy')}</div>;
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => {
        if (row.Payment_ID) {
          return <div>{t("Payment")}</div>;
        } else {
          return <div >{t("Invoice")}</div>
        }
      }
    },
    {
      name: "Invoice Amount",
      sortable: true,
      selector: (row) => {
        if (row.total_amount) {
          return <div >{`${row.currency}${parseFloat(row.total_amount).toFixed(2)}`} </div>
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Payment Amount",
      sortable: true,
      selector: (row) => {
        if (row.amount) {
          return <div >{`${row.currency}${parseFloat(row.amount).toFixed(2)}`} </div>
        } else {
          return <div ></div>
        }
      }
    },
    {
      name: "Invoice",
      sortable: true,
      selector: (row) => {
        if (row.invoices) {
          return <div>{row.invoices.invoiceNumbers}</div>;
        } else {
          return <div ></div>
        }
      }
    },
  ];

  // Return JSX for the Home component
  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <TransitionGroup>
            <CSSTransition component="div" classNames="TabsAnimation"
              appear={true} timeout={0} enter={false} exit={false}>
              <div>
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-graph2 icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        {t('Dashboard')}
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab z-index-6">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          <i className="header-icon lnr-chart-bars icon-gradient bg-happy-green"> {" "} </i>
                          {t('Analytics')}
                        </div>
                      </CardHeader>
                      <Row className="g-0">
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-10 bg-warning" />
                              <i className="lnr-cart text-dark opacity-8" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Invoices Amount')}</div>
                              <div className="widget-amount">{`${Data?.result?.[0] ? Data?.result?.[0]?.currency : '0'}-${parseFloat(Data?.totalAmount).toFixed(2)}`}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="6" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-danger" />
                              <i className="pe-7s-credit text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Paid Amount')}</div>
                              <div className="widget-amount">{`${Data?.result?.[0] ? Data?.result?.[0]?.currency : '0'}-${parseFloat(Data?.paidAmount).toFixed(2)}`}</div>
                            </div>
                          </div>
                          <div className="divider m-0 d-md-none d-sm-block" />
                        </Col>
                        <Col sm="12" md="4" xl="4">
                          <div className="card no-shadow rm-border bg-transparent widget-chart text-start">
                            <div className="icon-wrapper rounded-circle">
                              <div className="icon-wrapper-bg opacity-9 bg-success" />
                              <i className="lnr-list text-white" />
                            </div>
                            <div className="widget-chart-content">
                              <div className="widget-subheading">{t('Unpaid Invoices Amount')}</div>
                              <div className="widget-amount">{`${Data?.result?.[0] ? Data?.result?.[0]?.currency : '0'}-${parseFloat(Data?.RemainingAmount).toFixed(2)}`}</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Card className="mb-3">
                      <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize fw-normal">
                          <i className="header-icon lnr-dice me-3 text-muted opacity-6"> {" "} </i>
                          {t('Statements')}
                        </div>
                      </CardHeader>
                      <CardBody>
                        <LoadingOverlay tag="div" active={loading}
                          styles={{ overlay: (base) => ({ ...base }) }}
                          spinner={<DNALoader />}>
                          <DataTable
                            data={Data.result}
                            columns={TBcolumns}
                            pagination
                            fixedHeader
                            fixedHeaderScrollHeight="400px"
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationResetDefaultPage={resetPaginationToggle}
                          />
                        </LoadingOverlay>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default Home;