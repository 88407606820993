import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";


import {Card, CardBody } from "reactstrap";
import AttributesService from "./attributes.service";
import EntitiesService from "../Entities/entities.service";
import AuthService from "../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";


const AddAttribute = () => {

  const { t, i18n } = useTranslation();

    
    
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    
    const form = useRef();
    const checkBtn = useRef();
    const [loading, setLoading] = useState(false);
    const [cruds, setcruds] = useState([]);
    const [rows, setrows] = useState([]);
    const [parents, setparents] = useState([]);
    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState("");
    const [options_collection, setoptions_collection] = useState([]);
    useEffect(() => {
      i18n.changeLanguage(localStorage.getItem('language'));
      fetchEntities();
      fetchAttributes();
      fetchcollections();
      addRow1();
    },[]);
    var formdata ={};
    
    function fetchEntities() {
      EntitiesService.getAll().then((response) => {
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          setcruds(response.data.data);
        }
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
        if(errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
    };
    function fetchcollections() {
      EntitiesService.fetchcollections().then((response) => {
        if(response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          setoptions_collection(response.data.data);
        }
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
        if(errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
    };
    
    function fetchAttributes() {
      AttributesService.getAll({crud_id:{"$ne":state.crud_id }}).then((response) => {
        setparents(response.data.data);
      },
      (error) => {
        const errContent =
        (error.response && error.response.data) || error.message || error.toString();
        if(errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      setMessage("");
      setSuccessful(false);
      let data = new FormData(e.target);
      let formdata = {};
      for (let [key, value] of data) {
        if (formdata[key] !== undefined) {
          if (!Array.isArray(formdata[key])) {
            formdata[key] = [formdata[key]];
          }
          formdata[key].push(value);
        } else {
          formdata[key] = value;
        }
      }
      
      var labelss = formdata['label[]'];
      var valuesss = formdata['value[]'];
      
      let options=[];
      if(Array.isArray(labelss)){
        for(var i=0; i < labelss.length ;i++){
          if(labelss[i] && valuesss[i])
          options.push({'label':labelss[i],'value':valuesss[i]});
        }
      }
      else {
        if(labelss && valuesss )
        options.push({'label':labelss,'value':valuesss});
      }
      formdata.options = options;
      formdata.map = "Custom1"
        var payloadData = {
          functionName: "AddCustomFields",
          postData:  formdata 
        }
        setLoading(true);
        console.log("Attribute payload:-", payloadData)
        AttributesService.addAttribute(payloadData).then((response) => {  
          setLoading(false);
          if(response.data.status){
            navigate("/attributes-list",{ state: {"_id":state.crud_id } })
            if(response.data.message !== '') {
              Alerts.swalSuccessAlert(response.data.message)
            }
            else {
              Alerts.swalSuccessAlert(t("Attribute added successfully."));
            }
          }
          else {
            if(response.data.message !== '') {
              if(response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
              }
              else {
                Alerts.swalErrorAlert(response.data.message)
              }
            }
            else {
              Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
            }
          }
        },
        (error) => {
          setLoading(false);
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert(t("Network Operation failed."))
        });
    };


  function addRow1(){
    setrows(1);
  }

  function addRow(){
    var saddRow = rows;
    saddRow = saddRow + 1;
    setrows(saddRow);
  }


  function remove_option(index){
    document.getElementById('parent'+index).remove();
  }

    return (
      <>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper justify-content-left">
                <div className="page-title-heading fw-bold">
                  <div className="page-title-icon">
                    <i className="pe-7s-plus icon-gradient bg-happy-green" />
                  </div>
                  <div>{t('Add New Attribute')}
                    <div className="page-title-subheading">
                      {t('Fill the form below to add a new Attribute.')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="d-flex justify-content-center">
              <div className="col-md-12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <LoadingOverlay tag="div" active={loading}
                      styles = {{overlay: (base) => ({...base})}}
                      spinner = {<DNALoader/>}>  
                      <Form onSubmit={handleSubmit} ref={form}>
                        {!successful && (
                          <div>
                            <div className="row" >
                              <div className="col-md-6 col-xl-6" >
                                <div className="form-group">
                                  <label htmlFor="name">{t('Field Label')}</label>
                                  <Input type="text" className="form-control" placeholder={t('Attribute Field Label')}  name="field_lable" id="field_lable"    />
                                </div>
                                </div> 
                                <div className="col-md-6 col-xl-6" >
                                  <div className="form-group">
                                    <label htmlFor="page_title">{t('Field Type')}</label>
                                    <select className="form-select" name="field_type"  id="field_type"    >
                                      <option disabled={true} value="">{t('--Choose an option--')}</option>
                                      <option key="0" value ="text">{t('Text Input')}</option>
                                      <option key="1" value ="number">{t('Number Input')}</option>
                                      <option key="2" value ="email">{t('Email Input')}</option>
                                      <option key="3" value ="password">{t('Password Input')}</option>
                                      <option key="3" value ="Date">{t('Date Picker')}</option>
                                      <option key="4" value ="dropdown">{t('Drop Down')}</option>
                                      <option key="5" value ="checkbox">{t('CheckBox')}</option>
                                      <option key="6" value ="radio">{t('Radio Button')}</option>
                                      {/* <option key="7" value ="file">{t('File Input')}</option> */}
                                    </select>
                                  </div>
                                </div>
                            </div>                      
                            <br/>
                            <div className="row">
                              <div className="col-md-6 col-xl-6">
                                <div className="form-group " >
                                  <label htmlFor="menu_label">{t('Select Entity')}</label>
                                  <select className="form-select" name="crud_id"   id="crud_id" value={state.crud_id}  >
                                    <option disabled={false} value="">{t('--Choose an option--')}</option>
                                    {cruds &&
                                      cruds.map((crud, inde) => (
                                      <option key = {inde} value={crud._id}>{crud.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-xl-6">
                                <div className="form-group " >
                                  <label htmlFor="referal_name">{t('Select Parent')}</label>
                                  <select className="form-select" name="field_parent"   id="field_parent"    >
                                    <option disabled={false} value="">{t('--Choose an option--')}</option>
                                    {parents &&
                                      parents.map((parent, inde) => (
                                      <option key = {inde} value={parent._id}>{parent.field_lable}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6 col-xl-6">    
                                  <div className="form-group">
                                    <label htmlFor="referal_name">{t('Referal Name')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Referal Name')}  name="referal_name" id="referal_name"    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Is Editable')}</label>
                                    <select className="form-select" name="is_editable"   id="is_editable"      >
                                        <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                        <option key="1" value ="1">{t('Yes')}</option>
                                        <option key="0" value ="0">{t('No')}</option>
                                    </select>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6 col-xl-6">
                                  <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Show in DataTable')}</label>
                                    <select className="form-select" name="datatable"   id="datatable"       >
                                      <option disabled={true}  value="">{t('--Choose an option--')}</option>
                                      <option key="1" value ="1">{t('Yes')}</option>
                                      <option key="0" value ="0">{t('No')}</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                        <label htmlFor="referal_name">{t('Is Required')}</label>
                                        <select className="form-select" name="is_required"   id="is_required"       >
                                            <option disabled={false}  value="">{t('--Choose an option--')}</option>
                                            <option key="1" value ="1">{t('Yes')}</option>
                                            <option key="0" value ="0">{t('No')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                        <label htmlFor="referal_name">{t('Is Unique')}</label>
                                        <select className="form-select" name="is_unique"   id="is_unique"       >
                                            <option disabled={false}  value="">{t('--Choose an option--')}</option>
                                            <option key="0" value ="0">{t('No')}</option>
                                            <option key="1" value ="1">{t('Yes')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                        <label htmlFor="referal_name">{t('is filterable')}</label>
                                        <select className="form-select" name="is_filterable"   id="i_filterable"       >
                                            <option disabled={false}  value="">{t('--Choose an option--')}</option>
                                            <option key="0" value ="0">{t('No')}</option>
                                            <option key="1" value ="1">{t('Yes')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>  
                            <br/>
                            <div className="row">
                            <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                    <label htmlFor="referal_name">{t('Get Options From')}</label>
                                    <select className="form-select" name="options_collection"   id="options_collection"      >
                                        <option disabled={false} value="">{t('--Choose an option--')}</option>
                                        {options_collection &&
                                          options_collection.map((opt_collection, inde) => (
                                          <option key = {inde} value={opt_collection.name}>{opt_collection.name}</option>
                                          ))
                                        }
                                    </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-6">
                                    <div className="form-group" >
                                      <label htmlFor="referal_name">{t('Options Field Name')}</label>
                                      <Input type="text" className="form-control" placeholder={t('Options Field Name')}  name="Options_Field_Name" id="Options_Field_Name"    />
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                              <div className="col-md-6 col-xl-6">
                                <h5>{t('Add Attribute Options (if any)')}</h5>
                              </div>
                            </div>   
                            <br/>
                            {Array.from({ length: rows }, (_, index) => (
                            <div className="row " id={"parent"+index}>
                              <div className="col-md-6 col-xl-6" >
                                <div className="form-group">
                                  <label htmlFor="name">{t('Option Label')}</label>
                                    <Input type="text" className="form-control" placeholder={t('Attribute Option Label')} id={"option_lable"+index} name="label[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-5 col-xl-5" >
                                  <div className="form-group">
                                    <label htmlFor="name">{t('Option Value')}</label>
                                      <Input type="text" className="form-control" placeholder={t('Attribute Option Value')} id={"option_value"+index}  name="value[]"     />
                                  </div>
                                </div> 
                                <div className="col-md-1 col-xl-1" >
                                  <div className="form-group">
                                    <br/>
                                    <a onClick={() =>remove_option(index)} style={{ cursor: "pointer" }}>
                                      <button className="btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-danger">
                                        <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
                                      </button>
                                    </a>
                                  </div>
                                </div> 
                            </div>
                            ))}
                              
                            <br/> 
                            <div className="row">
                              <div className="col-md-2 col-xl-2" >
                                <a  onClick={() => addRow()} className="btn btn-custom-color btn-hover-shine">{t('Add More')}</a>
                              </div>
                            </div> 
                            <br/>
                            <div className="form-group">
                              <div className="col-md-12 col-xl-12 ">
                                <center>
                                  <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                  <button type="submit" className="btn btn-custom-color btn-hover-shine me-3 mt-3" >{t('Add Attribute')}</button>
                                </center>
                              </div>
                            </div>
                          </div>
                        )}

                        {message && (
                          <div className="form-group">
                            <br/>
                            <div
                              className={
                                successful ? "alert alert-success" : "alert alert-danger"
                              }
                              role="alert"
                            >
                              {message}
                            </div>
                          </div>
                        )}
                        <CheckButton style={{ display: "none" }} ref={checkBtn} />
                      </Form>
                    </LoadingOverlay>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );


}
export default AddAttribute;









