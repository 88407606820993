import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";


import ConfigurationService from "./configuration.service";
import AuthService from "../../Authentication/auth.service";


const TabGenralConfig = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [configs, setConfig] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Retrieve data from localStorage
    const dataFromLocalStorage = localStorage.getItem('payment_types');
    if (dataFromLocalStorage) {
      const initialSelectedOptions = dataFromLocalStorage.split(',');
      setSelectedOptions(initialSelectedOptions);
    }
  }, []);
  const handleChipClick = (option) => {
    const isSelected = selectedOptions.includes(option);
    let updatedOptions;

    if (isSelected) {
      updatedOptions = selectedOptions.filter((selected) => selected !== option);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    // Update localStorage with the updated options
    localStorage.setItem('payment_types', updatedOptions.join(','));
  };
  useEffect(() => {
    getLocalStorageValues();
    i18n.changeLanguage(localStorage.getItem('language'));
    getSystemConfig();
  }, []);




  const getLocalStorageValues = () => {
    const storedValues = localStorage.getItem('payment_types');
    if (storedValues) {
      const valuesArray = storedValues.split(',');
      setSelectedCollections(valuesArray);
    }
  };




  function getSystemConfig() {
    ConfigurationService.getConfiguration().then((response) => {
      console.log("API Response:-", response);
      if (response.data.status) {
        const [rec_data] = response.data.data;
        console.log("setConfig", rec_data);
        setConfig(rec_data);
        setPayment_gateway(rec_data?.payment_gateway)

      }
      else {
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          alert(t("Unable to retrieve configurations at the moment."))
        }
      }
    },
      (error) => {
        alert(t("Unable to retrieve configurations at the moment."))
      });
  }
  const [payment_gateway, setPayment_gateway] = useState(configs?.payment_gateway);
  console.log("payment_gateway", payment_gateway);
  function handleSave(values) {
    setLoading(true);
    console.log("Form Values:-", values);
    localStorage.setItem("razer_pay_id", values.razer_pay_id);
    localStorage.setItem("razer_pay_sKey", values.razer_pay_sKey);
    localStorage.setItem("razer_pay_vKey", values.razer_pay_vKey);
    localStorage.setItem("razer_pay_api", values.razer_pay_api);
    localStorage.setItem("merchant_code", values.merchant_code);
    localStorage.setItem("merchant_key", values.merchant_key);
    localStorage.setItem("IPay88_api", values.IPay88_api);
    localStorage.setItem("payment_gateway", payment_gateway);
    localStorage.setItem("payment_types", localStorage.getItem('payment_types'));
    var payloadData = {
      functionName: "Set_Finance_Config",
      postData: {
        razer_pay_id: values.razer_pay_id,
        razer_pay_sKey: values.razer_pay_sKey,
        razer_pay_vKey: values.razer_pay_vKey,
        razer_pay_api: values.razer_pay_api,
        payment_gateway: payment_gateway,
        merchant_code: values.merchant_code,
        merchant_key: values.merchant_key,
        IPay88_api: values.IPay88_api,
        payment_types: localStorage.getItem('payment_types')
      },
    }
    ConfigurationService.setConfiguration(payloadData).then((response) => {
      console.log("API Response:-", response);
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Configuration updated successfully."));
        }
        getSystemConfig();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't update configuration at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log("Error:-", error)
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  }

  const validateFtn = (values) => {
    const errors = {};
    if (!values.razer_pay_id) {
      errors.razer_pay_id = t("Razer Pay Id Required.");
    }
    if (!values.razer_pay_sKey) {
      errors.razer_pay_sKey = t("Secret Key Required.");
    }
    if (!values.razer_pay_vKey) {
      errors.razer_pay_vKey = t("Verification Key Required.");
    }
    if (!values.razer_pay_api) {
      errors.razer_pay_api = t("Tax Percentage Required.");
    }
    if (!payment_gateway) {
      errors.payment_gateway = t("Payment Gateway Required.");
    }
    return errors;
  };
  const handleChangeColor = (userType) => {
    console.log("userType",)
    return selectedCollections.includes(userType) ? 'primary' : 'default';
  }
  console.log("collection", collection)
  return (
    <Fragment>
      <Card className="main-card mb-3">
        <CardBody className="col-md-12">
          <Form
            initialValues={configs}
            validate={validateFtn}
            onSubmit={handleSave}
            FormState
            render={({ handleSubmit, values, submitting, validating, valid, form: { getState } }) => (
              <form onSubmit={handleSubmit}>
                <LoadingOverlay tag="div" active={loading}
                  styles={{ overlay: (base) => ({ ...base }) }}
                  spinner={<DNALoader />}>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Field name="payment_gateway" >
                          {({ input, meta }) => (
                            <div >
                              <div className="row mb-3">
                                <Label for="payment_gateway" className="col-sm-2 col-form-label">{t('Payment Gateway')}</Label>
                                <div className="col-sm-5">

                                  <Input
                                    {...input}
                                    type="select"
                                    invalid={meta.error && meta.touched}
                                    value={payment_gateway}
                                    onChange={(e) => setPayment_gateway(e.target.value)}
                                    placeholder={t("Payment Gateway")}
                                  >
                                    <option disabled={false} value="">{`--${t("Select Payment Gateway")}--`}</option>
                                    <option key={'RazerPay'} value={'RazerPay'}>{t("RazerPay")}</option>
                                    <option key={'IPay88'} value={'IPay88'}>{t("IPay88")}</option>
                                  </Input>
                                  {meta.error && meta.touched &&
                                    <span className="text-danger">{meta.error}</span>}
                                </div>
                              </div>
                            </div>
                          )}
                        </Field>
                      </FormGroup>

                      {payment_gateway !== 'IPay88' ?
                        <>
                          <FormGroup>
                            <Field name="razer_pay_id">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="razer_pay_id" className="col-sm-2 col-form-label">{t('RazerPay ID')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Id for Razer Pay')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Field name="razer_pay_vKey">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="razer_pay_vKey" className="col-sm-2 col-form-label">{t('RazerPay vKey')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Verification Key for RazerPay')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Field name="razer_pay_sKey">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="due_date" className="col-sm-2 col-form-label">{t('RazerPay sKey')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Secret Key for RazerPay')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Field name="razer_pay_api">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="razer_pay_api" className="col-sm-2 col-form-label">{t('RazerPay API')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('API for RazerPay')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </>
                        :
                        <>
                          <FormGroup>
                            <Field name="merchant_code">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="merchant_code" className="col-sm-2 col-form-label">{t('Merchant Code')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Merchant Code for IPay88')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Field name="merchant_key">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="merchant_key" className="col-sm-2 col-form-label">{t('Merchant Key')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Merchant Key for IPay88')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                          <FormGroup>
                            <Field name="IPay88_api">
                              {({ input, meta }) => (
                                <div>
                                  <div className="row mb-3">
                                    <Label for="IPay88_api" className="col-sm-2 col-form-label">{t('IPay88 Api')}</Label>
                                    <div className="col-sm-5">
                                      <Input {...input} type="text"
                                        invalid={meta.error && meta.touched}
                                        placeholder={t('Api URL for IPay88')}
                                      />
                                      {meta.error && meta.touched &&
                                        <span className="text-danger">{meta.error}</span>}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Field>
                          </FormGroup>
                        </>
                      }
                      <Row >
                        <Col md={2}>
                          <div >
                            <Label for="payment_types" className=" col-form-label">{t('Payment Types')}</Label>
                          </div>
                        </Col>
                        <Col md={10}>
                          <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap', width: '100%' }}>
                            <Chip
                              key={'partial_payment'}
                              label="Partial Payment"
                              clickable
                              color={selectedOptions.includes('partial_payment') ? 'primary' : 'default'}
                              onClick={() => handleChipClick('partial_payment')}
                            />
                            {/* <Chip
                              key={'full_payment'}
                              label="Full Payment"
                              clickable
                              color={selectedOptions.includes('full_payment') ? 'primary' : 'default'}
                              onClick={() => handleChipClick('full_payment')}
                            />  */}
                            <Chip
                              key={'bulk_payment'}
                              label="Bulk Payment"
                              clickable
                              color={selectedOptions.includes('bulk_payment') ? 'primary' : 'default'}
                              onClick={() => handleChipClick('bulk_payment')}
                            />
                          </Stack>
                        </Col>

                      </Row>

                      <FormGroup>
                        <div className="d-flex justify-content-center EditData">
                          <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                          <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit" disabled={!valid}>{t('Save')}</button>
                        </div>
                      </FormGroup>

                    </Col>
                  </Row>
                </LoadingOverlay>
              </form>

            )}
          />
        </CardBody>
      </Card>
    </Fragment>
  );

};
export default TabGenralConfig; 