import React from "react";
import { Translation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { Row, Col, FormGroup, Input, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from "reactstrap";
import { Form, Field } from "react-final-form";
import CustomerService from "../Customer.service";
import AuthService from "../../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../../components/Loaders/DnaLoader";
import "../../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../../components/Alerts/alerts";;


class DocsAddCustomer extends React.Component {
  componentDidMount() {
    this.getDocTypes();
  }
  constructor(props) {
    super(props);
    console.log("Received Prop:-", props);
    this.state = {
      modal: false,
      backdrop: true,
      loading: false,
      loginNavigate: false,
      docTypes: [],
      upfile: ''
    };
    this.toggle = this.toggle.bind(this);
    this.validateFtn = this.validateFtn.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getDocTypes = this.getDocTypes.bind(this);
    this.onFileChange = this.onFileChange.bind(this);

  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  getDocTypes() {
    CustomerService.getDocTypes().then((response) => {
      console.log("Get DocTypes response:-", response);
      this.setState({
        docTypes: response.data.data,
      });
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert("Server Error, Please try again later.")
        }
      })
  }

  validateFtn = values => {
    const errors = {};
    if (!values.doc_type_id) {
      errors.doc_type_id = "Document Type Required.";
    }
    return errors;
  };

  onSubmit = values => {
    if (!this.state.upfile) {
      Alerts.swalErrorAlert("Document is required.")
    } else {
      console.log(values);
      var payloadData = {
        functionName: "AddDocument",
        postData: {
          doc_type_id: values.doc_type_id,
          file: this.state.upfile,
          user_id: this.props.data,
        }
      }
      this.setState({
        loading: !this.state.loading,
      });
      CustomerService.AddCustomerDocument(payloadData).then((response) => {
        console.log("API Response:-", response);
        this.setState({
          loading: !this.state.loading,
        });
        if (response.data.status) {
          this.props.onSuccess();
          if (response.data.message !== '') {
            Alerts.swalSuccessAlert(response.data.message).then(res => {
              console.log("Resp:-", res)
              this.toggle();
            })
          }
          else {
            Alerts.swalSuccessAlert("Document Added successfully.").then(res => {
              console.log("Resp:-", res)
              this.toggle();
            })
          }
        }
        else {
          if (response.data.message !== '') {
            if (response.data.message === "Unauthorized") {
              AuthService.logout();
              this.setState({ loginNavigate: true });
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.data.message).then(res => {
                console.log("Resp:-", res)
              })
            }
          }
          else {
            Alerts.swalErrorAlert("Error: Can't Add Document at the moment. Please try again later.")
          }
        }
      },
        (error) => {
          this.setState({
            loading: !this.state.loading,
          });
          const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          Alerts.swalErrorAlert("Network Operation failed.")
        });
    }

  }

  onFileChange = e => {
    console.log(e.target.files)
    var filename = e.target.files[0].name
    console.log("File Name", filename)
    var fileReader = new FileReader();
    fileReader.readAsDataURL(e.target.files[0])
    fileReader.onload = () => {
      const convertedPDF = fileReader.result
      console.log("Converted PDF File:-", convertedPDF)
      this.setState({
        upfile: convertedPDF
      });
    }
  }

  render() {
    {
      this.state.loginNavigate &&
        <Navigate to={'/login'} />
    }
    return (
      <Translation>
        {(t) => (
          <span className="d-inline-block float-end mb-2 me-2">
            <a onClick={this.toggle} className="btn btn-custom-color btn-hover-shine ms-1">
              Add New
            </a>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop={true}>
              <LoadingOverlay tag="div" active={this.state.loading}
                styles={{ overlay: (base) => ({ ...base }) }}
                spinner={<DNALoader />}>
                <ModalHeader >Upload Document</ModalHeader>
                <ModalBody>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <Row>
                        <Col md="12" className="pt-3">
                          <Form onSubmit={this.onSubmit}
                            validate={this.validateFtn}
                            render={({ handleSubmit, values, submitting, validating, valid }) => (
                              <form onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Field name="doc_type_id">
                                        {({ input, meta }) => (
                                          <div>
                                            <Input {...input} type="select"
                                              invalid={meta.error && meta.touched}
                                            >
                                              <option disabled={false} value="">Document Type</option>
                                              {this.state.docTypes &&
                                                this.state.docTypes.map((typ, inde) => (
                                                  <option key={inde} value={typ._id}>{typ.name}</option>
                                                ))
                                              }
                                            </Input>
                                            {meta.error && meta.touched &&
                                              <span className="text-danger">{meta.error}</span>}
                                          </div>
                                        )}
                                      </Field>
                                    </FormGroup>
                                  </Col>

                                  <Col md="12" >
                                    <FormGroup>
                                      <div className="mb-12">
                                        <Label className="mb-2">File (Max 1 MB)</Label>
                                        <Input name="file" type='file' onChange={this.onFileChange}
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <div className="text-center">
                                    <FormGroup>
                                      <div className="text-center">
                                        <button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                          Add </button>
                                        <button type="button" className="btn btn-outline-success btn-lg btn-hover-shine me-3" onClick={this.toggle}>
                                          Cancel
                                        </button>
                                      </div>
                                    </FormGroup>
                                  </div>
                                </Row>
                              </form>
                            )}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ModalBody>
              </LoadingOverlay>
            </Modal>
          </span>
        )}
      </Translation>
    );
  };


};

export default DocsAddCustomer;