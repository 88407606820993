import axios from "axios";

const API_URL = process.env.REACT_APP_BASEURL;
const storeduser = JSON.parse(localStorage.getItem("user"));
const B_URL = process.env.REACT_APP_LOGINURL

const headers = {
  'Content-Type': 'application/json',
  'token': localStorage.getItem("session-id")
}

const getAll = (filter) => {
  var payload = {
    functionName: "GetCollectionData",
    "postData": {
      "collection_name": "custom_fields_invoice",
      "filter": filter
    }
  }
  return axios.post(API_URL, payload, { headers });
};

const deleteAttribute = (id) => {
  var payload = {
    functionName: "DeleteCollectionData",
    postData: {
      "_id": id,
      "collection_name": "custom_fields_invoice",
      "login_user_id": storeduser._id
    },
  }
  return axios.post(API_URL, payload, { headers });
};


const addAttribute = payload => {
  console.log("Received addAttribute Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}



const ADD_TaxClass = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};


const ADD_Reminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const Update_Reminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const ADD_PaymentMethod = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};

const Update_PaymentMethod = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};

const Update_TaxClass = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const deleteClass = (_id) => {
  var payloadData = {
    functionName: "deleteClass",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payloadData, { headers });
};

const deleteReminder = (_id) => {
  var payloadData = {
    functionName: "deleteReminder",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payloadData, { headers });
};
const deletePaymentMethod = (_id) => {
  var payloadData = {
    functionName: "deletePaymentMethod",
    _id: _id,
  }

  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payloadData, { headers });
};



const getAllClasses = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const getAllReminders = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'invoices', payload, { headers });
};

const getAllPaymentMethods = (payload) => {
  const headers = {
    'Content-Type': 'application/json',
    'token': localStorage.getItem("session-id")
  }
  return axios.post(B_URL + 'payments', payload, { headers });
};


const updateAttribute = payload => {
  console.log("Received updateAttribute Payload:-", payload)
  return axios.post(API_URL, payload, { headers });
}

const AttributesService = {
  ADD_TaxClass,
  ADD_PaymentMethod,
  ADD_Reminders,
  Update_Reminders,
  Update_PaymentMethod,
  Update_TaxClass,
  getAll,
  getAllReminders,
  getAllClasses,
  deleteClass,
  deleteReminder,
  getAllPaymentMethods,
  deletePaymentMethod,
  deleteAttribute,
  addAttribute,
  updateAttribute
}

export default AttributesService;