import React, { useState, Fragment, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Row, Label, FormGroup, Input, Card, CardBody } from "reactstrap";
import { CKEditor } from "ckeditor4-react";
import { Form, Field } from "react-final-form";
import { useNavigate, useLocation } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../components/Loaders/DnaLoader";
import "../../assets/custom css/loadoverlay.scss";

import Alerts from "../../components/Alerts/alerts";

import EmailService from "./emailTemplate.service";
import AuthService from "../Authentication/auth.service";


const AddEmailTemplate = () => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [html, sethtml] = useState("");

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  const onSubmit = values => {
    console.log(values);
    var payloadData = {
      functionName: "AddTemplate",
      postData: {
        template_name: values.template_name,
        temp_subject: values.temp_subject,
        html: html,
      },
    }
    setLoading(true);
    EmailService.addTemplate(payloadData).then((response) => {        
      console.log("API Response:-", response);
      setLoading(false);
      if(response.data.status){
        navigate("/email-templates")
        if(response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Template added successfully."));
        }
      }
      else {
        if(response.data.message !== '') {
          if(response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add template at the moment. Please try again later."))
        }
      }
    },
    (error) => {
      setLoading(false);
      const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      Alerts.swalErrorAlert(t("Network Operation failed."))
    });
  }


  const validateFtn = (values) => {
    const errors = {};
    if (!values.template_name) {
      errors.template_name = t("Template Name Required.");
    }
    if (!values.temp_subject) {
      errors.temp_subject = t("Template Subject Required.");
    }
    // if (!values.html) {
    //   errors.html = "Please write HTML code for Email body.";
    // }
    return errors;
  };

  const getHtml = (value) => {
    console.log(value.editor.getData());
    sethtml(value.editor.getData());
  }


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-mail-open-file icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add New Template')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to add a new Email Template.')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        <div className="d-flex justify-content-center">
          <div className="col-md-7">
            <Card className="main-card mb-3">
              <CardBody>
                <Form onSubmit={onSubmit}
                  validate={validateFtn}
                  render={({ handleSubmit, values, submitting, validating, valid }) => (
                    <form onSubmit={handleSubmit}>
                      <LoadingOverlay tag="div" active={loading}
                        styles = {{overlay: (base) => ({...base})}}
                        spinner = {<DNALoader/>}>  
                        <Row form>
                          <Col md={12}>
                            <FormGroup>
                              <Field name="template_name">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="template_name">{t('Name')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Template Name')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Field name="temp_subject">
                                {({ input, meta }) => (
                                  <div>
                                    <Label for="temp_subject">{t('Subject')}</Label>
                                    <Input {...input} type="text" 
                                      invalid={meta.error && meta.touched} 
                                      placeholder={t('Subject')}
                                    />
                                    {meta.error && meta.touched && 
                                    <span className="text-danger">{meta.error}</span>}
                                  </div>
                                )}
                              </Field>
                            </FormGroup>
                            <FormGroup>
                              <Label for="html">{t('HTML')}</Label>
                              <CKEditor initData="" onChange={getHtml} />
                            </FormGroup>
                            <FormGroup>
                              <div className="d-flex justify-content-center">
                                <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-light btn-hover-shine me-3 mt-3">{t('Cancel')}</button>
                                <button className="btn btn-custom-color btn-hover-shine me-3 mt-3" type="submit">{t('Add Template')}</button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>   
                      </LoadingOverlay>
                    </form>
                  )}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        
        </div>
      </div>
    </Fragment>
  );

};

export default AddEmailTemplate;


{/* <FormGroup>
  <Field name="html">
    {({ input, meta }) => (
      <div>
        <Label for="html">HTML</Label>
        <Input {...input} type="textarea"
          invalid={meta.error && meta.touched}
          placeholder="Write HTML for template here"
        />
        {meta.error && meta.touched && 
        <span className="text-danger">{meta.error}</span>}
      </div>
    )}
  </Field>
</FormGroup> */}
