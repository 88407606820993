import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input
} from "reactstrap";
import $ from 'jquery';
import moment from 'moment';
import { Form, Field } from "react-final-form";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";
import { Modal, FormGroup, ModalHeader, ModalBody } from "reactstrap";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import DataTable from 'react-data-table-component';
import { Tooltip } from "@mui/material";

import InvoiceFilter from "../../../components/Table Filters/InvoiceFilter/CustomerFilterRecurring";
import AlertService from "../../../components/Alerts/alerts";



const CustomersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [pdfModalIsOpen, setPDFModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [filterInvoice_StartDate, setFilterInvoice_StartDate] = useState('');
  const [filterInvoice_EndDate, setFilterInvoice_EndDate] = useState('');
  const [filterDue_StartDate, setFilterDue_StartDate] = useState('');
  const [filterDue_EndDate, setFilterDue_EndDate] = useState('');
  const [search_text, setSearchText] = useState('');
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [filterText1, setFilterText1] = useState('');
  const [filterText2, setFilterText2] = useState('');
  const [filterText3, setFilterText3] = useState('');

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);

  function setFilterTextFtn(itemrec, param) {
    console.log(itemrec)
    console.log('Value of key1:', itemrec);
    if (param === 'users') {
      setFilterText1(itemrec);
    }
    if (param === 'orders') {
      setFilterText2(itemrec);
    }
  };



  const toggleModal = () => {
    setModal(!modal);
  };

  //for search with enter keypress
  function checkenterkey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function fetchCustomers(page_index, page_limit, filterText1, filterText2,
    filterInvoice_StartDate, filterInvoice_EndDate,
    filterDue_StartDate, filterDue_EndDate, search_text) {
    var filter = {};
    console.log(filterText1);
    if (filterText1) {
      console.log("filterText1");
      filter["customer_id"] = filterText1
    }
    if (search_text) {
      console.log("search_text");
      filter["search_text"] = search_text
    }
    if (filterText2) {
      console.log("filterText1");
      filter["status"] = filterText2
    }
    if (filterDue_StartDate) {
      console.log("filterDue_StartDate");
      filter["filterDue_StartDate"] = filterDue_StartDate
    } if (filterDue_EndDate) {
      console.log("filterDue_EndDate");
      filter["filterDue_EndDate"] = filterDue_EndDate
    } if (filterInvoice_StartDate) {
      console.log("filterInvoice_StartDate");
      filter["filterInvoice_StartDate"] = filterInvoice_StartDate
    } if (filterInvoice_EndDate) {
      filter["filterInvoice_EndDate"] = filterInvoice_EndDate
    }
    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }

    setLoading(true);

    CustomerService.Search_Recurring_Invoices(offset, page_limit, filterText1,
      filterText2, filterInvoice_StartDate, filterInvoice_EndDate, filterDue_StartDate, filterDue_EndDate, search_text).then((response) => {
        setLoading(false);
        console.log("Get Customers response:-", response)
        if (response.data.message === "Unauthorized") {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        }
        else {
          console.log(response.data);
          setCustomers(response.data.data);
          setTotalRows(response.data.count.count);
        }
      },
        (error) => {
          setLoading(false);
          const errContent =
            (error.response && error.response.data) || error.message || error.toString();
          console.log("Net:-", errContent)
          if (errContent) {
            Alerts.swalErrorAlert(errContent)
          }
          else {
            Alerts.swalErrorAlert(t("Server Error, Please try again later."))
          }
        })
  };
  function handleClear(item) {
    if (item === 'users') {
      $("#colfilter").val('');
      setFilterText1('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'action') {
      // $("#modfilter").val('');
      $("#actfilter").val('');
      setFilterText2('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
    if (item === 'function') {
      $("#funcfilter").val('');
      setFilterText3('');
      setResetPaginationToggle(!resetPaginationToggle);
      var page = 0
      handlePageChange(page++)
    }
  };

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterText1('');
    setSearchText('');
    setFilterInvoice_StartDate('');
    setFilterInvoice_EndDate('');
    setFilterDue_StartDate('');
    setFilterDue_EndDate('');
    setFilterText2('');
    fetchCustomers(0, pageLimit);

  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    // Filter values
    const filteredText1 = filterText1 || "";
    const filteredText2 = filterText2 || "";
    const FilterInvoice_StartDate1 = filterInvoice_StartDate || "";
    const FilterInvoice_EndDate2 = filterInvoice_EndDate || "";
    const FilterDue_StartDate3 = filterDue_StartDate || "";
    const Search_text = search_text || "";
    const FilterDue_EndDate4 = filterDue_EndDate || "";
    console.log(FilterDue_StartDate3)
    console.log(FilterDue_EndDate4)
    fetchCustomers(0, pageLimit, filteredText1, filteredText2, FilterInvoice_StartDate1,
      FilterInvoice_EndDate2, FilterDue_StartDate3, FilterDue_EndDate4, Search_text);
  }

  const handleInvoicePayments = (row) => {
    if (row.status !== 'paid') {
      row.is_admin = false;
      navigate('/add-payment', { state: { row, check: false } });
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }
  function confirmDeleteCustomer(_id) {
    let msg = t("Once deleted, you will not be able to recover this!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };


  function sendDelete(id) {
    setLoading(true);
    CustomerService.delete_Recurring_Invoice(id).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {

          console.log(response);
          setLoading(false);
          Alerts.swalSuccessAlert(t("Invoice Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the customer at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const mycolumns = [
    {
      name: "Start Date",
      selector: "starts_on",
      sortable: true,
      cell: (row) => format(parseISO(row?.starts_on), 'dd-MM-yyyy'),
    },
    {
      name: "Recurring Invoice",
      selector: "recurring_invoice_number",
      sortable: true,
    },

    {
      name: "Customer Name",
      selector: (row) => {
        return <div >{row.user_details ? row?.user_details[localStorage.getItem('invoice_to_field')] : ''}</div>
      },
      sortable: true,
    },

    {
      name: "End Date",
      selector: "ends_on",
      sortable: true,
      cell: (row) => {
        if (row.ends_on !== null) {
          if (row.ends_on_checkbox) {
            return "Never Expires";
          } else {
            return format(parseISO(row.ends_on), 'dd-MM-yyyy');
          }
        } else {
          return "Never Expires";
        }
      },
    },
    {
      name: "Repeat Every",
      sortable: true,
      selector: (row) => (
        <div >{`${row.repeat_every}(s) `}</div>
      )
    },
    {
      name: "Due After",
      sortable: true,
      selector: (row) => (
        <div >{`${row.due_date} Day(s)`} </div>
      )
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Send Email" arrow>
                <i onClick={() => handleMail(row)} className="pe-7s-mail text-primary btn-icon-wrapper"></i>
              </Tooltip>
              <Tooltip placement="top" title="View PDF" arrow>
                <i onClick={() => handleViewPDF(row._id)} className="pe-7s-look text-primary btn-icon-wrapper"> </i>
              </Tooltip>
              <Tooltip placement="top" title="Download PDF" arrow>
                <i onClick={() => handlePDF(row._id)} className="pe-7s-download text-success btn-icon-wrapper text-info"> </i>
              </Tooltip>
              <Tooltip placement="top" title="Update Recurring Invoices" arrow>
                <i>
                  <i onClick={() => handleEdit(row, false)} className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                </i>
              </Tooltip>
              <Tooltip placement="top" title="Delete Recurring Invoice" arrow>
                <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip>
            </button>
          </div>
        );
      }
    },
  ];
  const downloadPDF = (response) => {
    console.log("PDF Data Length: ", response.data.length);
    console.log("Partial PDF Data: ", response.data.slice(0, 100));
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

    // Create a download link and trigger the download
    const url = window.URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'invoice.pdf'; // Set the desired file name here.
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link and revoking the URL
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  };

  const handlePDF = (_id) => {
    var getData = {
      functionName: "Get_PDF_Invoice",
      _id: _id
    }
    setLoading(true);
    CustomerService.getInvoice_PDF(getData).then((response) => {
      console.log(response);
      const pre = document.createElement('pre');
      pre.textContent = response.data;
      document.body.appendChild(pre);
      setLoading(false);
      if (response.status === 200) {

        downloadPDF(response);
      } else if (response.status === 401) {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      } else {
        // Handle other error cases, if necessary
        console.log("Response status: " + response.status);
        // Display an error message or perform other actions as needed.
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      })
  }
  const handlePayments = (row, method) => {
    if (row.status !== 'paid') {
      method.is_admin = true;
      navigate('/add-payment', { state: { row, check: false, method } });
    } else {
      AlertService.swalErrorAlert("Invoice Already Paid Successfully")
    }

  }
  const handleEdit = (row, check) => {
    console.log(row);
    if (row.status !== 'paid' && row.status !== 'partial paid') {
      navigate('/update-recurring-invoice', {
        state: {
          state: row,
          check: check
        }
      });
    } else {
      AlertService.swalErrorAlert("Paid Invoice isn't Changeable")
    }

  }

  function handleMail(row) {
    let msg = t("Are you sure you want to send Invoice in Email?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendMail(row)
      }
    })
  };

  function sendMail(row) {
    setLoading(true);
    CustomerService.sendPDF_Mail(row).then((response) => {
      console.log("Send mail to:-", response)
      setLoading(false);
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Invoice emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };



  const handleViewPDF = (_id) => {
    var getData = {
      functionName: "Get_PDF_Invoice",
      _id: _id
    };
    setLoading(true);
    console.log(getData);
    CustomerService.getInvoice_PDF(getData)
      .then((response) => {
        setLoading(false);
        console.log(response.status);
        console.log(response);
        if (response.status === 200) {
          setPdfUrl(URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));
          setPDFModalIsOpen(true);
        } else if (response.status === 401) {
          AuthService.logout();
          navigate("/login");
          window.location.reload();
        } else {
          // Handle other error cases, if necessary
          console.log("Response status: " + response.status);
          // Display an error message or perform other actions as needed.
        }
      })
      .catch((error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent);
        if (errContent) {
          Alerts.swalErrorAlert(errContent);
        } else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."));
        }
      });
  };

  const PDFViewerModal = () => {
    console.log(pdfUrl);
    return (
      <div
        className={`modal fade ${pdfModalIsOpen ? "show" : ""}`}
        style={{ display: pdfModalIsOpen ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header ">
              <h5 className="modal-title">Customer's Order Invoice</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setPDFModalIsOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <iframe title="pdfUrl" src={pdfUrl} style={{ width: "100%", height: "500px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <PDFViewerModal />
      <Fragment>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className=" bg-light-gray bg-animation ">
              <div className="d-flex  justify-content-center align-items-center">
                <Col md="12" className="mx-auto app-login-box">
                  <div className="app-page-title">
                    <div className="page-title-wrapper">
                      <div className="page-title-heading fw-bold">
                        <div className="page-title-icon">
                          <i className="pe-7s-news-paper icon-gradient bg-tempting-azure" />
                        </div>
                        <div>
                          {t("Recurring Invoice Management")}
                          <div className="page-title-subheading">
                            {t('Add, Update, Delete & View Details of Recurring Invoices.')}
                          </div>
                        </div>
                      </div>
                      <div className="page-title-actions">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                          {t("Back")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Fragment>
                      <Row>
                        <Col md="12">
                          <Card className="main-card mb-3">
                            <CardBody>
                              <LoadingOverlay tag="div" active={loading}
                                styles={{ overlay: (base) => ({ ...base }) }}
                                spinner={<DNALoader />}>

                                <InvoiceFilter
                                  search_text={search_text}
                                  onSearch_text={(e) => {
                                    setSearchText(e.target.value);
                                  }}

                                  onFilterInvoice_StartDate={setFilterInvoice_StartDate}
                                  onFilterInvoice_EndDate={setFilterInvoice_EndDate}

                                  onFilterDue_StartDate={setFilterDue_StartDate}
                                  onFilterDue_EndDate={setFilterDue_EndDate}



                                  searchCustomers={searchCustomers}
                                  resetFilters={resetFilters}
                                  onFilter1={(e) => {
                                    console.log(e.target.value);
                                    setFilterTextFtn(e.target.value, 'users');
                                  }}
                                  onFilter2={(e) => {
                                    console.log(e.target.value);
                                    setFilterText2(e.target.value, 'users');
                                  }}
                                  handleKeyDown={(event) => checkenterkey(event)}
                                  filterText1={filterText1}
                                  filterText2={filterText2}
                                  filterInvoice_StartDate={filterInvoice_StartDate}
                                  filterInvoice_EndDate={filterInvoice_EndDate}
                                  filterDue_StartDate={filterDue_StartDate}
                                  filterDue_EndDate={filterDue_EndDate}

                                  onClear={() => handleClear('users')}
                                />

                                <DataTable
                                  data={customers}
                                  columns={mycolumns}
                                  pagination
                                  fixedHeader
                                  fixedHeaderScrollHeight="400px"
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  paginationResetDefaultPage={resetPaginationToggle}
                                />
                              </LoadingOverlay>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Fragment>
                  </Row>
                </Col>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    </>

  );
};

export default CustomersList;