
import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Label, FormGroup, Input } from "reactstrap";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import img404 from "../../assets/utils/images/custom/404.png";

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <Fragment>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <TransitionGroup>
                        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                            timeout={1500} enter={false} exit={false}>
                            <div>
                                <div className="bg-light-gray bg-animation">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Col md="12" className="mx-auto">
                                            <div className="d-flex justify-content-center mt-3">
                                                <img src={img404} alt="Image not found" className="h-70" />
                                            </div>
                                            <div className="d-flex justify-content-center mt-3">
                                                <button type="button" onClick={() => navigate(-2)} className="btn btn-success btn-hover-shine me-5 btn-lg">
                                                    Back
                                                </button>
                                            </div>
                                            {/* {<CopyRight/>} */}
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
            </div>
        </Fragment>
    );
};

export default PageNotFound;