import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { format, parseISO, set } from 'date-fns';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import $ from 'jquery';
import fileDownload from 'js-file-download'
import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';
import DateRangePickerComponent from '../../../components/Table Filters/InvoiceFilter/DateRangePickerComponent'; // Adjust the import path as needed
import styled from "styled-components";

import moment from 'moment';
import {
  Row,
  Col,
  Input,
  Card,
  CardBody
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";
import Alerts from "../../../components/Alerts/alerts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLockOpen, faLock, faKey } from "@fortawesome/free-solid-svg-icons";

import CustomerService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";
import DataTable from 'react-data-table-component';
const fileName = "Members";

library.add(
  faLockOpen,
  faLock,
  faKey
);


const API_URL = process.env.REACT_APP_BASEURL;
const API2_URL = process.env.REACT_APP_LOGINURL;
const storeduser = JSON.parse(localStorage.getItem("user"));

const CustomersList = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [All_Customers, setAllCustomers] = useState([]);
  const [AllMemberships, setAllMemberships] = useState([]);

  const [totalRows, setTotalRows] = useState(0);
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchName, setsearchName] = useState('');
  const [Member_Type, setMember_Type] = useState('');
  const [searchRegNo, setsearchRegNo] = useState('');
  const [searchEmail, setsearchEmail] = useState('');
  const [modal, setModal] = useState(false);
  const [fieldlist, setfieldlist] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [total_records, settotal_records] = useState(0);
  const [imported_records, setimported_records] = useState(0);
  const [not_imported, setnot_imported] = useState(0);
  const [duplicates, setduplicates] = useState([]);
  const [totalduplicates, settotalduplicates] = useState(0);
  const [parentCRUD, setParentCRUD] = useState(0);
  const [TotalRecords, setTotalRecords] = useState(0);
  const [ImportedRecords, setImportedRecords] = useState(0);
  const [SkippedRecords, setSkippedRecords] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchCustomers(0, pageLimit);
  }, [pageLimit]);

  useEffect(() => {
    fetchAllCustomers();
    fetchAllMemberships();
  }, []);

  function fetchAllCustomers() {
    var filter = {};
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "filter": filter
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setAllCustomers(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };
  function fetchAllMemberships() {
    var filter = {};
    var getData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "filter": filter
      },
    }
    setLoading(true);
    CustomerService.fetchAllMemberships(getData).then((response) => {
      setLoading(false);
      console.log("Get Membership response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setAllMemberships(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  function fetchCustomers(page_index, page_limit) {
    var filter = {};
    if (searchName !== "") {
      console.log("Search name:-", searchName)
      filter["search_Text"] = searchName
    }
    if (Member_Type !== "") {
      console.log("Search Member Type:-", Member_Type)
      filter["Member_Type"] = Member_Type
    }
    if (StartDate !== "" && EndDate !== "") {
      console.log("Search Member Type:-", Member_Type)
      filter["start_date"] = StartDate
      filter["end_date"] = EndDate
    }

    var offset = (page_index - 1) * page_limit;
    if (offset < 1) {
      offset = 0;
    }
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": page_limit,
        "filter": filter
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };



  const handleDownload = (filename) => {

    var columnslist = [];
    var valuelist = [];
    columnslist.push('membership_number');
    columnslist.push('member_type');
    columnslist.push('name');
    columnslist.push('member_name');
    columnslist.push('phone_number');
    columnslist.push('email');
    columnslist.push('nationality');
    columnslist.push('position_in_company');
    columnslist.push('alternate_email_address');

    valuelist.push("MP00001");
    valuelist.push("corporate");
    valuelist.push("None!");
    valuelist.push("Ihtesham Mansoor");
    valuelist.push("03425505565");
    valuelist.push("humayun@mpbc.my");
    valuelist.push("Pakistani");
    valuelist.push("Others");
    valuelist.push("humayun@mpbc.my");


    var csvData = columnslist.join(',') + "\r\n" + valuelist.join(',');
    fileDownload(csvData, filename)

  }

  function handlePageChange(page_index) {
    console.log("Page index:-", page_index)
    setCurrentPage(page_index)
    fetchCustomers(page_index, pageLimit);
  }

  async function handlePerRowsChange(newPageLimit, page_index) {
    setPageLimit(newPageLimit);
  }

  function nameFilter(e) {
    console.log(e.target.value);
    setsearchName(e.target.value)
  }
  function Member_type_Filter(e) {
    console.log(e.target.value);
    setMember_Type(e.target.value)
  }
  function regFilter(e) {
    console.log(e.target.value);
    setsearchRegNo(e.target.value)
  }
  function emailFilter(e) {
    console.log(e.target.value);
    setsearchEmail(e.target.value)
  }


  function resetFilters() {
    setResetPaginationToggle(!resetPaginationToggle);
    setsearchName('');
    setMember_Type('');
    setsearchRegNo('');
    setStartDate('');
    setEndDate('');
    setsearchEmail('');
    var offset = 0;
    var getData = {
      functionName: "GetCustomers",
      postData: {
        "sort": { _id: -1 },
        "offset": offset,
        "limit": pageLimit,
        "filter": {}
      },
    }
    setLoading(true);
    CustomerService.getAllCustomers(getData).then((response) => {
      setLoading(false);
      console.log("Get Customers response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        setCustomers(response.data.data);
        setTotalRows(response.data.count.count);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        console.log("Net:-", errContent)
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  }

  function searchCustomers() {
    setResetPaginationToggle(!resetPaginationToggle);
    var page = 0
    handlePageChange(page++)
  }


  const Label = styled.label`
  display: block;
  font-weight: bold;
`;

  const headers = [
    { label: "Membership Number", key: "membership_number" },
    { label: "Member Type", key: "user_type" },
    { label: "Company Name", key: "name" },
    { label: "Member Name", key: "member_name" },
    { label: "Email", key: "email" },
    { label: "Alternative Email", key: "alternate_email_address" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Nationality", key: "nationality" },
    { label: "Position in Company", key: "position_in_company" },
    { label: "Member Status", key: "status" },
  ];

  function uploadFileButton() {
    setLoading2(true);
    var formData = new FormData();
    formData.append('file', $('#fileUpload').prop('files')[0]);
    formData.append("login_user_id", storeduser._id)
    formData.append("functionName", "BulkImportedMembers")

    formData.append("currency", localStorage.getItem('currency'))
    formData.append("paymentMethod", localStorage.getItem('payment_method'))
    formData.append("comments", localStorage.getItem('default_notes'))
    formData.append("due_date", parseInt(localStorage.getItem('due_date')))

    const headers = {
      'token': localStorage.getItem("session-id")
    }
    $.ajax({
      url: API2_URL + 'import_csv/',
      data: formData,
      type: 'POST',
      headers: headers,
      contentType: false, // NEEDED, DON'T OMIT THIS (requires jQuery 1.6+)
      processData: false, // NEEDED, DON'T OMIT THIS
      success: function (response) {
        setLoading2(false);
        console.log(response);
        toggleModal();
        toggleModal2();
        if (response.status) {
          setTotalRecords(response.data.total_rows);
          setImportedRecords(response.data.imported_records);
          setSkippedRecords(response.data.skipped_records);
          fetchCustomers(0, pageLimit);
          Alerts.swalSuccessAlert(response.message)
        }
        else {
          if (response.message !== '') {
            if (response.message === "Unauthorized") {
              AuthService.logout();
              navigate("/login");
              window.location.reload();
            }
            else {
              Alerts.swalErrorAlert(response.message)
            }
          }
          else {
            Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
          }
        }

      }
    });
  }
  function confirmDeleteCustomer(_id) {
    let msg = t("Once deleted, you will not be able to recover this Member!")
    Alerts.swalConfirmDeleteAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendDelete(_id)
      }
    })
  };

  function sendDelete(id) {
    setLoading(true);
    CustomerService.deleteCustomer(id).then((response) => {
      setLoading(false);
      console.log("Delete customer response:-", response)
      if (response.data.status) {
        fetchCustomers(currentPage, pageLimit)
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Member Deleted Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't delete the member at the moment."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Delete operation failed"))
      })
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleModal2 = () => {
    setModal2(!modal2);
  };
  const select_all_records = () => {

    if ($('.select_all_records').is(":checked")) {
      $('.check_records').prop("checked", true);
    } else {
      $('.check_records').prop("checked", false);
    }


  }

  const check_record = (e) => {
    var element = e.target;
    if ($(element).is(":checked")) {

      var checkall = 1;
      $('.check_records').map(function () {

        if ($(this).is(":checked")) {
        } else {
          checkall = 0;

        }

      });
      if (checkall) {
        $('.select_all_records').prop("checked", true);
      }
    } else {
      $('.select_all_records').prop("checked", false);
    }
  }

  function regeneratePassword(_id) {
    let msg = t("Are you sure you want to regenerate password for this Member?")
    Alerts.swalConfirmGeneralAlert(msg).then(res => {
      console.log("Resp:-", res)
      if (res === true) {
        sendPassword(_id)
      }
    })
  };

  function sendPassword(id) {
    setLoading(true);
    CustomerService.sendCustomerPassword(id).then((response) => {
      console.log("Send Password response:-", response)
      setLoading(false);
      fetchCustomers(currentPage, pageLimit)
      if (response.data.status) {
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Password emailed Successfully."))
        }
        console.log(response.data.message)
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message);
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't send the Password."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Operation failed"))
      })
  };
  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    setStartDate(startDateFormatted);
    setEndDate(endDateFormatted);
  };

  const mycolumns = [
    {
      name: "Membership Number",
      selector: row => row?.membership_number,
    },
    {
      name: "Member Type",
      selector: (row) => {
        const lowerCaseUserType = row.user_type.toLowerCase();

        let label = '';
        let color = '';

        if (lowerCaseUserType.includes('corporate')) {
          label = 'Corporate Member';
          color = 'blue';
        } else if (lowerCaseUserType.includes('honorary')) {
          label = 'Honorary Member';
          color = 'red';
        } else if (lowerCaseUserType.includes('government')) {
          label = 'Government Nominee';
          color = 'green';
        } else if (lowerCaseUserType.includes('elected')) {
          label = 'Exco Member (Elected)';
          color = 'orange';
        } else if (lowerCaseUserType.includes('ordinary_individual_professional')) {
          label = 'Ordinary - Individual| Professional';
          color = '#a103fc';
        } else if (lowerCaseUserType.includes('ordinary')) {
          label = 'Ordinary  Member';
          color = 'gray';

        } else if (lowerCaseUserType.includes('council')) {
          label = 'Chambers & Business Council Nominee';
          color = 'black';
        }

        return (
          <div style={{ color }}>
            {label}
          </div>
        );
      }
    },
    {
      name: "Company/Person Name",
      width: "17%",
      selector: (row) => {
        const fullName = row?.name !== '' ? row?.name : row?.member_name;
        return (
          <Tooltip title={fullName}>
            <div className="hoverable-name">
              {fullName}
            </div>
          </Tooltip>
        );
      },
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: row => row.phone_number,
    },
    {
      name: "Email",
      selector: row => row.email
    },
    {
      name: "Expiry Date",
      selector: row => row?.membershipData?.[0]?.expiry_date ? moment(row?.membershipData?.[0]?.expiry_date).format('DD-MMM-YYYY') : ''
    },
    {
      name: t('Status'),
      selector: (row) => {
        return (
          <div>
            {row.status === "Active" ?
              <button type="button" style={{ borderColor: '#F39026', backgroundColor: '#F39026' }}
                className="btn btn-success btn-hover-shine me-3 btn">Active
              </button>
              :
              row.status === "InActive" ?
                <button type="button"
                  className="btn btn-danger btn-hover-shine me-3 btn">InActive
                </button>
                :
                ""
            }
          </div>
        );
      }
    },
    {
      name: 'Actions',
      selector: (row) => {
        return (
          <div>
            <button className="mt-2 mb-2 me-2 btn-icon btn">
              <Tooltip placement="top" title="Member Details" arrow>
                <Link to="/customer-details" state={row}>
                  <i className="pe-7s-note2 btn-icon-wrapper text-info"> </i>
                </Link>
              </Tooltip >

              <Tooltip placement="top" title="Update" arrow>
                <i>
                  <Link to="/update-members" state={row}>
                    <i className="pe-7s-pen btn-icon-wrapper text-primary"> </i>
                  </Link>
                </i>
              </Tooltip >

              <Tooltip placement="top" title="Delete" arrow>
                <i onClick={() => confirmDeleteCustomer(row._id)} className="pe-7s-trash text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              {/* {row.customer_status === "Active" && */}
              <Tooltip placement="top" title="Send Credentials" arrow>
                <i onClick={() => regeneratePassword(row._id)} className="pe-7s-mail-open-file text-danger btn-icon-wrapper"> </i>
              </Tooltip >
              {/* } */}
              {/* <Tooltip placement="top" title="Regenrate Password" arrow>
                <i onClick={() => regeneratePassword(row._id)} className="text-warning btn-sm px-0 btn-icon-wrapper">
                  <FontAwesomeIcon icon={["fas", "key"]} />
                </i>
              </Tooltip> */}
            </button>
          </div>
        );
      }
    },
  ];


  return (
    <Fragment>
      <div className="app-main__outer">
        <div className="app-main__inner">

          <div>
            <span className="d-inline-block mb-2 me-2">

              <Modal isOpen={modal} toggle={toggleModal} backdrop={true}>
                <ModalHeader >{t('Import Records')}</ModalHeader>
                <ModalBody>
                  <LoadingOverlay tag="div" active={loading2}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Card className="main-card mb-3">
                      <CardBody>
                        <form id="importform">
                          <input
                            type='file' name="importfile" label={t('Upload')} accept='.csv'
                            onChange={uploadFileButton}
                            id="fileUpload"
                          />

                          <br />
                          <p style={{ border: "3px solid red", padding: "5px", marginTop: "10px" }}>{t("* Please do not remove or change the first row in the sample file also add member type.")}</p>
                        </form>

                      </CardBody>
                    </Card>
                  </LoadingOverlay>
                </ModalBody>
                <ModalFooter>
                  <Button className="btn btn-success btn-hover-shine me-3 btn" onClick={() => {
                    handleDownload('sample.csv')
                  }}>{t('Download Sample')}</Button>
                  <Button color="link" onClick={toggleModal} className="btn btn-danger btn-hover-shine me-3 btn">
                    {t('Cancel')}
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={modal2} toggle={toggleModal2} backdrop={true}>
                <ModalHeader ><h4 style={{ color: "green" }} >{t('Import Records Stats')}</h4></ModalHeader>
                <ModalBody>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <h4>{t("Total Records")} : <span> {TotalRecords}</span></h4>
                      <h4 style={{ color: "green" }} >{t("Records Imported")} : <span > {ImportedRecords}</span></h4>
                      <h4 style={{ color: "red" }}>{t("Records Not Imported")} : <span  > {SkippedRecords}</span></h4>
                      {/* <h4 style={{ color: "orange" }} >{t("Serials Already Found")} : <span > {totalduplicates}</span></h4> */}
                      {/* {duplicates && duplicates.map((dup, index) => {
                            return (
                              <><span>{dup}</span></>
                            )
                          })} */}



                    </CardBody>
                  </Card>
                </ModalBody>
                <ModalFooter>
                  <Button color="link" onClick={toggleModal2} className="btn btn-danger btn-hover-shine me-3 btn">
                    {t('Close')}
                  </Button>
                </ModalFooter>
              </Modal>

            </span>
          </div>

          <div className=" bg-light-gray bg-animation ">
            <div className="d-flex  justify-content-center align-items-center">
              <Col md="12" className="mx-auto app-login-box">
                <div className="app-page-title">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading fw-bold">
                      <div className="page-title-icon">
                        <i className="pe-7s-users icon-gradient bg-tempting-azure" />
                      </div>
                      <div>
                        Manage Members
                        <div className="page-title-subheading">
                          Explore detailed information and sub-tabs for each Member.
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions">
                      <button type="button" onClick={() => navigate(-1)} className="btn btn-outline-success btn-hover-shine me-3">
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <Row>
                  <Fragment>
                    <Row>
                      <Col md="12">
                        <Card className="main-card mb-3">
                          <CardBody>
                            <Row className="mt-2">
                              <Row>
                                <Col md="2">
                                  <Input type="text" name="fname" value={searchName} placeholder="Search Text" onChange={event => nameFilter(event)} />
                                </Col>

                                <Col md="2">
                                  <Input type="select"
                                    placeholder="Select Membership"
                                    name="member_type" value={Member_Type}
                                    onChange={event => Member_type_Filter(event)}

                                  >
                                    <option disabled={false} value="">{`${t('Select Membership')}`}</option>
                                    {AllMemberships.map((option) => (
                                      <option key={option.item_name} value={option.item_name} >{option.item_name}</option>
                                    ))}
                                  </Input>
                                </Col>
                                <Col md="3">
                                  <div style={{
                                    background: '#F9F9FB',
                                    height: '35px',
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '6px',
                                    marginBottom: '20px',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }} className="mb-2">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Label style={{ background: '#F9F9FB', paddingRight: '5px', paddingLeft: '5px' }}>
                                        {t(`Expiry`)}
                                      </Label>
                                      <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                                        <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                                      </div>
                                      {/* DateRangePickerComponent for Order Date */}
                                      <DateRangePickerComponent onDateChange={handleDateChange} />
                                    </div>
                                  </div>
                                </Col>

                                <Col md="5" className="d-flex justify-content-end align-items-center">
                                  <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={() => searchCustomers()}>Search</button>
                                  <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={() => resetFilters()}>Reset</button>
                                  <button style={{ marginLeft: '5px' }} type="button" className="btn btn-custom-color btn-hover-shine mr-10" onClick={toggleModal}>{t('Import')}</button>
                                  <a style={{ marginLeft: '5px' }} href="/add-members" className="btn btn-custom-color btn-hover-shine ml-10">Add New</a>
                                  <button
                                    // onClick={toggleModal}>{t('Import')} 
                                    className="btn text-info px-0 py-0 mb-0">
                                    <CSVLink
                                      headers={headers}
                                      data={All_Customers}
                                      style={{ marginLeft: '5px' }}
                                      type="button"
                                      className="btn btn-custom-color btn-hover-shine mr-10"
                                      filename={fileName}
                                    >
                                      Export
                                    </CSVLink>


                                  </button>
                                </Col>
                              </Row>


                              <Col md="12" className="pt-3">
                                <LoadingOverlay tag="div" active={loading}
                                  styles={{ overlay: (base) => ({ ...base }) }}
                                  spinner={<DNALoader />}>
                                  <DataTable
                                    data={customers}
                                    columns={mycolumns}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="400px"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangePage={handlePageChange}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    paginationResetDefaultPage={resetPaginationToggle}
                                  />
                                </LoadingOverlay>
                              </Col>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Fragment>

                </Row>
              </Col>
            </div>
          </div>
        </div>
      </div >

    </Fragment >
  );
};

export default CustomersList;