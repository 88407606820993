import React, { useEffect, useState, Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Row, Col, Card, CardBody } from "reactstrap";
import Tooltip from '@mui/material/Tooltip';

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import GenFilter from "../../../components/Table Filters/GenFilter";
import DataTable from 'react-data-table-component';

import Alerts from "../../../components/Alerts/alerts";

import AttributesService from "./fields.service";
import AuthService from "../../Authentication/auth.service";



const AttributesList = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();


    const { state } = location;
    const [loading, setLoading] = useState(false);
    const [attributeslist, setattributes] = useState([]);

    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('language'));
        fetchAttributes();
    }, []);



    function fetchAttributes() {
        setLoading(true);
        var payloadData = {
            functionName: "getAllReminders",
            filter: {},

        }
        AttributesService.getAllReminders(payloadData).then((response) => {
            setLoading(false);
            console.log("Get Attributes response:-", response)
            if (response.data.message === "Unauthorized") {
                AuthService.logout();
                navigate("/login");
                window.location.reload();
            }
            else {
                console.log(response)
                setattributes(response.data.data);
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                if (errContent) {
                    Alerts.swalErrorAlert(errContent)
                }
                else {
                    Alerts.swalErrorAlert(t("Server Error, Please try again later."))
                }
            })
    };

    const TBcolumns = [
        {
            name: t('Reminder'),
            selector: (row) => {
                return (
                    <div>
                        {`${row.reminder_days} Day(s) ${row.before_after} Due Date`}
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: t('Status'),
            selector: (row) => {
                return (
                    <div>
                        {row.status === "Active" ?
                            <button type="button"
                                className="btn btn-success btn-hover-shine me-3 btn">{row.status}
                            </button>
                            :
                            row.status === "Inactive" ?
                                <button type="button"
                                    className="btn btn-danger btn-hover-shine me-3 btn">{row.status}
                                </button>
                                :
                                <button type="button"
                                    className="btn btn-default btn-hover-shine me-3 btn">{row.status}
                                </button>
                        }
                    </div>
                );
            }
        },
        {
            name: t('Actions'),
            selector: (row) => {
                return (
                    <div>
                        <Tooltip placement="top" title={t("Edit")} arrow>
                            <Link className="EditData" to="/Update_Reminders" state={row} >
                                <i className="pe-7s-pen icon-gradient bg-arielle-smile btn-lg px-1 "> </i>
                            </Link>
                        </Tooltip >
                        <Tooltip placement="top" title={t("Delete")} arrow>
                            <a className="DeleteData" onClick={() => confirmDelete(row._id)} style={{ cursor: "pointer" }}>
                                <i className="pe-7s-trash icon-gradient bg-ripe-malin btn-lg px-0"> </i>
                            </a>
                        </Tooltip >
                    </div>

                );
            }
        },
    ];


    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const filteredItems = (attributeslist && attributeslist.filter(
        item => item.reminder_days && item.reminder_days.toLowerCase().includes(filterText.toLowerCase()),
    ));
    const handleSearch = (searchText) => {
        setFilterText(searchText);
    };

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <GenFilter
                onSearch={handleSearch}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle])


    function confirmDelete(_id) {
        let msg = t("Once deleted, you will not be able to recover this Reminder!")
        Alerts.swalConfirmDeleteAlert(msg).then(res => {
            console.log("Resp:-", res)
            if (res === true) {
                sendDelete(_id)
            }
        })
    };

    function sendDelete(id) {
        setLoading(true);
        AttributesService.deleteReminder(id).then((response) => {
            setLoading(false);
            console.log("Delete Attribute response:-", response)
            fetchAttributes();
            if (response.data.status) {
                if (response.data.message !== '') {
                    Alerts.swalSuccessAlert(response.data.message)
                }
                else {
                    Alerts.swalSuccessAlert(t("Reminder Deleted Successfully."))
                }
                console.log(response.data.message)
            }
            else {
                if (response.data.message !== '') {
                    Alerts.swalErrorAlert(response.data.message)
                }
                else {
                    Alerts.swalErrorAlert(t("Error: Can't delete the Field at the moment."))
                }
            }
        },
            (error) => {
                setLoading(false);
                const errContent =
                    (error.response && error.response.data) || error.message || error.toString();
                Alerts.swalErrorAlert(t("Delete operation failed"))
            })
    };


    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" className="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12">
                                <Card className="main-card mb-3">
                                    <CardBody>
                                        <LoadingOverlay tag="div" active={loading}
                                            styles={{ overlay: (base) => ({ ...base }) }}
                                            spinner={<DNALoader />}>
                                            <Col md="6" style={{ float: "left", position: "absolute", zIndex: "1" }}>
                                                {subHeaderComponentMemo}
                                            </Col>
                                            <DataTable
                                                columns={TBcolumns}
                                                data={filteredItems}
                                                pagination
                                                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                                subHeader
                                                subHeaderComponent={<Link to="/Add_Reminders" state={{ "crud_id": 1 }} className="AddData">
                                                    <button type="button" className="btn btn-custom-color btn-hover-shine me-3 btn">{t('Add New')}</button>
                                                </Link>}
                                                selectableRowDisabled={true}
                                                persistTableHead
                                            />
                                        </LoadingOverlay>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    );


}

export default AttributesList;