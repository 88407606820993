import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { Form, Field } from "react-final-form";

import AttributesService from "./Customer.service";
import AuthService from "../../Authentication/auth.service";

import LoadingOverlay from "react-loading-overlay-ts";
import DNALoader from "../../../components/Loaders/DnaLoader";
import "../../../assets/custom css/loadoverlay.scss";

import Alerts from "../../../components/Alerts/alerts";


const AddAttribute = () => {

  const { t, i18n } = useTranslation();



  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [Memberships, setMemberships] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedMembership, setSelectedMembership] = useState('');
  const [amount, setAmount] = useState('');
  const [Duration, setDuration] = useState('');
  const [repeat_every, setRepeat_every] = useState('');


  useEffect(() => {
    if (selectedMembership) {
      const selectedMembershipDetails = Memberships.find(
        (membership) => membership.item_name === selectedMembership
      );

      if (selectedMembershipDetails) {
        const selectedMembershipPrice = selectedMembershipDetails.price || '';
        const selectedMembershipDuration = selectedMembershipDetails.duration || '';

        console.log('Selected Membership Price:', selectedMembershipPrice);
        console.log('Selected Membership Duration:', selectedMembershipDuration);
        setSelectedItem(selectedMembershipDetails);
        setAmount(selectedMembershipPrice);
        setRepeat_every(selectedMembershipDuration);
        // Calculate the expiration date based on the current date and selected duration
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth(); // Get the current month (0-indexed)

        let expirationYear, expirationMonth;

        if (currentMonth >= 6) { // Check if the current month is July (6) or later
          expirationYear = currentDate.getFullYear() + 1; // Next year
          expirationMonth = 11; // December (11 is zero-based index)
        } else {
          expirationYear = currentDate.getFullYear(); // Current year
          expirationMonth = 11; // December
        }
        // Set the expiration date to the calculated year and month, with day 31
        const expirationDate = new Date(expirationYear, expirationMonth, 32);

        // const expirationDate = calculateExpirationDate(currentDate, selectedMembershipDuration);
        // console.log('Membership Expiration Date:', expirationDate);
        setDuration(expirationDate);
      }
    }
  }, [selectedMembership]);

  // Function to calculate expiration date based on current date and duration
  const calculateExpirationDate = (startDate, duration) => {
    const endDate = new Date(startDate);

    // Parse the duration to extract the value and unit (days, months, years)
    const durationValue = parseInt(duration);
    const durationUnit = duration.replace(durationValue, '').trim().toLowerCase();

    switch (durationUnit) {
      case 'day':
      case 'Days':
      case 'Day':
      case 'days':
        endDate.setDate(startDate.getDate() + durationValue);
        break;
      case 'Month':
      case 'month':
      case 'Months':
      case 'months':
        endDate.setMonth(startDate.getMonth() + durationValue);
        break;
      case 'year':
      case 'Year':
      case 'Years':
      case 'years':
        endDate.setFullYear(startDate.getFullYear() + durationValue);
        break;
      default:
        break;
    }

    return endDate;
  };



  const [loading, setLoading] = useState(false);
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchMemberships();
  }, []);

  function fetchMemberships() {
    setLoading(true);
    var payloadData = {
      functionName: "GetItems",
      postData: {
        "sort": { _id: -1 },
        "offset": 0,
        "limit": 10000,
        "filter": {}
      },

    }
    AttributesService.GetItems(payloadData).then((response) => {
      setLoading(false);
      console.log("Get Memberships response:-", response)
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        console.log(response)
        setMemberships(response.data.data);
      }
    },
      (error) => {
        setLoading(false);
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };


  const validateFtn = (values) => {
    console.log("values:-", values)
    const errors = {};
    if (!values.membership_name) {
      errors.membership_name = "membership Required.";
    }
    if (!amount) {
      errors.amount = "amount Required.";
    }


    return errors;
  };
  const onSubmit = (values) => {
    const newData = [{
      id: 1,
      item_name: selectedItem.item_name,
      package: selectedItem.item_name,
      tax_class: 0,
      rate: amount,
      quantity: 1,
      amount: amount,
      tax: 0
    }];

    console.log("values", values)
    var payloadData = {
      functionName: "ADDMembershipUser",
      membership_name: selectedMembership,
      amount: amount,
      expiry_date: values.expiry_date,
      user_id: state._id,
      item: newData,
      currency: localStorage.getItem('currency'),
      paymentMethod: localStorage.getItem('payment_method'),
      comments: localStorage.getItem('default_notes'),
      due_date: parseInt(localStorage.getItem("due_date")),
      repeat_every: repeat_every,
    }
    setLoading(true);
    console.log("Attribute payload:-", payloadData)
    AttributesService.ADDMembershipUser(payloadData).then((response) => {
      setLoading(false);
      if (response.data.status) {
        // const row = {
        //   response: response?.data?.data?.insertedId,
        //   membership_name: selectedMembership,
        //   amount: amount,
        //   expiry_date: values.expiry_date,
        //   customer_id: state._id,
        //   state: state,
        //   checkTab: 3
        // }
        navigate("/invoices-list");
        if (response.data.message !== '') {
          Alerts.swalSuccessAlert(response.data.message)
        }
        else {
          Alerts.swalSuccessAlert(t("Membership Type Added Successfully."));
        }
      }
      else {
        if (response.data.message !== '') {
          if (response.data.message === "Unauthorized") {
            AuthService.logout();
            navigate("/login");
            window.location.reload();
          }
          else {
            Alerts.swalErrorAlert(response.data.message)
          }
        }
        else {
          Alerts.swalErrorAlert(t("Error: Can't add attribute at the moment. Please try again later."))
        }
      }
    },
      (error) => {
        setLoading(false);
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        Alerts.swalErrorAlert(t("Network Operation failed."))
      });
  };

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper justify-content-left">
              <div className="page-title-heading fw-bold">
                <div className="page-title-icon">
                  <i className="pe-7s-plus icon-gradient bg-happy-green" />
                </div>
                <div>{t('Add Membership')}
                  <div className="page-title-subheading">
                    {t('Fill the form below to Add a Membership For User')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <Card className="main-card mb-3">
                <CardBody>
                  <LoadingOverlay tag="div" active={loading}
                    styles={{ overlay: (base) => ({ ...base }) }}
                    spinner={<DNALoader />}>
                    <Form
                      onSubmit={onSubmit}
                      validate={validateFtn}
                      render={({ handleSubmit, values, submitting, validating, valid }) => (
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Membership")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="membership_name">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            type="select"
                                            className="membership_name"
                                            invalid={meta.error && meta.touched}
                                            onChange={(e) => {
                                              setSelectedMembership(e.target.value);
                                              input.onChange(e);
                                            }}
                                            value={selectedMembership}
                                          >
                                            <option disabled={false} value="">
                                              Select Membership
                                            </option>
                                            {Memberships &&
                                              Memberships.map((membership, index) => (
                                                <option key={index} value={membership.item_name}>
                                                  {membership.item_name}
                                                </option>
                                              ))}
                                          </Input>
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Amount")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left">
                                    <Field name="amount">
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Amount'}
                                            type="number"
                                            value={amount}
                                            onChange={(e) => { setAmount(e.target.value) }}
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12" >
                              <Row className="align-items-center">
                                <Col md="12">
                                  <Label className="">{("Expiry Date")}</Label>
                                </Col>
                                <Col md="12">
                                  <FormGroup className=" align-items-left" >
                                    <Field name="expiry_date" initialValue={Duration ? Duration.toISOString().split('T')[0] : ''}>
                                      {({ input, meta }) => (
                                        <div>
                                          <Input
                                            {...input}
                                            placeholder={'Expiry Date'}
                                            type="date"
                                            invalid={meta.error && meta.touched}
                                          />
                                          {meta.error && meta.touched && (
                                            <span className="text-danger">{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row style={{ marginTop: "20px" }}>
                            <FormGroup>
                              <div className="text-center">
                                <Button type="submit" className="btn btn-secondary btn-lg btn-hover-shine me-3">
                                  Save
                                </Button>
                                <button type="button" onClick={() => {
                                  if (state.check) {
                                    localStorage.setItem('tab5', "2");
                                  }
                                  navigate(-1)
                                }} className="btn btn-outline-success btn-lg btn-hover-shine me-3">
                                  Cancel
                                </button>
                              </div>
                            </FormGroup>
                          </Row>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );


}
export default AddAttribute;









