import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from "styled-components";
import CustomerService from "../../../pages/Finance/Invoices/Invoices.service";
import AuthService from "../../../pages/Authentication/auth.service";
import Alerts from "../../Alerts/alerts";
import { Row, Col } from 'react-bootstrap';
import { Input } from 'reactstrap';

import DateRangePickerComponent from './DateRangePickerComponent'; // Adjust the import path as needed
// import { CSVLink } from 'react-csv';

const DateInput = styled.input.attrs(props => ({
  type: "date",
  placeholder: "Select Date",
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
`;

const Inputs = styled.select.attrs(props => ({
  type: "select",
  size: props.small ? 5 : undefined
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  border-color:#ccc; 
  background: white;
`;

const StatusInput = styled.input.attrs(props => ({
  type: "text",
  placeholder: "Search Status"
}))`
  height: 32px;
  width: 100%;
  border-radius: 6px;
  
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 40px;
`;

const FilterRow = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CollectionFilter = ({ search_text, onSearch_text, filterText1, filterText2,
  handleKeyDown, onFilter1, onFilter2, onFilterInvoice_StartDate, filterDue_EndDate,
  onFilterInvoice_EndDate, onFilterDue_StartDate, onFilterDue_EndDate,
  searchCustomers, resetFilters,
  filterDue_StartDate, filterInvoice_StartDate, filterInvoice_EndDate }) => {
  const { t, i18n } = useTranslation();
  const [collection, setCollection] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
    fetchcollections();
  }, []);
  const [selectedDate, setSelectedDate] = useState(null);

  function fetchcollections() {
    var getData = {
      functionName: "GetInvoiceTo",
      postData: {
        sort: { _id: -1 },
        offset: 0,
        limit: 1000,
        filter: {
          user_type: localStorage.getItem("invoice_to")?.split(','),
        },
        TableName: 'users',
        TableField: localStorage.getItem("invoice_to_field")
      },
    };
    CustomerService.GetInvoiceTo(getData).then((response) => {
      if (response.data.message === "Unauthorized") {
        AuthService.logout();
        navigate("/login");
        window.location.reload();
      }
      else {
        if (response.data) {
          setCollection(response.data.data);

        }
      }
    },
      (error) => {
        const errContent =
          (error.response && error.response.data) || error.message || error.toString();
        if (errContent) {
          Alerts.swalErrorAlert(errContent)
        }
        else {
          Alerts.swalErrorAlert(t("Server Error, Please try again later."))
        }
      })
  };

  console.log(filterText1);

  const handleDateChange = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterDue_StartDate(startDateFormatted);
    onFilterDue_EndDate(endDateFormatted);
  };

  const handleDateChange_Invoice = (startDateFormatted, endDateFormatted) => {
    // Call the callback functions to update the parent component's state
    onFilterInvoice_StartDate(startDateFormatted);
    onFilterInvoice_EndDate(endDateFormatted);
  };
  return (
    <>
      <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Col xs="24" sm="12" md="6" lg="4" xl="3">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Input  type="text" name="fname" style={{ width: "42%" }}
              value={search_text} placeholder={t("Search Text")} onChange={onSearch_text}></Input>
            <Input
              type="select"
              placeholder={t("Search Customer")}
              value={filterText1}
              style={{
                marginLeft: '10px',
                width: "58%",
                borderColor: '#CED4DA',
                height: "34px",
                backgroundColor: "white",
                border: '1px solid #ccc',
                borderRadius: "5px",
              }}
              onChange={onFilter1}
              onKeyDown={handleKeyDown}
              id="colfilter"
            >

              <option disabled={false} value="">{t("Select Customer")}</option>
              {collection &&
                collection.map((opt_collection, index) => (
                  <option key={index} value={opt_collection._id}>{opt_collection[localStorage.getItem('invoice_to_field')]}</option>
                ))
              }
            </Input>

          </div>
        </Col>


        <Col xs="24" sm="12" md="12" lg="8" xl="6">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{
              background: '#F9F9FB',
              height: '35px',
              width: '49%',
              border: '1px solid #ccc',
              borderRadius: '6px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center'
            }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Label style={{ width: '120px', background: '#F9F9FB', paddingRight: '5px', paddingLeft: '5px' }}>
                  {t('Starts On')}
                </Label>
                <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                  <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                </div>
                {/* DateRangePickerComponent for Starts On Date */}
                <DateRangePickerComponent onDateChange={handleDateChange_Invoice} />
              </div>
            </div>

            <div style={{
              background: '#F9F9FB',
              height: '35px',
              width: '49%',
              border: '1px solid #ccc',
              borderRadius: '6px',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center'
            }} className="mb-2">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Label style={{ width: '100px', background: '#F9F9FB', paddingRight: '5px', paddingLeft: '5px' }}>
                  {t('Ends On')}
                </Label>
                <div className="d-flex justify-content-end align-items-center h-100 pr-2">
                  <div style={{ borderRight: '2px solid #ccc', height: '4%' }}></div>
                </div>
                {/* DateRangePickerComponent for Ends On Date */}
                <DateRangePickerComponent style={{}} onDateChange={handleDateChange} />
              </div>
            </div>
          </div>

        </Col>

        <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-2">
          <button type="button" className="btn btn-custom-color btn-hover-shine" onClick={() => searchCustomers()}>Search</button>
          <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => resetFilters()}>Reset</button>
          <button type="button" className="btn btn-custom-color btn-hover-shine ms-2" onClick={() => navigate('/add-recurring-invoice')}>Add New</button>
        </Col>
      </Row>
    </>

  );
};

export default CollectionFilter;
